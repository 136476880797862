import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QuillEditorComponent } from 'ngx-quill';
import { PostResource } from '../post-resource.service';
import { NoteFolderAddComponent } from '../../note-folder/note-folder-add/note-folder-add.component';
import {
  GlobalProvider,
  GlobalService,
  ToastService
} from '@xplat-cgc/ionic/features/providers';
import { PlanComponent } from '../../../components/plan/plan.component';
// override p with div tag
/*const Parchment = Quill.import('parchment');
const Block = Parchment.query('block');

Block.tagName = 'DIV';
Quill.register(Block , true);

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);*/

@Component({
  selector: 'app-post-add',
  templateUrl: './post-add.component.html',
  styleUrls: ['./post-add.component.scss']
})
export class PostAddComponent implements OnInit, AfterViewInit {
  @ViewChild('editor')
  editor: QuillEditorComponent;

  public post: any = {};
  public saving = false;
  public collapsed = true;
  public note_folder = '';
  public default_folder = '';
  public user = {};
  public global = {};

  constructor(
    protected modalCtrl: ModalController,
    protected globalProvider: GlobalProvider,
    private globalService: GlobalService,
    private postResource: PostResource,
    protected toastService: ToastService
  ) {
    this.global = this.globalProvider['global'];
  }

  ngAfterViewInit() {
    this.user = this.globalProvider.global['user'];
  }

  ngOnInit() {
    this.user = this.globalProvider.global['user'];
    if (this.user) {
      this.default_folder = this.user['name'] + "'s folder";
    }
  }

  onClose() {
    this.modalCtrl.dismiss(this.post);
  }

  async presentModalFolder() {
    const modal = await this.modalCtrl.create({
      component: NoteFolderAddComponent,
      componentProps: {
        user_id: this.user['id']
      },
      backdropDismiss: false
    });
    modal.present();
    modal.onDidDismiss().then(noteFolder => {
      if (noteFolder.data && noteFolder.data.id) {
        this.post.note_folder_id = noteFolder.data.id;
        this.note_folder = noteFolder.data.name;
      }
    });
  }

  onSave() {
    this.saving = true;
    this.post.user_id = this.user['id'];
    this.postResource
      .store(this.post)
      .then(data => {
        this.toastService.success({
          message: data['message'],
          position: 'top'
        });
        this.post = {};
        this.saving = false;
        this.collapsed = true;
        this.setHeight();
        // this.modalCtrl.dismiss(data['data']);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async presentPlan() {
    if (this.globalService.isPlanFree()) {
      const modal = await this.modalCtrl.create({
        component: PlanComponent,
        backdropDismiss: false,
        componentProps: {
          user: this.global['user'],
          close: true,
          hideId: this.global['user']['plan_id']
        }
      });
      modal.present();
    }
  }

  onExpand() {
    this.collapsed = false;
    this.setHeight();
  }

  onCollapse() {
    this.collapsed = true;
    this.setHeight();
  }

  setHeight() {
    setTimeout(() => {
      document.querySelector('.content-result-search')['style']['max-height'] =
        'calc(100vh - ' +
        (document.querySelector('app-post-add').clientHeight + 110) +
        'px)';
    }, 400);
  }
}
