<!--div
  class="content-center background-placeholder"
  *ngIf="
    !global['result']['bible']['data'] &&
    !global['result']['bible']['loading'] &&
    global['mobile']
  "
>
  <fa-icon [icon]="['fas', 'pen-fancy']" size="3x" style="transform: rotate(135deg);"></fa-icon>
  <h2 class="title">
    <strong>{{ 'Enjoy your study' | translate }}!</strong>
  </h2>
  <div>{{ 'Enter a word or phrase to begin a search' | translate }}</div>
</div-->
<ion-list
  class="ion-no-padding"
>
  <ion-item
    *ngFor="
      let books of globalProvider['books'][global['lang'] || 'en'];
      index as i
    "
    (click)="onSelectChapter(books)"
    color="light"
    [ngClass]="{ 'item-mark': currentIndex == books['id'] }"
    class="fadeIn animated"
    tappable=""
  >
    <ion-label class="ion-text-wrap">
      {{ books['name'] }}
    </ion-label>
  </ion-item>
</ion-list>
<!--ion-item-group *ngIf="global['result']['bible']['data']">
  <ion-list class="ion-no-padding">
    <ion-item
      *ngFor="
        let passage of global['result']['bible']['data']['passages'];
        even as even;
        index as i
      "
      color="light"
      class="fadeIn animated border-white"
      (click)="onSelectPassages(passage)"
      [ngClass]="{ 'item-mark': currentIndex == passage['id'] }"
    >
      <ion-label class="ion-text-wrap">
        <div class="item-note text-justify">
          <strong>{{ passage.reference }}</strong>
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      *ngFor="
        let verse of global['result']['bible']['data']['verses'];
        even as even;
        index as i
      "
      color="light"
      class="fadeIn animated border-white"
      (click)="onSelectChapter(verse)"
      [ngClass]="{ 'item-mark': currentIndex == verse['id'] }"
    >
      <ion-label class="ion-text-wrap">
        <div class="text-primary">
          <strong>{{ verse.reference }}</strong>
        </div>
        <div
          class="item-note text-justify"
          [innerHTML]="
            verse.text | highlightify: global['result']['bible']['queryText']
          "
        ></div>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="onQuery($event)">
    <ion-infinite-scroll-content
      loadingSpinner="dots"
      loadingText="{{ 'Loading more data' | translate }}..."
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-item-group-->
