<div class="background">
  <ion-header>
    <ion-toolbar>
      <ion-buttons (click)="closeModal()" slot="start">
        <ion-back-button [disabled]="" [defaultHref]="''"></ion-back-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding" *ngIf="(track$ | async) as modalData">
    <div class="fixedContent" padding-top>
      <ion-thumbnail>
        <img loading="lazy" class="img-responsive" default="" [src]="environment.resources + modalData?.image">
      </ion-thumbnail>
      <ion-text color="light" class="text-center">{{modalData?.title}}</ion-text>
      <p text-uppercase color="light" class="pUser">{{modalData?.artist}}</p>
      <p text-uppercase color="light" class="pUser">{{modalData?.album}}</p>
      <p text-uppercase color="light" class="pUser">{{modalData?.playtime_string}}</p>
    </div>

    <ion-list>
      <ion-item lines="none" (click)="onToFavorite(modalData)" [style.opacity]="!userPermissionsApp['favorite.musicAccess'] ? 0.35 : 1">
        <fa-icon  slot="start" [icon]="['fas', 'star-of-david']" size="lg" *ngIf="!modalData.favorite"
                 style="color: var(--ion-color-medium); font-size: 19px"></fa-icon>
        <fa-icon  slot="start" [icon]="['fas', 'star-of-david']" size="lg" *ngIf="modalData.favorite"
                 style="color: var(--ion-color-success); font-size: 19px"></fa-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Favorites' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item tappable="" lines="none" (click)="goForActions({ type : 'playlist'})"  [style.opacity]="!userPermissionsApp['playlist.musicAccess'] ? 0.35 : 1">
        <ion-icon slot="start" name="musical-notes" color="medium"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Add to playlist' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item tappable="" lines="none" (click)="goForActions({ type : 'removeplaylist'}, modalData)"
                *ngIf="modalData?.player_music_recommendation?.user_id && !modalData?.player_music_recommendation?.favorite">
        <ion-icon slot="start" name="close-circle-outline" color="medium"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Remove from playlist' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item tappable="" lines="none" (click)="goForActions({ type : 'add'}, modalData)">
        <ion-icon slot="start" name="remove-circle-outline" *ngIf="!queue[modalData.id]" color="medium"></ion-icon>
        <ion-icon slot="start" name="remove-circle-outline" *ngIf="queue[modalData.id]" color="success"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Add to Queue' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item tappable="" lines="none" (click)="goForActions({ type : 'queue'})" *ngIf="(queue$ | async)?.length">
        <ion-icon slot="start" name="list" color="medium"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Queue' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item *ngIf="!modalData?.downloaded"  lines="none" (click)="goForActions({ type : 'download'}, modalData)"  [style.opacity]="!userPermissionsApp['download.musicAccess'] ? 0.35 : 1">
        <ion-icon slot="start" name="cloud-download-outline" color="medium"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Download' | translate}}</ion-text>
        </ion-label>
      </ion-item>
      <ion-item tappable="" lines="none" (click)="goForActions({ type : 'removedownloads'}, modalData)"
                *ngIf="modalData?.downloaded && download">
        <ion-icon slot="start" name="trash-outline" color="medium"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text color="light">{{'Remove from downloads' | translate}}</ion-text>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</div>
