import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/bible'
})
export class BibleResource extends BaseResources {
    @ResourceAction({
        path: '/chapter/{!id}'
    })
    chapter: IResourceMethod<any, Object>;
}
