import { SongsactionmodalComponent } from './songsactionmodal.component'
/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'
import { MusicListService } from '../music-list/music-list.service'
import { PlayerMusicRecommendationResource } from '../player-music-recommendation-resource'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { PermissionModalModule } from '@xplat-cgc/ionic/features/ui/pages/permission-modal/permission-modal.module'


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    FontAwesomeModule,
    PermissionModalModule
  ],
  declarations: [SongsactionmodalComponent],
  exports: [SongsactionmodalComponent],
  providers: [MusicListService, PlayerMusicRecommendationResource]
})
export class SongsactionmodalModule {
}
