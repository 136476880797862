import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonSlides} from '@ionic/angular';
import * as _ from 'lodash';
@Component({
  selector: 'QCH-ion-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input() weeks = [];
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild('slidesWeeks', { static: true }) slidesWeeks: IonSlides;
  @Input() active = {};
  @Input() dir: 'ltr' | 'rtl' | null = 'rtl';
  @Output() selectDate = new EventEmitter();
  @Output() ionSlideDidChange = new EventEmitter();
  public getActiveIndex;
  slideOpts = {
    //loop: true
  };

  async onIonSlideDidChange() {
    this.getActiveIndex = (await this.slidesWeeks.getActiveIndex()) + 1;
    this.ionSlideDidChange.emit({
      index: this.getActiveIndex
    });
    const isEnd = await this.slidesWeeks.isEnd();
  }

  async onSelectDate(row, iDay, iWeek) {
    if (_.isEmpty(row['moment'])) {
      return false;
    }
    this.active['active'] = false;
    row['active'] = true;
    this.active = row;
    this.getActiveIndex = (await this.slidesWeeks.getActiveIndex()) + 1;
    this.selectDate.emit({
      data: row,
      index: this.getActiveIndex
    });
  }

  ngOnInit(): void {
    if (!_.isEmpty(this.weeks) && !_.isEmpty(this.weeks[0])) {
      if (this.dir === null) {
        this.weeks[0][this.weeks[0].length - 1]['active'] = 'active';
        this.active = this.weeks[0][this.weeks[0].length - 1];
        this.onSelectDate(this.weeks[0][this.weeks[0].length - 1], null, null);
      } else {
        this.weeks[0][0]['active'] = 'active';
        this.active = this.weeks[0][0];
        this.onSelectDate(this.weeks[0][0], null, null);
      }

      if (this.weeks[0].length == 1) {
        this.weeks[0].unshift({});
        this.weeks[0].unshift({});
        this.weeks[0].unshift({});
        this.weeks[0].unshift({});
        this.weeks[0].unshift({});
        this.weeks[0].unshift({});
      }
      if (this.weeks.length === 1) {
        //this.slideOpts.loop = false;
      }
    }
  }
}
