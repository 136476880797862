<div>
    <ion-list>
        <ion-list-header>
            <ion-item class="fadeIn animated" *ngIf="level > 0" (click)="onBackFolder()"
                      style="cursor: pointer; width: 15vw;">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>&nbsp;&nbsp;
                <ion-label class="ion-text-wrap">
                    {{'Go back' | translate}}
                </ion-label>
            </ion-item>
            <ion-label class="ion-text-wrap" *ngIf="level == 0">
                {{title_folder | translate}}
            </ion-label>
        </ion-list-header>
        <ion-item *ngFor="let item of folders" (click)="onClickFolder(item)" style="cursor: pointer">
            <ion-label>
                <fa-icon [icon]="['fas', 'folder']"></fa-icon> &nbsp;{{item.name}}
            </ion-label>
        </ion-item>
        <ion-item class="text-center" *ngIf="folders.length == 0 && !loading">
            <ion-label>
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                <i>{{'No folders here' | translate}}...</i></ion-label>
        </ion-item>
        <ion-item class="text-center" *ngIf="loading">
            <ion-label>
                <fa-icon [icon]="['fas', 'redo-alt']"></fa-icon>
                {{'Loading' | translate}}...
            </ion-label>
        </ion-item>
    </ion-list>

    <ion-button color="light" size="small" *ngIf="!addFolderHere"
                (click)="onNewFolder()">{{'Add folder here' | translate}}</ion-button>
    <ion-item *ngIf="addFolderHere">
        <ion-input #folderName placeholder="{{'Folder name' | translate}}" autofocus
                   [(ngModel)]="newFolder.name"></ion-input>
        <ion-button color="success" size="small" (click)="onAdd()"
                    [disabled]="!newFolder.name">{{'Add' | translate}}</ion-button>
        <ion-button color="danger" size="small" (click)="addFolderHere=false">{{'Cancel' | translate}}</ion-button>
    </ion-item>

</div>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()" size="small" color="danger">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>&nbsp;
                {{'Cancel' | translate}}
            </ion-button>
            <ion-button (click)="onSelectFolder()" size="small" *ngIf="noteFolder.name">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>&nbsp;
                {{'Select' | translate}} '{{title_folder | translate}}'
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
