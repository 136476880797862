import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAudible } from '@fortawesome/free-brands-svg-icons'
import {
  faBible,
  faBookReader,
  faBroadcastTower,
  faCalendarAlt,
  faChurch,
  faFile,
  faListOl,
  faMinus,
  faPlus,
  faShareAlt,
  faSpinner,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { TranslateModule } from '@ngx-translate/core'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen'
import { ImageModalComponent } from './components/image-modal/image-modal.component'
import { AuthMenuComponent } from '../ui/pages/auth/auth-menu/auth-menu.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { PostSharedModule } from './pages/post'
import { PipesModule } from '../providers/pipes/pipes.module'
import { SearchSharedModule } from './pages/search/search.shared.module'
import { ViewSharedModule } from '../providers/views.shared.module'
import { HeaderComponent } from '@xplat-cgc/ionic/features/ui/components/header/header.component'
import { CalendarComponent } from '@xplat-cgc/ionic/features/ui/components/calendar/calendar.component'
import { HeaderButtonsModule } from './components/header-buttons/header-buttons.module'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'
import { GlobalProvider } from '@xplat-cgc/ionic/features'


@NgModule({
  imports: [CommonModule,
    TranslateModule,
    IonicModule,
    FontAwesomeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    PostSharedModule,
    PipesModule,
    SearchSharedModule,
    ViewSharedModule,
    HeaderButtonsModule
  ],
  declarations: [HeaderComponent, ImageModalComponent, AuthMenuComponent, CalendarComponent],
  exports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    FontAwesomeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    PostSharedModule,
    PipesModule,
    SearchSharedModule,
    ViewSharedModule, HeaderComponent, ImageModalComponent, CalendarComponent
  ]
})
export class UIModule {
  constructor() {
    library.add(faAudible)
    library.add(faBible)
    library.add(faBookReader)
    library.add(faSpinner)
    library.add(faChurch)
    library.add(faBroadcastTower)
    library.add(faUser)
    library.add(faShareAlt)
    library.add(faListOl)
    library.add(faCalendarAlt)
    library.add(faUsers)
    library.add(faPlus)
    library.add(faMinus)
    library.add(faSignOutAlt)
    library.add(faFolderOpen)
    library.add(faFile)
    library.add(faDotCircle)
    library.add(faCircle)
    library.add(faPlay)
    library.add(faPlayCircle)
  }
}
