import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {LayoutPage} from './layout.page';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFolderOpen, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {routes} from './layout.routes';
import {SharedModule} from '../features/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        FontAwesomeModule
    ],
    exports: [
        SharedModule
    ],
    declarations: [LayoutPage]
})
export class LayoutPageModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faUserCircle);
        library.add(faFolderOpen);
        library.add(faBell);
        library.add(faSave);
    }
}
