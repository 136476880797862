import { Component } from '@angular/core';
import { BroadcastResource } from '../broadcast-resource';
import { BaseListComponent } from '@xplat-cgc/ionic/features/providers/base-list-component';
import { GlobalService } from '@xplat-cgc/ionic/features/providers/services/global-service';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-broadcast-list',
  templateUrl: './broadcast-list.component.html',
  styleUrls: ['./broadcast-list.component.scss']
})
export class BroadcastListComponent extends BaseListComponent {
  public keys = [];
  public search = '';
  public environment = environment;
  public global = {};
  public weeks = null;
  public data = null;

  constructor(
    protected resource: BroadcastResource,
    public globalProvider: GlobalProvider,
    public globalService: GlobalService,
    public platform: Platform
  ) {
    super();
    this.global = this.globalProvider.global;
  }

  onQuery(event, promise = true) {
    if (this.globalService.isPlanFree()) {
      this.pagination.limit = 7;
    }

    const params = _.assign(
      {
        _page: this.pagination.page++,
        _limit: this.pagination.limit,
        _active: this.pagination.active,
        _inactive: this.pagination.inactive,
        _sortJoin: '-date,-id',
        'date-max': moment().format('YYYY-MM-DD'),
        'date-min': moment()
          .subtract(1, 'years')
          .format('YYYY-MM-DD')
      },
      this.wheres
    );

    if (this.global['lang'] == 'es') {
      params['_sortJoin'] = '-date_es,-id';
      params['date_es-max'] = moment().format('YYYY-MM-DD');
      params['date_es-min'] = moment()
        .subtract(1, 'years')
        .format('YYYY-MM-DD');
      delete params['date-max'];
      delete params['date-min'];
    }
    this.loading = true;
    this.promise = this.resource.query({}, params);
    this.promise
      .then(data => {
        if (!this.weeks) {
          this.weeks = [];
        }
        if (!_.isEmpty(data['data']['data'])) {
          data['data']['data'] = _.map(data['data']['data'], row => {
            row['moment'] = moment(row['date']);
            if (this.global['lang'] == 'es') {
              row['moment'] = moment(row['date_es']);
            }
            return row;
          });
          this.weeks = _.concat(this.weeks, _.chunk(data['data']['data'], 7));
        } else {
        }
      })
      .finally(() => {
        this.loading = false;
      });
    return this.promise;
  }

  onIonSlideDidChange(row) {
    if (this.globalService.isPlanFree()) {
      return false;
    }
    const getPage = row['index'] + 1;
    if (getPage >= this.pagination.page) {
      this.pagination.page = getPage;
      this.onQuery(null);
    }
  }

  onSelectDate(row) {
    this.data = null;
    this.loading = true;
    setTimeout(() => {
      this.data = row['data'];
      this.loading = false;
    }, 500);
  }

  ngOnInit() {
    this.list = [];
    this.promise = null;
    this.pagination.limit = 7 * 2;
    this.onQuery(null);
    this.pagination.limit = 7;
  }
}
