import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActionSheetController, IonInfiniteScroll, Platform } from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { SearchService } from '../search.service'
import { PostResource } from '../../post/post-resource.service'
import { NavigationExtras, Router } from '@angular/router'
import * as _ from 'lodash'
import { TranslateService } from '@ngx-translate/core'
import { File } from '@ionic-native/file/ngx'
import { HttpClient } from '@angular/common/http'
import { Storage } from '@ionic/storage'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers'

@Component({
  selector: 'app-search-post',
  templateUrl: './search-post.component.html',
  styleUrls: ['./search-post.component.scss']
})
export class SearchPostComponent implements OnInit, AfterViewInit {
  @ViewChild(IonInfiniteScroll)
  infiniteScroll: IonInfiniteScroll;
  @Input() type;

  public global = {};
  public lastID = 0;
  public progressDownload = null;
  public user = {};
  public listDownloaded = {};
  public currentIndex = null;

  constructor(
    public globalProvider: GlobalProvider,
    protected searchService: SearchService,
    protected postResource: PostResource,
    protected events: Events,
    public router: Router,
    protected translate: TranslateService,
    private file: File,
    public platform: Platform,
    protected ngzone: NgZone,
    protected http: HttpClient,
    private cdRef: ChangeDetectorRef,
    public storage: Storage,
    protected actionSheetController: ActionSheetController
  ) {
    this.global = this.globalProvider['global'];
  }

  async onDownload(post) {
    this.storage.get('listDownloaded').then(async res => {
      this.listDownloaded = res;
      const buttons = [];
      const translate = await this.translate
        .get([
          'Download',
          'Cancel',
          'Download PDF',
          'Download Audio',
          'Download Video',
          'Download Note'
        ])
        .toPromise();

      let downloadPdf = false;
      let downloadNote = false;
      let downloadAudio = false;
      let downloadVideo = false;
      const download = _.findLast(this.listDownloaded, ['id', post['id']]);
      if (!_.isEmpty(download)) {
        downloadPdf =
          !!_.find(download['assets'], ['type', 'pdf']) ||
          !!_.find(download['assets'], ['type', 'book']);
        downloadNote = !!_.find(download['assets'], ['type', 'post']);
        downloadAudio = !!_.find(download['assets'], ['type', 'audio']);
        downloadVideo = !!_.find(download['assets'], ['type', 'video']);
      }

      if (post['type'] == 'pdf' || post['type'] == 'book') {
        buttons.push({
          role: downloadPdf ? 'selected' : '',
          cssClass: 'check',
          icon: downloadPdf ? 'checkmark' : '',
          text: translate['Download PDF'],
          handler: () => {
            this.setDataDownload(post, 'pdf');
          }
        });
      }

      if (post['type'] == 'post') {
        buttons.push({
          role: downloadNote ? 'selected' : '',
          cssClass: 'check',
          icon: downloadNote ? 'checkmark' : '',
          text: translate['Download Note'],
          handler: () => {
            this.setDataDownload(post, 'post');
          }
        });
      }

      if (!!_.find(post['assets'], ['type', 'video'])) {
        buttons.push({
          role: downloadVideo ? 'selected' : '',
          cssClass: 'check',
          icon: downloadVideo ? 'checkmark' : '',
          text: translate['Download Video'],
          handler: () => {
            this.setDataDownload(post, 'video');
          }
        });
      }

      if (!!_.find(post['assets'], ['type', 'audio'])) {
        buttons.push({
          role: downloadAudio ? 'selected' : '',
          cssClass: 'check',
          icon: downloadAudio ? 'checkmark' : '',
          text: translate['Download Audio'],
          handler: () => {
            this.setDataDownload(post, 'audio');
          }
        });
      }

      buttons.push({
        text: translate['Cancel'],
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });
      const actionSheet = await this.actionSheetController.create({
        header: translate['Download'],
        mode: 'ios',
        buttons: buttons
      });
      await actionSheet.present();
    });
  }

  setDataDownload(post, type) {
    const navigationExtras: NavigationExtras = {
      state: {
        post: { ...post },
        type: type
      }
    };
    if (this.global['mobile']) {
      this.global['originSelect'] = type;
      this.router.navigate(['/tabs/study/post/downloaded'], navigationExtras);
    } else {
      this.router.navigate(['post/downloaded'], navigationExtras);
    }
  }

  ionViewDidEnter() {
    this.lastID = null;
    this.storage.get('listDownloaded').then(res => {
      this.listDownloaded = res;
    });
  }

  ngAfterViewInit() {
    this.lastID = null;
    this.storage.get('listDownloaded').then(res => {
      this.listDownloaded = res;
    });
  }

  checkDownloaded(post) {
    return _.findLast(this.listDownloaded, ['id', post['id']]);
  }

  async onSelect(data) {
    this.global['originSelect'] = 'search';
    this.currentIndex = data['id'];
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    this.global['result'][this.type]['title'] = data['title'];
    let url = '';
    if (this.global['mobile']) {
      url =
        '/tabs/study/search/section/' + this.global['searchType'] + '/viewer';
    } else {
      url = '/search';
    }
    this.router.navigate([url]).then(() => {
      this.lastID = data['id'];
      this.global['loadingViewer'] = true;
      this.global['idPDF'] = this.lastID;
      if (data['type'] === 'pdf' || data['type'] === 'book') {
        this.postResource[data['type'] == 'book' ? 'pdf' : 'pdf']({
          id: data['id'],
          mobile: this.global['mobile']
        })
          .then(response => {
            this.events.publish('event:viewer', {
              type: data['type'],
              data: response
            });
            data['check'] = true;
          })
          .catch(() => {
            data['check'] = true;
          });
      } else if (data['type'] === 'audio') {
        this.global['loadingViewer'] = true;
        data['check'] = true;
        this.events.publish('event:viewer', {
          type: data['type'],
          data: data
        });
      } else if (data['type'] === 'video') {
        this.events.publish('event:viewer', {
          type: 'video',
          data: data
        });
        data['check'] = true;
      } else if (data['type'] === 'post') {
        this.postResource
          .get({
            id: data['id']
          })
          .then(response => {
            this.events.publish('event:viewer', {
              type: data['type'],
              data: response['data']
            });
            data['check'] = true;
          })
          .catch(() => {
            data['check'] = true;
          });
      }
    });
  }

  ngOnInit() {
    this.events.subscribe('event:infinitiScrollresult' + this.type, data => {
      if (data) {
        this.infiniteScroll.disabled = data['disabled'];
      }
    });
    this.global['result'][this.type]['sort'] = '-matches';
  }

  ngOnDestroy() {
    this.events.unsubscribe('event:infinitiScrollresult' + this.type);
    this.cdRef.detach();
  }

  onChangeOrder(order) {
    this.global['result']['pdf']['page'] = 0;
    this.global['result']['pdf']['data'] = {};
    this.onQuery(null);
  }

  onQuery(event) {
    if (
      this.global['result'][this.type] &&
      this.global['result'][this.type]['data']['data']
    ) {
      if (
        this.global['result'][this.type]['data']['data'].length >=
        this.global['result'][this.type]['total']
      ) {
        this.infiniteScroll.disabled = true;
        event.target.complete();
        return false;
      }
    }
    this.global['result'][this.type]['page']++;
    this.searchService
      .onQueryPost({
        query: this.global['result'][this.type]['queryText'],
        type: this.type
      })
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch(() => {
        if (event) {
          event.target.complete();
        }
      });
  }
}
