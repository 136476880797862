<ion-header>
    <ion-toolbar>
        <ion-title  class="ion-text-uppercase">{{post.title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
               <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="innerHTML" [innerHTML]="post.description" style="padding: 2%;"></div>
</ion-content>
<ion-footer>
    <ion-toolbar class="ion-text-center">
        <ion-button (click)="onPrint()" size="small" color="light" [disabled]="printing">
            <fa-icon [icon]="['fas', 'file']"></fa-icon> &nbsp; &nbsp; {{'Save as PDF' | translate}}
        </ion-button>
    </ion-toolbar>
</ion-footer>
