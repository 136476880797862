<ion-card class="d-flex ngx-basic-audio-player">
  <ion-button (click)='play();' class="ngx-basic-audio-player-button-handler" [disabled]="!isReady"
              style="font-size: 14px!important;" *ngIf="!isPlaying">
    <fa-icon class="fa fa-lg play-track" [icon]="['fas','play']"
             aria-hidden="true"></fa-icon>
  </ion-button>
  <ion-button (click)='pause();' class="ngx-basic-audio-player-button-handler"
              style="font-size: 14px!important;" *ngIf="isPlaying" [disabled]="!isReady">
    <fa-icon class="fa fa-lg pause-track" [icon]="['fas','pause']"
             aria-hidden="true"></fa-icon>
  </ion-button>
  <div class="flex-fill pl-3 ngx-basic-audio-player-slider">
    <!--ion-range style="width: 100%" min="0" max="{{duration}}" value="{{currentTime}}" class="ion-no-padding"
               (ionChange)="currTimePosChanged($event)"></ion-range-->
    <ion-range min="0" max="{{duration}}" [(ngModel)]="position" (ionChange)="currTimePosChanged($event)" class="ion-no-padding">
    </ion-range>
  </div>


  <div class="d-flex ngx-basic-audio-player-duration">
        <span *ngIf="duration !== 0.01" class="pl-2 pr-3 my-auto" style="font-size: 14px!important;">
          {{progress}}
        </span>
  </div>
  <!--ion-button *ngIf="displayVolumeControls" (click)='toggleVolume();'
              style="padding: 0px!important; min-width:48px!important;">
    <fa-icon *ngIf="volume === 0" class="fa fa-lg volume-mute" [icon]="['fas','volume-mute']" aria-hidden="true">
    </fa-icon>
    <fa-icon *ngIf="volume > 0" class="fa fa-lg volume-up" [icon]="['fas','volume-up']" aria-hidden="true">
    </fa-icon>
  </ion-button-->
</ion-card>

<!--ion-card *ngIf="displayTitle && title !== ''" style="border-top: 1px solid #ddd;">
  <div style="text-align: center;">
    <div style="padding:1px 3px;">
      {{ title }}
    </div>
  </div>
</ion-card-->
