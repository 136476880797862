import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SearchService } from '../../search/search.service'
import { PostResource } from '../post-resource.service'
import { AlertController, IonInfiniteScroll, ModalController, Platform } from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import * as moment from 'moment'
import * as _ from 'lodash'
import { GlobalProvider, UserData } from '@xplat-cgc/ionic/features/providers'

@Component({
  selector: 'app-post-week',
  templateUrl: './post-week.component.html',
  styleUrls: ['./post-week.component.scss']
})
export class PostWeekComponent implements OnInit {
  @ViewChild(IonInfiniteScroll)
  infiniteScroll: IonInfiniteScroll;
  @Input() type;
  public global = {};
  public list = [];
  public loading = false;

  constructor(
    public globalProvider: GlobalProvider,
    protected searchService: SearchService,
    protected postResource: PostResource,
    protected events: Events,
    private httpClient: HttpClient,
    private userData: UserData,
    public router: Router,
    public alertController: AlertController,
    protected translate: TranslateService,
    protected modalCtrl: ModalController,
    public platform: Platform
  ) {
    this.global = this.globalProvider['global'];
    this.global['headerTitle'] = 'Sermon of the week';
    if (!this.global['postWeek']) {
      this.global['postWeek'] = {
        data: []
      };
    }
    this.onQuery(null);
  }

  async onSelect(data) {
    this.global['searchType'] = 'pdf';
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    this.router.navigate(['/search']);
    /*if (this.lastID === data['id']) {
            return false;
        }*/
    this.global['loadingViewer'] = true;
    if (data['type'] === 'pdf' || data['type'] === 'book') {
      this.postResource[data['type']]({
        id: data['id']
      })
        .then(response => {
          this.events.publish('event:viewer', {
            type: data['type'],
            data: response
          });
        })
        .finally(() => {
          data['check'] = true;
        });
    } else if (data['type'] === 'audio') {
      const audio = document.getElementsByTagName('audio');

      this.global['loadingViewer'] = true;
      data['check'] = true;
      return this.events.publish('event:viewer', {
        type: data['type'],
        data: data
      });
      this.postResource[data['type']]({
        id: data['id']
      })
        .then(response => {})
        .finally(() => {
          data['check'] = true;
        });
    } else if (data['type'] === 'video') {
      this.events.publish('event:viewer', {
        type: 'video',
        data: data
      });
      data['check'] = true;
    } else if (data['type'] === 'post') {
      this.postResource
        .get({
          id: data['id']
        })
        .then(response => {
          this.events.publish('event:viewer', {
            type: data['type'],
            data: response['data']
          });
        })
        .finally(() => {
          data['check'] = true;
        });
    }
  }

  ngOnInit() {}

  onQuery(event) {
    if (this.list.length >= this.list['total']) {
      this.infiniteScroll.disabled = true;
      if (event) {
        event.target.complete();
      }

      return false;
    }

    this.loading = true;
    this.global['result']['postWeek']['page'] =
      (this.global['result']['postWeek']['page'] || 0) + 1;
    this.postResource
      .list({
        type: 'pdf,book',
        _sort: '-created_at',
        _limit: 10,
        'created_at-min': moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        _page: this.global['result']['postWeek']['page']
      })
      .then(response => {
        if (_.isEmpty(this.list['data'])) {
          this.list['data'] = [];
        }
        this.list = _.assign(_.omit(response['data'], 'data'), this.list);
        _.forEach(response['data']['data'], value => {
          this.list['data'].push(value);
        });
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
        if (event) {
          event.target.complete();
        }
      });
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  onDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
