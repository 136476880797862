import { Component, OnInit, ViewChild } from '@angular/core'
import { SearchService } from './search.service'
import { IonContent, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular'
import { AuthMenuComponent } from '../auth/auth-menu/auth-menu.component'
import { TranslateService } from '@ngx-translate/core'
import { BibleResource } from '../../../providers/resources'
import { GlobalProvider, UserData } from '../../../providers'

@Component({
    selector: 'app-search',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {

    customPopoverOptions: any = {
        showBackdrop: false
        // header: 'Hair Color',
        // subHeader: 'Select your hair color',
        // message: 'Only select your dominant hair color'
    };
    public queryText = '';
    public global = {};
    public searchType = '';

    @ViewChild(IonContent) content: IonContent;

    public user = this.globalProvider.global['user'];

    constructor(
        protected searchResource: BibleResource,
        protected globalProvider: GlobalProvider,
        protected searchService: SearchService,
        public modalController: ModalController,
        public popoverController: PopoverController,
        private menu: MenuController,
        protected translate: TranslateService,
        public userData: UserData,
        public platform: Platform
    ) {
        this.global = this.globalProvider['global'];
    }

    onChangeLang(lang) {
        this.global['lang'] = lang;
        window.localStorage.setItem('lang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    ionViewDidEnter() {
        this.menu.enable(true);
    }

    ngOnInit() {

    }

    async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: AuthMenuComponent,
            event: ev,
            translucent: true,
            showBackdrop: false
        });
        popover.present();

        popover.onDidDismiss().then((user) => {
            if (user['data']) {
                this.user['photo'] = user['data']['photo'];
            }
        });
    }
}
