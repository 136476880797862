import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlayerState } from '../interfaces/player-state.interface';
import * as fromPlayer from '../reducers/player.reducer';
export const featureKey = 'player';

export type FeatureState = PlayerState;
export const selectPlayerFeature = createFeatureSelector<FeatureState>(featureKey);

export const selectQueueFeature = (state: FeatureState) => state.queue;
export const selectPlayer = createSelector(selectPlayerFeature, state => state);
export const selectUpdateAllPlaylist = createSelector(selectPlayerFeature, state => state.update);
export const selectPlayerIsLoading = createSelector(selectPlayerFeature, state => state?.isLoading);
export const selectPlayerCurrentTrack = createSelector(selectPlayerFeature, state => state?.currentTrack);
export const selectPlayerCurrentTrackInfo = createSelector(selectPlayerFeature, state => state?.currentTrackInfo);
export const selectPlayerCurrentPlaylist = createSelector(selectPlayerFeature, state => state?.currentPlaylist);
export const selectPlayerQueue = createSelector(selectPlayerFeature, fromPlayer.selectAllQueue);
export const selectPlayerPlaylists = createSelector(selectPlayerFeature, fromPlayer.selectAllPlaylists);
export const selectPlayerDownloads = createSelector(selectPlayerFeature, fromPlayer.selectAllDownloads);
