import { Injectable } from '@angular/core'
import { State, Store } from '@ngrx/store'
import { IPlaylist, ITrack, PlayerState } from '../../state/interfaces/player-state.interface'
import { addPlaylist, addQueueTrack, deleteQueueTrack, setPlayerCurrentTrack, setPlaylists } from '../../state/actions/player.actions'
import { PlayerMusicRecommendationResource } from '../player-music-recommendation-resource'
import { TranslateService } from '@ngx-translate/core'
import { SpotifyService } from '../spotify.service'

@Injectable({
  providedIn: 'root'
})
export class MusicListService {

  constructor(
    protected _translate: TranslateService,
    public serviceProvider: SpotifyService,
    protected _store: Store<{ player: PlayerState }>,
    private _state: State<{ player: PlayerState }>,
    private _playerMusicRecommendationResource: PlayerMusicRecommendationResource
  ) {

  }



  addPlaylist(params: Record<any, any>) {
    return this._playerMusicRecommendationResource.createPlaylist(params).then((res: any) => {
      this._store.dispatch(addPlaylist({ playlist: res?.data }))
      return res
    }).catch((err) => {

    })
  }

  addTrackToPlaylist(track: ITrack, playlist: IPlaylist) {
    return this._playerMusicRecommendationResource.addToPlaylist({
      player_music_id: track?.id,
      player_music_recommendation_id: playlist.id
    }).then( async (data: any) => {
      let toast;
      if (!data?.data?.exist) {
        const translate = await this._translate
          .get([
            'Song add to',
          ])
          .toPromise();
        toast = await this.serviceProvider.showToast(`${translate['Song add to']} ${playlist.title}`);
        toast.present();
        return data
      } else {
        const translate = await this._translate
          .get([
            'Song already in',
          ])
          .toPromise();
        toast = await this.serviceProvider.showToast(`${translate['Song already in']} ${playlist.title}`, 'danger');
        toast.present();
        //generate exception
        throw new Error('Song already in playlist')
        return Promise.reject("oh, no!");
      }

    }).catch((err) => {

    })
  }


  removeTrackFromPlaylist(track: ITrack, playlist: IPlaylist) {
    return this._playerMusicRecommendationResource.removeFormPlaylist({
      player_music_id: track.id,
      player_music_recommendation_id: playlist.id
    }).then( async (data: any) => {
      let toast;
      if (!data?.data?.exist) {
        const translate = await this._translate
          .get([
            'Song removed from',
          ])
          .toPromise();
        toast = await this.serviceProvider.showToast(`${translate['Song removed from']} ${playlist.title}`);
        toast.present();
        return data
      } else {
        const translate = await this._translate
          .get([
            'Song already in',
          ])
          .toPromise();
        toast = await this.serviceProvider.showToast(`${translate['Song already in']} ${playlist.title}`, 'danger');
        toast.present();
        //generate exception
        throw new Error('Song already in playlist')
        return Promise.reject("oh, no!");
      }

    }).catch((err) => {

    })
  }

  toFavorite(track: ITrack | null = null) {
    let promise = null
    if (track) {
      promise  = this._playerMusicRecommendationResource.addFavoriteTrack({
        player_music_id: track.id,
      })
    } else {
      promise  = this._playerMusicRecommendationResource.addFavoriteTrack({
        player_music_id: this._state.getValue()?.player?.currentTrackInfo?.id,
      })
    }

    promise.then(async (data) => {


      const translate = await this._translate
        .get([
          'Song added to favorites',
          'Song removed from favorites',
        ])
        .toPromise();
      const toast = await this.serviceProvider.showToast(track.favorite ? translate['Song added to favorites'] : translate['Song removed from favorites']);
      toast.present();
      return data
    })

    return promise
  }

  removeQueue(track: ITrack) {
    this._store.dispatch(deleteQueueTrack({id: track.id}));
  }

  addQueue(track: ITrack | null = null) {
    if (track) {
      this._store.dispatch(addQueueTrack({
        track: {
          ...track,
          index: (Object.keys(this._state.getValue()?.player?.queue?.entities)?.length || 0) + 1
        }
      }))
      return
    }
    this._store.dispatch(addQueueTrack({
      track: {
        ...this._state.getValue()?.player?.currentTrackInfo,
        index: (Object.keys(this._state.getValue()?.player?.queue?.entities)?.length || 0) + 1
      }
    }))
  }

  getPlaylists(params: Record<any, any>  = {}) {
    return this._playerMusicRecommendationResource.getPlaylists(params).then((res: any) => {
      this._store.dispatch(setPlaylists({ playlists: res?.data }))
      return res
    })
  }


  updateOrderPlaylist(playlist: {id: number; order: number}[]) {
    return this._playerMusicRecommendationResource.updateOrderPlaylist(playlist).then((res: any) => {
      return res
    })
  }

}
