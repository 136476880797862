import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/strong'
})
export class StrongResource extends BaseResources {
    @ResourceAction({
        path: '/{!id}'
    })
    chapter: IResourceMethod<any, Object>;
}
