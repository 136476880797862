import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BroadcastResource } from '../broadcast-resource';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import { Platform } from '@ionic/angular';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';
import * as moment from 'moment';

@Component({
  selector: 'app-broadcast-detail',
  templateUrl: './broadcast-detail.component.html',
  styleUrls: ['./broadcast-detail.component.scss']
})
export class BroadcastDetailComponent implements OnInit, AfterViewInit {
  @Input() data = null;
  public environment = environment;
  public loading = false;
  public global = {};
  @ViewChild('audioBroadcast') audioBroadcast;
  constructor(
    protected route: ActivatedRoute,
    protected resource: BroadcastResource,
    public platform: Platform,
    public globalProvider: GlobalProvider
  ) {
    this.global = this.globalProvider.global;
  }

  ngAfterViewInit() {
    if (this.audioBroadcast) {
      this.audioBroadcast.nativeElement.oncanplaythrough = () => {
        this.audioBroadcast.nativeElement.play();
      };
    }
  }

  onTrackFinished(track: any) {
    console.log('Track finished', track);
  }

  show() {
    this.loading = true;
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.resource
        .get({
          id: id
        })
        .then(response => {
          this.loading = false;
          this.data = response['data'];
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }

  ngOnInit() {
    this.show();
  }
}
