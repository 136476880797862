import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
  faPlay, faPause, faSpinner, faStepForward, faStepBackward, faVolumeMute, faVolumeUp, fas,
} from '@fortawesome/free-solid-svg-icons'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { VisorComponent } from './component/visor/visor.component'
import { PostResource } from '@xplat-cgc/ionic/features/ui/pages/post/post-resource.service'
import { TranslateModule } from '@ngx-translate/core'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons/faSearchMinus'
import { PipesModule } from '@xplat-cgc/ionic/features/providers/pipes/pipes.module'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { HttpClientModule } from '@angular/common/http'
import { NgxLoadingModule } from 'ngx-loading'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'


@NgModule({
  declarations: [VisorComponent],
  imports: [
    CommonModule, FormsModule, FontAwesomeModule, IonicModule, TranslateModule, PipesModule, MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    NgxLoadingModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    VisorComponent,
    NgxExtendedPdfViewerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [PostResource],
})
export class VisorModule {
  constructor(library: FaIconLibrary) {
    // Icons for User Favorite Page
    library.addIconPacks(fas)
    library.addIcons(
      faPlay, faPause, faSpinner, faStepForward, faStepBackward, faVolumeMute, faVolumeUp,
      faArrowDown, faArrowUp, faSearchPlus, faSearchMinus,
    )
  }
}
