import { UIModule } from '@xplat-cgc/ionic/features/ui/ui.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {PostPage} from './post.page';
import {PostListComponent} from './post-list/post-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTrash, faFile, faFolder, faUserCircle, faDownload, faPlay, faBan} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PostWeekComponent} from './post-week/post-week.component';
import {NgxLoadingModule} from 'ngx-loading';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import {PostListDownloadedComponent} from './post-list-downloaded/post-list-downloaded.component';
import {PipesModule} from '../../../providers/pipes/pipes.module';
import {ToastService} from '../../../providers/services/toast-service';
import {ViewSharedModule} from '../../../providers/views.shared.module';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faRedoAlt} from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import {SearchViewerComponent} from '@xplat-cgc/ionic/features/ui/pages/search/search-viewer';
import { HeaderButtonsModule } from '../../components/header-buttons/header-buttons.module';

const routes: Routes = [
    {
        path: '',
        component: PostPage,
        children: [
            {
                path: 'list',
                component: PostListComponent
            },
            {
                path: 'downloaded',
                component: PostListDownloadedComponent
            },
            {
              path: 'downloaded/viewer',
              loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/search/search.module').then(m => m.SearchPageModule)
            },
            {
                path: 'week',
                component: PostWeekComponent
            },
        ]
    }
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        PipesModule,
        TranslateModule,
        HttpClientModule,
        FontAwesomeModule,
        NgxLoadingModule,
        ViewSharedModule
    ],
    declarations: [PostPage],
    providers: [
        ToastService
    ]
})
export class PostPageModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faTrash);
        library.add(faFile);
        library.add(faFolder);
        library.add(faUserCircle);
        library.add(faDownload);
        library.add(faPlay);
        library.add(faBan);
        library.add(faTrash);
        library.add(faSpinner);
        library.add(faCircleNotch);
        library.add(faPlus);
        library.add(faMinus);
        library.add(faSave);
        library.add(faTimes);
        library.add(faRedoAlt);
    }
}
