import { createAction, props } from '@ngrx/store';
import { IPlaylist, ITrack, ITrackDownload, PlayerState } from '../interfaces/player-state.interface'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'

export const setPlayerState = createAction('[Player] Set', props<{ player: PlayerState }>());
export const updatePlayerState = createAction('[Player] Update', props<{ player: PlayerState }>());
export const setPlayerCurrentTrackInfo = createAction('[Player] Set Current Track Info', props<{ track: ITrack }>());
export const updatePlayerCurrentTrackInfo = createAction('[Player] Update Current Track Info', props<{ track: Partial<ITrack> }>());
export const clearPlayerCurrentTrackInfo = createAction('[Player] Clear Current Track Info');
export const setPlayerCurrentTrack = createAction('[Player] Set Current Track', props<{ track: ITrack }>());
export const updateAllPlaylists = createAction('[Player] Update Playlists', props<{ update: string }>());

export const setQueue = createAction('[Queue/API] Set Queue', props<{ queue: ITrack[] }>())
export const addQueueTrack = createAction('[Queue/API] Add Queue Track', props<{ track: ITrack }>());
export const setQueueTrack = createAction('[Queue/API] Set Queue Track', props<{ track: ITrack }>());
export const updateQueueTrack = createAction('[Queue/API] Update Queue Track', props<{ update: Update<ITrack> }>())
export const deleteQueueTrack = createAction('[Queue/API] Delete Queue Track', props<{ id: string | number }>())
export const mapQueueTrack = createAction('[Queue/API] Map Queue Track', props<{ entityMap: EntityMapOne<ITrack> }>())
export const mapQueueTracks = createAction('[Queue/API] Map Queue Tracks', props<{ entityMap: EntityMap<ITrack> }>())
export const clearQueueTracks = createAction('[Queue/API] Clear Queue Tracks')


export const setCurrentPlaylist = createAction('[Player] Set Current Track', props<{ playlist: IPlaylist }>());
export const setPlaylists = createAction('[Playlist/API] Set Playlist', props<{ playlists: IPlaylist[] }>())
export const addPlaylist = createAction('[Playlist/API] Add Playlist', props<{ playlist: IPlaylist }>());
export const updatePlaylist = createAction('[Playlist/API] Update Playlist', props<{ update: Update<IPlaylist> }>())
export const deletePlaylist = createAction('[Playlist/API] Delete Playlist', props<{ id: string | number }>())
export const mapPlaylists = createAction('[Playlist/API] Map Playlists', props<{ entityMap: EntityMap<IPlaylist> }>())
export const clearPlaylists = createAction('[Playlist/API] Clear Playlists')


export const loadPlayerDownloads = createAction('[Download/API] Load Downloads')
export const setPlayerDownloads = createAction('[Download/API] Set Downloads', props<{ downloads: ITrackDownload[] }>())
export const addPlayerDownload = createAction('[Download/API] Add Download', props<{ download: ITrackDownload }>());
export const updatePlayerDownload = createAction('[Download/API] Update Download', props<{ update: Update<ITrackDownload> }>())
export const deletePlayerDownload = createAction('[Download/API] Delete Download', props<{ id: string | number }>())
export const mapPlayerDownloads = createAction('[Download/API] Map Downloads', props<{ entityMap: EntityMap<ITrackDownload> }>())
export const clearPlayerDownloads = createAction('[Download/API] Clear Downloads')

