import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core'
import { Platform } from '@ionic/angular'
import { Router } from '@angular/router'
import * as _ from 'lodash'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers'
import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'

@Component({
  selector: 'app-search-viewer',
  templateUrl: './search-viewer.component.html',
  styleUrls: ['./search-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchViewerComponent implements OnInit {
  public global = {};
  public type = 'bible';
  public data: any = {};
  public environment = environment;

  constructor(
    public globalProvider: GlobalProvider,
    protected events: Events,
    public router: Router,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    public platform: Platform
  ) {
    this.global = this.globalProvider['global'];
    if (_.isEmpty(this.global['visors'])) {
      this.global['visors'] = [];
    }
  }

  ngOnInit() {
    this.events.subscribe('event:viewer', data => {
      this.type = null;
      this.data = null;
      setTimeout(() => {
        this.type = data['type'];
        this.data = data['data'];
        if (data['type'] === 'video') {
          if (this.data['path_file']) {
            this.data['path_file'] = (this.data['path_file'] + '').replace(
              '/videos/',
              '/video/'
            );
          }
          this.global['loadingViewer'] = false;
        } else if (data['type'] === 'pdf' || data['type'] === 'book') {
          this.global['loadingViewer'] = true;
        } else if (data['type'] === 'strong') {
          this.global['loadingViewer'] = false;
        } else if (data['type'] === 'audio') {
        } else {
          this.global['loadingViewer'] = false;
        }

        data['searchType'] = this.global['searchType'];
        if (!this.global['mobile']) {
          this.global['visors'] = _.filter(this.global['visors'], function(n) {
            return n['searchType'] !== data['searchType'];
          });
        } else {
          this.global['visors'] = [];
        }
        setTimeout(() => {
          this.global['visors'].push(data);
          if (!this.cdRef['destroyed']) {
            this.cdRef.detectChanges();
          }
        }, 500)
      });
    });
  }
}
