<ion-header *ngIf="!global['mobile']">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title  class="ion-text-uppercase">{{ 'Radio Broadcast' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
    <ion-header *ngIf="global['mobile']" class="bg-white">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/tabs/home/week"></ion-back-button>
        </ion-buttons>
        <QCH-ion-header-buttons></QCH-ion-header-buttons>
      </ion-toolbar>
      <ion-toolbar color="primary">
        <ion-title  class="text-center ion-text-uppercase">
          <strong>{{ 'Radio Broadcast' | translate }}</strong>
        </ion-title>
      </ion-toolbar>
      <QCH-ion-calendar
      *ngIf="weeks"
      [weeks]="weeks"
      (ionSlideDidChange)="onIonSlideDidChange($event)"
      (selectDate)="onSelectDate($event)"
    ></QCH-ion-calendar>
    </ion-header>
    <ion-content>
    <div class="bg-white">
      <app-broadcast-detail class="pt-2" [data]="data"></app-broadcast-detail>
    </div>
    <div
      class="content-center background-placeholder"
      *ngIf="weeks && weeks.length === 0">
      <fa-icon [icon]="['fas', 'frown-open']" size="3x"></fa-icon>
      <h2 class="title">
        <strong>{{ 'No Content' | translate }}!</strong>
      </h2>
    </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
</ion-content>
