
<div *ngIf="globalProvider['global']['mobile']" class="px-2">
  <app-musicplayer [track]="track"></app-musicplayer>
  <!-- <audio  #audioPlayer controls controlsList="nodownload" controls="" webkit-playsinline playsinline style="width: 100%;" [ngStyle]="{'background': platform.is('ios') ? 'var(--ion-color-primary);' : ''}">
    <source [src]="audioUrl | safeUrl" type="audio/mpeg" style="width: 100%;"/>
  </audio> -->

</div>
<ion-card class="d-flex ngx-basic-audio-player" *ngIf="!globalProvider['global']['mobile']">
    <audio  #audioPlayer webkit-playsinline playsinline>
      <source [src]="audioUrl | safeUrl" type="audio/mpeg">z
    </audio>
    <ion-button (click)='playBtnHandler();' class="ngx-basic-audio-player-button-handler"
                style="font-size: 14px!important;" [disabled]="loaderDisplay">
        <fa-icon *ngIf="loaderDisplay" title="loading..." class="fa fa-lg loading-track" [pulse]="true"
                 [icon]="['fas','spinner']" aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="!loaderDisplay && !isPlaying" class="fa fa-lg play-track" [icon]="['fas','play']"
                 aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="!loaderDisplay && isPlaying" class="fa fa-lg pause-track" [icon]="['fas','pause']"
                 aria-hidden="true"></fa-icon>
    </ion-button>
    <div class="flex-fill pl-3 ngx-basic-audio-player-slider">
        <ion-range style="width: 100%" min="0" max="{{duration}}" value="{{currentTime}}" class="ion-no-padding"
                   (ionChange)="currTimePosChanged($event)"></ion-range>
    </div>
    <div class="d-flex ngx-basic-audio-player-duration">
        <span *ngIf="duration !== 0.01" class="pl-2 pr-3 my-auto" style="font-size: 14px!important;">
            -{{duration - currentTime |
            secondsToMinutes }}
        </span>
    </div>
    <ion-button *ngIf="displayVolumeControls" (click)='toggleVolume();'
                style="padding: 0px!important; min-width:48px!important;">
        <fa-icon *ngIf="volume === 0" class="fa fa-lg volume-mute" [icon]="['fas','volume-mute']" aria-hidden="true">
        </fa-icon>
        <fa-icon *ngIf="volume > 0" class="fa fa-lg volume-up" [icon]="['fas','volume-up']" aria-hidden="true">
        </fa-icon>
    </ion-button>
</ion-card>

<ion-card *ngIf="displayTitle && title !== ''" style="border-top: 1px solid #ddd;">
    <div style="text-align: center;">
        <div style="padding:1px 3px;">
            {{ title }}
        </div>
    </div>
</ion-card>
