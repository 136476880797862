<ion-header *ngIf="!global['mobile']">
    <ion-toolbar></ion-toolbar>
</ion-header>
<ion-header *ngIf="global['mobile']">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="global['searchType'] && global['originSelect'] == 'search'" defaultHref="/tabs/search/section/{{global['searchType']}}"></ion-back-button>
      <ion-back-button *ngIf="global['originSelect'] == 'download' || global['originSelect'] == 'pdf' || global['originSelect'] == 'video' || global['originSelect'] == 'audio'" defaultHref="/tabs/study/post/downloaded"></ion-back-button>
      <ion-back-button *ngIf="global['originSelect'] == 'sermon'" defaultHref="/tabs/home/week/sermon"></ion-back-button>
      <ion-back-button *ngIf="global['originSelect'] == 'sundaySermon'" defaultHref="/tabs/home/week/sundaySermon"></ion-back-button>
    </ion-buttons>
    <div [ngSwitch]="global['searchType']">
      <ion-title *ngSwitchCase="'bible'">{{global['result']['bible']['title']}}</ion-title>
      <ion-title *ngSwitchCase="'strong'">{{global['result']['strong']['title']}}</ion-title>
      <ion-title *ngSwitchCase="'pdf'">{{global['result']['pdf']['title']}}</ion-title>
      <ion-title *ngSwitchCase="'post'">{{global['result']['post']['title']}}</ion-title>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
    <div class="content-center background-placeholder" *ngIf="!global['visors'].length && global['searchType'] != 'bible'">
        <fa-icon [icon]="['fas', 'pen-fancy']" size="3x" style="transform: rotate(135deg);"></fa-icon>
        <h2 class="title"><strong>{{'Enjoy your study' | translate}}!</strong></h2>
        <div>{{'Enter a word or phrase to begin a search' | translate}}</div>
    </div>
    <app-search-visor *ngFor="let visor of global['visors']" [data]="visor"
                      [hidden]="global['searchType'] != visor['searchType']"></app-search-visor>
  <iframe *ngIf="!global['mobile']" [hidden]="global['searchType'] != 'bible'" id="bibleExternal" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals" width="100%" [src]="global['urlBible'] | safeUrl"></iframe>
</ion-content>


