import {NgModule} from '@angular/core';
import {MatBasicAudioPlayerComponent} from './component/mat-basic-audio-player/mat-basic-audio-player.component';
import {AudioPlayerService} from './service/audio-player-service/audio-player.service';
import {CommonModule} from '@angular/common';
import {SecondsToMinutesPipe} from './pipe/seconds-to-minutes';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faPlay, faPause, faSpinner, faStepForward, faStepBackward, faVolumeMute, faVolumeUp, fas
} from '@fortawesome/free-solid-svg-icons';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../providers/pipes';
import {MusicplayerPageModule} from '../musicplayer/musicplayer.module';
import { MusicplayerSharedPageModule } from '@xplat-cgc/player/app/musicplayer/musicplayer-shared.module';

@NgModule({
    declarations: [MatBasicAudioPlayerComponent, SecondsToMinutesPipe],
    imports: [
      MusicplayerSharedPageModule,
      CommonModule, FormsModule, FontAwesomeModule, IonicModule, PipesModule, MusicplayerPageModule
    ],
    exports: [MatBasicAudioPlayerComponent, SecondsToMinutesPipe],
    providers: [AudioPlayerService]
})
export class NgxAudioPlayerModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(faPlay, faPause, faSpinner, faStepForward, faStepBackward, faVolumeMute, faVolumeUp);
    }
}
