import { BaseCopyResources } from './base-copy-resources';
import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
  pathPrefix: '/subscription'
})
export class SubscriptionResource extends BaseCopyResources {}
