import {NgModule} from '@angular/core';

// xplat
import {UIModule} from '@xplat-cgc/ionic';

const MODULES = [
    UIModule
];

@NgModule({
    imports: [...MODULES],
    exports: [...MODULES]
})
export class SharedModule {
}
