<ion-buttons slot="end" style="float: right;">
  <ion-button fill="clear" id="popover-button{{id}}">
    <ng-container *ngIf="global['lang'] == 'en'">
      ENG <img  loading="lazy"src="assets/img/usa.png" width="32" class="pl-2"/>
    </ng-container>
    <ng-container *ngIf="global['lang'] == 'es'">
      ESP <img  loading="lazy"src="assets/img/mx.png" width="32" class="pl-2"/>
    </ng-container>
  </ion-button>
  <ion-popover trigger="popover-button{{id}}" [dismissOnSelect]="true">
    <ng-template>
      <ion-content>
          <ion-list *ngIf="global['mobile']" lines="none">
            <ion-list-header>
              <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Language' | translate }}
              </ion-label>
            </ion-list-header>
            <ion-item tappable="" (click)="onChangeLang('en')" [color]="global['lang'] == 'en' ? 'secondary' : ''">
              <ion-avatar slot="start">
                <img loading="lazy"src="assets/img/usa.png" width="32" class="pl-2"/>
              </ion-avatar>
              <ion-label>
                English
              </ion-label>
            </ion-item>
            <ion-item tappable="" (click)="onChangeLang('es')" [color]="global['lang'] == 'es' ? 'secondary' : ''">
              <ion-avatar slot="start">
                <img loading="lazy"src="assets/img/mx.png" width="32" class="pl-2"/>
              </ion-avatar>
              <ion-label>
                Español
              </ion-label>
            </ion-item>
          </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>
  <ion-button fill="clear" (click)="presentModaHelpStrong()" *ngIf="showHelpStron">
    <ion-icon slot="icon-only"  name="warning-outline"></ion-icon>
  </ion-button>
  <ion-button fill="clear" (click)="presentModalHelp()" *ngIf="global['user']">
    <ion-icon slot="icon-only"  name="help-circle-outline"></ion-icon>
  </ion-button>
  <ion-button fill="clear" (click)="presentModalNotification()" *ngIf="global['user']">
    <ion-icon slot="icon-only"  name="notifications-outline"></ion-icon>
  </ion-button>
</ion-buttons>
