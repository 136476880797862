<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{'Remove account' | translate}}</ion-title>
    <!--Button Close -->
    <ion-buttons slot="end">
      <ion-button  (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p>
    {{'Warning: This action will permanently delete your account, and you will not be able to log in again. All your data and settings will be lost. Are you sure you want to proceed with this action?' | translate}}
  </p>
  <!-- Generate content show not permission for view de section -->
  <p>{{'Enter the code sent to your email' | translate}}</p>
  <div class="row">
    <div class="col-12">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{ 'Code' | translate }}
          <span class="text-danger text-bold">*</span></mat-label>
        <input matInput inputmode="text" name="code" placeholder="{{ 'Enter the code' | translate }}"
               [(ngModel)]="code"/>
      </mat-form-field>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-button expand="block" color="danger" type="button" (click)="onRemoveAccount()" [disabled]="!code || isLoading">
    <fa-icon [icon]="['fas', 'ban']"></fa-icon> &nbsp;
    {{ 'Cancel Subscription' | translate }}
  </ion-button>
</ion-footer>
