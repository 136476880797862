import { Injectable, Injector } from '@angular/core';
import { UserData } from './user-data';
import { AuthResource } from './resources/auth-resource';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import * as _ from 'lodash';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class GlobalProvider {
  public global: any = {
    downloader: [],
    listDownloaded: []
  };
  public books = {
    es: [
      {
        id: 'GEN',
        chapterId: 'GEN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Gn.',
        name: 'Génesis',
        nameLong: 'Génesis'
      },
      {
        id: 'EXO',
        chapterId: 'EXO.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ex.',
        name: 'Éxodo',
        nameLong: 'Éxodo'
      },
      {
        id: 'LEV',
        chapterId: 'LEV.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Lv.',
        name: 'Levítico',
        nameLong: 'Levítico'
      },
      {
        id: 'NUM',
        chapterId: 'NUM.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Nm.',
        name: 'Números',
        nameLong: 'Números'
      },
      {
        id: 'DEU',
        chapterId: 'DEU.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Dt.',
        name: 'Deuteronomio',
        nameLong: 'Deuteronomio'
      },
      {
        id: 'JOS',
        chapterId: 'JOS.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jos.',
        name: 'Josué',
        nameLong: 'Josué'
      },
      {
        id: 'JDG',
        chapterId: 'JDG.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jue.',
        name: 'Jueces',
        nameLong: 'Jueces'
      },
      {
        id: 'RUT',
        chapterId: 'RUT.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Rt.',
        name: 'Rut',
        nameLong: 'Rut'
      },
      {
        id: '1SA',
        chapterId: '1SA.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 S.',
        name: '1 Samuel',
        nameLong: 'Primer libro de Samuel'
      },
      {
        id: '2SA',
        chapterId: '2SA.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 S.',
        name: '2 Samuel',
        nameLong: 'Segundo libro de Samuel'
      },
      {
        id: '1KI',
        chapterId: '1KI.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 R.',
        name: '1 Reyes',
        nameLong: 'Primer libro de los Reyes'
      },
      {
        id: '2KI',
        chapterId: '2KI.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 R.',
        name: '2 Reyes',
        nameLong: 'Segundo libro de los Reyes'
      },
      {
        id: '1CH',
        chapterId: '1CH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 Cr.',
        name: '1 Crónicas',
        nameLong: 'Primer libro de las Crónicas'
      },
      {
        id: '2CH',
        chapterId: '2CH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 Cr.',
        name: '2 Crónicas',
        nameLong: 'Segundo libro de las Crónicas'
      },
      {
        id: 'EZR',
        chapterId: 'EZR.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Esd.',
        name: 'Esdras',
        nameLong: 'Esdras'
      },
      {
        id: 'NEH',
        chapterId: 'NEH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Neh.',
        name: 'Nehemías',
        nameLong: 'Nehemías'
      },
      {
        id: 'EST',
        chapterId: 'EST.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Est.',
        name: 'Ester',
        nameLong: 'Ester'
      },
      {
        id: 'JOB',
        chapterId: 'JOB.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Job',
        name: 'Job',
        nameLong: 'Job'
      },
      {
        id: 'PSA',
        chapterId: 'PSA.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Sal.',
        name: 'Salmos',
        nameLong: 'Salmos'
      },
      {
        id: 'PRO',
        chapterId: 'PRO.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Pr.',
        name: 'Proverbios',
        nameLong: 'Proverbios'
      },
      {
        id: 'ECC',
        chapterId: 'ECC.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ec.',
        name: 'Eclesiastés',
        nameLong: 'Eclesiastés'
      },
      {
        id: 'SNG',
        chapterId: 'SNG.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Cnt.',
        name: 'Cantares',
        nameLong: 'El Cantar de los Cantares'
      },
      {
        id: 'ISA',
        chapterId: 'ISA.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Is.',
        name: 'Isaías',
        nameLong: 'Isaías'
      },
      {
        id: 'JER',
        chapterId: 'JER.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jer.',
        name: 'Jeremías',
        nameLong: 'Jeremías'
      },
      {
        id: 'LAM',
        chapterId: 'LAM.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Lm.',
        name: 'Lamentaciones',
        nameLong: 'Lamentaciones'
      },
      {
        id: 'EZK',
        chapterId: 'EZK.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ez.',
        name: 'Ezequiel',
        nameLong: 'Ezequiel'
      },
      {
        id: 'DAN',
        chapterId: 'DAN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Dn.',
        name: 'Daniel',
        nameLong: 'Daniel'
      },
      {
        id: 'HOS',
        chapterId: 'HOS.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Os.',
        name: 'Oseas',
        nameLong: 'Oseas'
      },
      {
        id: 'JOL',
        chapterId: 'JOL.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jl.',
        name: 'Joel',
        nameLong: 'Joel'
      },
      {
        id: 'AMO',
        chapterId: 'AMO.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Am.',
        name: 'Amós',
        nameLong: 'Amós'
      },
      {
        id: 'OBA',
        chapterId: 'OBA.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Abd.',
        name: 'Abdías',
        nameLong: 'Abdías'
      },
      {
        id: 'JON',
        chapterId: 'JON.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jon.',
        name: 'Jonás',
        nameLong: 'Jonás'
      },
      {
        id: 'MIC',
        chapterId: 'MIC.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Mi.',
        name: 'Miqueas',
        nameLong: 'Miqueas'
      },
      {
        id: 'NAM',
        chapterId: 'NAM.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Nah.',
        name: 'Nahúm',
        nameLong: 'Nahúm'
      },
      {
        id: 'HAB',
        chapterId: 'HAB.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Hab.',
        name: 'Habacuc',
        nameLong: 'Habacuc'
      },
      {
        id: 'ZEP',
        chapterId: 'ZEP.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Sof.',
        name: 'Sofonías',
        nameLong: 'Sofonías'
      },
      {
        id: 'HAG',
        chapterId: 'HAG.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Hag.',
        name: 'Hageo',
        nameLong: 'Hageo'
      },
      {
        id: 'ZEC',
        chapterId: 'ZEC.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Zac.',
        name: 'Zacarías',
        nameLong: 'Zacarías'
      },
      {
        id: 'MAL',
        chapterId: 'MAL.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Mal.',
        name: 'Malaquías',
        nameLong: 'Malaquías'
      },
      {
        id: 'MAT',
        chapterId: 'MAT.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Mt.',
        name: 'San Mateo',
        nameLong: 'Evangelio según San Mateo'
      },
      {
        id: 'MRK',
        chapterId: 'MRK.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Mr.',
        name: 'San Marcos',
        nameLong: 'Evangelio según San Marcos'
      },
      {
        id: 'LUK',
        chapterId: 'LUK.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Lc.',
        name: 'San Lucas',
        nameLong: 'Evangelio según San Lucas'
      },
      {
        id: 'JHN',
        chapterId: 'JHN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jn.',
        name: 'San Juan',
        nameLong: 'Evangelio según San Juan'
      },
      {
        id: 'ACT',
        chapterId: 'ACT.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Hch.',
        name: 'Hechos',
        nameLong: 'Hechos de los Apóstoles'
      },
      {
        id: 'ROM',
        chapterId: 'ROM.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ro.',
        name: 'Romanos',
        nameLong: 'Carta de san Pablo a los Romanos'
      },
      {
        id: '1CO',
        chapterId: '1CO.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 Co.',
        name: '1 Corintios',
        nameLong: 'Primera carta de san Pablo a los Corintios'
      },
      {
        id: '2CO',
        chapterId: '2CO.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 Co.',
        name: '2 Corintios',
        nameLong: 'Segunda carta de san Pablo a los Corintios'
      },
      {
        id: 'GAL',
        chapterId: 'GAL.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ga.',
        name: 'Gálatas',
        nameLong: 'Carta de san Pablo a los Gálatas'
      },
      {
        id: 'EPH',
        chapterId: 'EPH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ef.',
        name: 'Efesios',
        nameLong: 'Carta de san Pablo a los Efesios'
      },
      {
        id: 'PHP',
        chapterId: 'PHP.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Fil.',
        name: 'Filipenses',
        nameLong: 'Carta de san Pablo a los Filipenses'
      },
      {
        id: 'COL',
        chapterId: 'COL.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Col.',
        name: 'Colosenses',
        nameLong: 'Carta de san Pablo a los Colosenses'
      },
      {
        id: '1TH',
        chapterId: '1TH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 Ts.',
        name: '1 Tesalonicenses',
        nameLong: 'Primera carta de san Pablo a los Tesalonicenses'
      },
      {
        id: '2TH',
        chapterId: '2TH.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 Ts.',
        name: '2 Tesalonicenses',
        nameLong: 'Segunda carta de san Pablo a los Tesalonicenses'
      },
      {
        id: '1TI',
        chapterId: '1TI.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 Ti.',
        name: '1 Timoteo',
        nameLong: 'Primera carta de san Pablo a Timoteo'
      },
      {
        id: '2TI',
        chapterId: '2TI.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 Ti.',
        name: '2 Timoteo',
        nameLong: 'Segunda carta de san Pablo a Timoteo'
      },
      {
        id: 'TIT',
        chapterId: 'TIT.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Tit.',
        name: 'Tito',
        nameLong: 'Carta de san Pablo a Tito'
      },
      {
        id: 'PHM',
        chapterId: 'PHM.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Flm.',
        name: 'Filemón',
        nameLong: 'Carta de san Pablo a Filemón'
      },
      {
        id: 'HEB',
        chapterId: 'HEB.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'He.',
        name: 'Hebreos',
        nameLong: 'Carta a los Hebreos'
      },
      {
        id: 'JAS',
        chapterId: 'JAS.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Stg.',
        name: 'Santiago',
        nameLong: 'Carta de Santiago'
      },
      {
        id: '1PE',
        chapterId: '1PE.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 P.',
        name: '1 Pedro',
        nameLong: 'Primera carta de san Pedro'
      },
      {
        id: '2PE',
        chapterId: '2PE.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 P.',
        name: '2 Pedro',
        nameLong: 'Segunda carta de san Pedro'
      },
      {
        id: '1JN',
        chapterId: '1JN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '1 Jn.',
        name: '1 Juan',
        nameLong: 'Primera carta de san Juan'
      },
      {
        id: '2JN',
        chapterId: '2JN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '2 Jn.',
        name: '2 Juan',
        nameLong: 'Segunda carta de san Juan'
      },
      {
        id: '3JN',
        chapterId: '3JN.1',
        bibleId: '592420522e16049f-01',
        abbreviation: '3 Jn.',
        name: '3 Juan',
        nameLong: 'Tercera carta de san Juan'
      },
      {
        id: 'JUD',
        chapterId: 'JUD.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Jud.',
        name: 'Judas',
        nameLong: 'Carta de san Judas'
      },
      {
        id: 'REV',
        chapterId: 'REV.1',
        bibleId: '592420522e16049f-01',
        abbreviation: 'Ap.',
        name: 'Apocalipsis',
        nameLong: 'Apocalipsis'
      }
    ],
    en: [
      {
        id: 'GEN',
        chapterId: 'GEN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Gen',
        name: 'Genesis',
        nameLong: 'The First Book of Moses, Commonly Called Genesis'
      },
      {
        id: 'EXO',
        chapterId: 'EXO.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Exo',
        name: 'Exodus',
        nameLong: 'The Second Book of Mosis, Commonly Called Exodus'
      },
      {
        id: 'LEV',
        chapterId: 'LEV.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Lev',
        name: 'Leviticus',
        nameLong: 'The Third Book of Mosis, Commonly Called Leviticus'
      },
      {
        id: 'NUM',
        chapterId: 'NUM.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Num',
        name: 'Numbers',
        nameLong: 'The Fourth Book of Moses, Commonly Called Numbers'
      },
      {
        id: 'DEU',
        chapterId: 'DEU.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Deu',
        name: 'Deuteronomy',
        nameLong: 'The Fifth Book of Moses, Commonly Called Deuteronomy'
      },
      {
        id: 'JOS',
        chapterId: 'JOS.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jos',
        name: 'Joshua',
        nameLong: 'The Book of Joshua'
      },
      {
        id: 'JDG',
        chapterId: 'JDG.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jdg',
        name: 'Judges',
        nameLong: 'The Book of Judges'
      },
      {
        id: 'RUT',
        chapterId: 'RUT.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Rut',
        name: 'Ruth',
        nameLong: 'The Book of Ruth'
      },
      {
        id: '1SA',
        chapterId: '1SA.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Sa',
        name: '1 Samuel',
        nameLong: 'The First Book of Samuel'
      },
      {
        id: '2SA',
        chapterId: '2SA.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Sa',
        name: '2 Samuel',
        nameLong: 'The Second Book of Samuel'
      },
      {
        id: '1KI',
        chapterId: '1KI.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Ki',
        name: '1 Kings',
        nameLong: 'The First Book of Kings'
      },
      {
        id: '2KI',
        chapterId: '2KI.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Ki',
        name: '2 Kings',
        nameLong: 'The Second Book of Kings'
      },
      {
        id: '1CH',
        chapterId: '1CH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Ch',
        name: '1 Chronicles',
        nameLong: 'The First Book of Chronicles'
      },
      {
        id: '2CH',
        chapterId: '2CH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Ch',
        name: '2 Chronicles',
        nameLong: 'The Second Book of Chronicles'
      },
      {
        id: 'EZR',
        chapterId: 'EZR.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Ezr',
        name: 'Ezra',
        nameLong: 'The Book of Ezra'
      },
      {
        id: 'NEH',
        chapterId: 'NEH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Neh',
        name: 'Nehemiah',
        nameLong: 'The Book of Nehemiah'
      },
      {
        id: 'EST',
        chapterId: 'EST.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Est',
        name: 'Esther',
        nameLong: 'The Book of Esther'
      },
      {
        id: 'JOB',
        chapterId: 'JOB.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Job',
        name: 'Job',
        nameLong: 'The Book of Job'
      },
      {
        id: 'PSA',
        chapterId: 'PSA.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Psa',
        name: 'Psalms',
        nameLong: 'The Psalms'
      },
      {
        id: 'PRO',
        chapterId: 'PRO.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Pro',
        name: 'Proverbs',
        nameLong: 'The Proverbs'
      },
      {
        id: 'ECC',
        chapterId: 'ECC.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Ecc',
        name: 'Ecclesiastes',
        nameLong: 'Ecclesiates or, The Preacher'
      },
      {
        id: 'SNG',
        chapterId: 'SNG.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Sng',
        name: 'Song of Solomon',
        nameLong: 'The Song of Solomon'
      },
      {
        id: 'ISA',
        chapterId: 'ISA.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Isa',
        name: 'Isaiah',
        nameLong: 'The Book of Isaiah'
      },
      {
        id: 'JER',
        chapterId: 'JER.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jer',
        name: 'Jeremiah',
        nameLong: 'The Book of Jeremiah'
      },
      {
        id: 'LAM',
        chapterId: 'LAM.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Lam',
        name: 'Lamentations',
        nameLong: 'The Lamentations of Jeremiah'
      },
      {
        id: 'EZK',
        chapterId: 'EZK.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Ezk',
        name: 'Ezekiel',
        nameLong: 'The Book of Ezekiel'
      },
      {
        id: 'DAN',
        chapterId: 'DAN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Dan',
        name: 'Daniel',
        nameLong: 'The Book of Daniel'
      },
      {
        id: 'HOS',
        chapterId: 'HOS.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Hos',
        name: 'Hosea',
        nameLong: 'The Book of Hosea'
      },
      {
        id: 'JOL',
        chapterId: 'JOL.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jol',
        name: 'Joel',
        nameLong: 'The Book of Joel'
      },
      {
        id: 'AMO',
        chapterId: 'AMO.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Amo',
        name: 'Amos',
        nameLong: 'The Book of Amos'
      },
      {
        id: 'OBA',
        chapterId: 'OBA.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Oba',
        name: 'Obadiah',
        nameLong: 'The Book of Obadiah'
      },
      {
        id: 'JON',
        chapterId: 'JON.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jon',
        name: 'Jonah',
        nameLong: 'The Book of Jonah'
      },
      {
        id: 'MIC',
        chapterId: 'MIC.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Mic',
        name: 'Micah',
        nameLong: 'The Book of Micah'
      },
      {
        id: 'NAM',
        chapterId: 'NAM.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Nam',
        name: 'Nahum',
        nameLong: 'The Book of Nahum'
      },
      {
        id: 'HAB',
        chapterId: 'HAB.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Hab',
        name: 'Habakkuk',
        nameLong: 'The Book of Habakkuk'
      },
      {
        id: 'ZEP',
        chapterId: 'ZEP.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Zep',
        name: 'Zephaniah',
        nameLong: 'The Book of Zephaniah'
      },
      {
        id: 'HAG',
        chapterId: 'HAG.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Hag',
        name: 'Haggai',
        nameLong: 'The Book of Haggai'
      },
      {
        id: 'ZEC',
        chapterId: 'ZEC.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Zec',
        name: 'Zechariah',
        nameLong: 'The Book of Zechariah'
      },
      {
        id: 'MAL',
        chapterId: 'MAL.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Mal',
        name: 'Malachi',
        nameLong: 'The Book of Malachi'
      },
      {
        id: 'MAT',
        chapterId: 'MAT.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Mat',
        name: 'Matthew',
        nameLong: 'The Good News According to Matthew'
      },
      {
        id: 'MRK',
        chapterId: 'MRK.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Mrk',
        name: 'Mark',
        nameLong: 'The Good News According to Mark'
      },
      {
        id: 'LUK',
        chapterId: 'LUK.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Luk',
        name: 'Luke',
        nameLong: 'The Good News According to Luke'
      },
      {
        id: 'JHN',
        chapterId: 'JHN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jhn',
        name: 'John',
        nameLong: 'The Good News According to John'
      },
      {
        id: 'ACT',
        chapterId: 'ACT.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Act',
        name: 'Acts',
        nameLong: 'The Acts of the Apostles'
      },
      {
        id: 'ROM',
        chapterId: 'ROM.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Rom',
        name: 'Romans',
        nameLong: 'Paul’s Letter to the Romans'
      },
      {
        id: '1CO',
        chapterId: '1CO.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Co',
        name: '1 Corinthians',
        nameLong: 'Paul’s First Letter to the Corinthians'
      },
      {
        id: '2CO',
        chapterId: '2CO.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Co',
        name: '2 Corinthians',
        nameLong: 'Paul’s Second Letter to the Corinthians'
      },
      {
        id: 'GAL',
        chapterId: 'GAL.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Gal',
        name: 'Galatians',
        nameLong: 'Paul’s Letter to the Galatians'
      },
      {
        id: 'EPH',
        chapterId: 'EPH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Eph',
        name: 'Ephesians',
        nameLong: 'Paul’s Letter to the Ephesians'
      },
      {
        id: 'PHP',
        chapterId: 'PHP.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Php',
        name: 'Philippians',
        nameLong: 'Paul’s Letter to the Philippians'
      },
      {
        id: 'COL',
        chapterId: 'COL.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Col',
        name: 'Colossians',
        nameLong: 'Paul’s Letter to the Colossians'
      },
      {
        id: '1TH',
        chapterId: '1TH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Th',
        name: '1 Thessalonians',
        nameLong: 'Paul’s First Letter to the Thessalonians'
      },
      {
        id: '2TH',
        chapterId: '2TH.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Th',
        name: '2 Thessalonians',
        nameLong: 'Paul’s Second Letter to the Thessalonians'
      },
      {
        id: '1TI',
        chapterId: '1TI.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Ti',
        name: '1 Timothy',
        nameLong: 'Paul’s First Letter to Timothy'
      },
      {
        id: '2TI',
        chapterId: '2TI.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Ti',
        name: '2 Timothy',
        nameLong: 'Paul’s Second Letter to Timothy'
      },
      {
        id: 'TIT',
        chapterId: 'TIT.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Tit',
        name: 'Titus',
        nameLong: 'Paul’s Letter to Titus'
      },
      {
        id: 'PHM',
        chapterId: 'PHM.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Phm',
        name: 'Philemon',
        nameLong: 'Paul’s Letter to Philemon'
      },
      {
        id: 'HEB',
        chapterId: 'HEB.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Heb',
        name: 'Hebrews',
        nameLong: 'The Letter to the Hebrews'
      },
      {
        id: 'JAS',
        chapterId: 'JAS.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jas',
        name: 'James',
        nameLong: 'The Letter from James'
      },
      {
        id: '1PE',
        chapterId: '1PE.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Pe',
        name: '1 Peter',
        nameLong: 'Peter’s First Letter'
      },
      {
        id: '2PE',
        chapterId: '2PE.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Pe',
        name: '2 Peter',
        nameLong: 'Peter’s Second Letter'
      },
      {
        id: '1JN',
        chapterId: '1JN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '1Jn',
        name: '1 John',
        nameLong: 'John’s First Letter'
      },
      {
        id: '2JN',
        chapterId: '2JN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '2Jn',
        name: '2 John',
        nameLong: 'John’s Second Letter'
      },
      {
        id: '3JN',
        chapterId: '3JN.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: '3Jn',
        name: '3 John',
        nameLong: 'John’s Third Letter'
      },
      {
        id: 'JUD',
        chapterId: 'JUD.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Jud',
        name: 'Jude',
        nameLong: 'The Letter from Jude'
      },
      {
        id: 'REV',
        chapterId: 'REV.1',
        bibleId: '06125adad2d5898a-01',
        abbreviation: 'Rev',
        name: 'Revelation',
        nameLong: 'The Revelation to John'
      }
    ]
  };

  constructor(
    protected userData: UserData,
    private _injector: Injector,
    private _storage: Storage
  ) {}

  set(key, value) {
    this.global[key] = value;
  }

  get(key) {
    return this.global[key];
  }

  async load() {
    moment.tz.setDefault('America/Kentucky/Louisville');
    const token = window.localStorage['token'];
    const authResource = this._injector.get(AuthResource);
    try {
      const statusNetwork = await Network.getStatus()
      if (!statusNetwork.connected) {
        let user = await this._storage.get('user');
        this.set('config', await this._storage.get('config'));
        if (!_.isEmpty(user)) {
          this.set('user', user['user']);
        }
        this.set('token', token);
        this['global']['config']['liveEvents'] = [];
        this['global']['config']['liveStream'] = false;
        return true;
      }

      await authResource
        .options()
        .then(data => {
          if (_.isEmpty(data['data'])) {
            data['data'] = {};
          }
          this.set('config', data['data']);
          if (
            this['global']['config'] &&
            this['global']['config']['images'] &&
            this['global']['config']['images']['login']
          ) {
            this['global']['config']['images']['login'] =
              environment.resources +
              this['global']['config']['images']['login'];
          } else {
            this['global']['config']['images'] = {
              login: 'assets/img/carrousel-1.jpg'
            };
          }

          if (!this['global']['config'].hasOwnProperty('preach')) {
            this['global']['config']['preach'] = {};
          }
          if (this['global']['config']['preach']) {
            this['global']['config']['images']['prearch'] =
              environment.resources +
              this['global']['config']['preach']['image'];
          } else {
            this['global']['config']['images']['prearch'] =
              'assets/img/carrousel-1.jpg';
          }

          if (!this['global']['config'].hasOwnProperty('aboutUs')) {
            this['global']['config']['aboutUs'] = {};
          }
          if (this['global']['config']['aboutUs']) {
            this['global']['config']['images']['aboutUs'] =
              environment.resources +
              this['global']['config']['aboutUs']['header_image'];
          } else {
            this['global']['config']['images']['aboutUs'] =
              'assets/img/carrousel-1.jpg';
          }

          if (!this['global']['config'].hasOwnProperty('header')) {
            this['global']['config']['header'] = {};
          }
          if (this['global']['config']['header']) {
            this['global']['config']['images']['header'] =
              environment.resources +
              this['global']['config']['header']['image'];
          } else {
            this['global']['config']['images']['header'] =
              'assets/img/carrousel-1.jpg';
          }
          this._storage.set('config', this['global']['config']);
          /*header_image	null
                header_title	null*/
        })
        .catch(async e => {
          console.log(e);
          this.set('config', await this._storage.get('config'));
        });

      if (token) {
        await  authResource.me().then(data => {
          window.localStorage['user'] = data['data']['user'];
          this.set('user', {
            ...data['data']['user'],
            abilities: data['data']['abilities']
          });
          this.set('token', data['data']['token']);
          this._storage.set('user', data['data']);
        });
      }
    } catch (e) {}
  }
}
