<ion-header>
    <ion-toolbar>
        <ion-title  class="ion-text-uppercase">{{'Quick Note' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onExpand()" *ngIf="collapsed">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </ion-button>
            <ion-button (click)="onCollapse()" *ngIf="!collapsed">
                <fa-icon [icon]="['fas', 'minus']"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<div class="expand-wrapper" [ngClass]="collapsed ? 'collapsed' : ''" >
    <div>
        <ion-item>
            <ion-label position="floating">{{'Title' | translate}}</ion-label>
            <ion-input [(ngModel)]="post.title"></ion-input>
        </ion-item>

        <quill-editor class="form-control" [ngClass]="collapsed ? 'collapsed' : ''"
                      [(ngModel)]="post.description">
        </quill-editor>
    </div>
</div>
<ion-footer *ngIf="!collapsed">
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col size="4" class="pt-3">
                        <fa-icon [icon]="['fas', 'folder']"></fa-icon>&nbsp;&nbsp;
                        <small class="text-white" *ngIf="!post.note_folder_id">{{default_folder}}</small>
                        <small class="text-white" *ngIf="post.note_folder_id"><b>{{note_folder}}</b></small>
                </ion-col>
                <ion-col size="4">
                    <ion-label>&nbsp;</ion-label>
                    <ion-button color="success" size="small"
                                (click)="presentModalFolder()">{{'Select' | translate}}</ion-button>
                </ion-col>
                <ion-col size="4" class="text-right">

                    <ion-button (click)="onSave()" color="secondary"
                                [disabled]="!(post.title && post.description) || saving">
                        <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp; {{'Save' | translate}}
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
