// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
         production: true,
         api: 'https://cgc.extendevs.com/api/',
         socket: 'https://cgc.extendevs.com',
         resources: 'https://cgc.extendevs.com/',
         //api: 'http://localhost:3000/api/',
         hmr: false,
         offline: false,
         keyConekta: 'key_S7PXzB655poVyzMYFpZ8HoQ',
         version: '2.0.3',
         firebase: {
           apiKey: 'AIzaSyBH1TPCue6E8hHxXFKxyjFmnv6pgMW6WkI',
           authDomain: 'cgc-app-bb594.firebaseapp.com',
           databaseURL: 'https://cgc-app-bb594.firebaseio.com',
           projectId: 'cgc-app-bb594',
           storageBucket: 'cgc-app-bb594.appspot.com',
           messagingSenderId: '202153245577',
           appId: '1:202153245577:web:b50a7f06f3a8acdc',
           vapidKey:
             'BGufm45FPp3ARsSvw9hxPbYjLvKWewudy5GuOU5yVAZnHiOsMvL6VqshoM669Xuq8ycDGy4kEWAFI5R_VtQGMoQ',
           measurementId: 'G-3ZVD4V51SD'
         },
         pushcode: {
           ios:
             'uJJSJYtur--U8ygVud01NcATOAtn49584550-0ff9-4f53-bed2-fbba7f02d5fe',
           android:
             'Z12wnuj31oDnTKyNjoWa7Yddpc2049584550-0ff9-4f53-bed2-fbba7f02d5fe'
         },
         stripe: 'pk_test_Ze48Sc3P1nyozHbzFZRom7Dy003HY2uM5b',
         googleAnalytics: '215963959'
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
