import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { AlertController, ModalController, Platform } from '@ionic/angular'
import { PostResource } from '../post-resource.service'
import { PostShowComponent } from '../post-show/post-show.component'
import { TranslateService } from '@ngx-translate/core'
import * as _ from 'lodash'
import { GlobalProvider, GlobalService, ToastService } from '@xplat-cgc/ionic/features/providers'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'

@Component({
    selector: 'app-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PostListComponent implements OnInit {

    public list: any[];
    public user = this.globalProvider.global['user'];
    public folders = [];
    public backFolder = [];
    public loading = false;
    public level = 0;
    public folder_up_id = 0;
    public title_folder = 'My folders';
    public noteFolder: any = {};
    public sortType = 'name';
    public global = {};

    constructor(
        protected postResource: PostResource,
        protected globalProvider: GlobalProvider,
        protected events: Events,
        protected globalService: GlobalService,
        protected translate: TranslateService,
        protected alertController: AlertController,
        protected toastService: ToastService,
        public platform: Platform,
        protected modalCtrl: ModalController
    ) {
      this.global = this.globalProvider.global;
    }

    ngOnInit() {
        this.onQuery('null');
    }

    async remove(post) {
        const translate = await this.translate.get([
            'You really want to delete the note',
            'Confirm',
            'Cancel'
        ]).toPromise();
        const alert = await this.alertController.create({
            header: translate['Confirm'] + '!',
            message: translate['You really want to delete the note'] + '?',
            buttons: [
                {
                    text: translate['Cancel'],
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Ok',
                    handler: () => {
                        this.postResource.delete({
                            id: post['id']
                        }).then((response) => {
                            _.remove(this.list, function (row) {
                                return row['id'] === post['id'];
                            });
                            this.toastService.success({
                                message: response['message']
                            });
                        }).catch((response) => {
                            this.toastService.error({
                                message: response['message']
                            });
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    onQuery(folder_up) {
        this.folders = [];
        this.list = [];
        this.loading = true;
        this.globalService.fillBatch({
            'noteFolder': {
                '_sort': this.sortType,
                'user_id': this.user.id,
                'folder_up_id': folder_up === 0 ? 'null' : folder_up
            }
        }).then(data => {
            this.folders = data['data']['noteFolder'];
        });
        let sortNotes = this.sortType;
        switch (sortNotes) {
            case 'name':
                sortNotes = 'title';
                break;
            case '-name':
                sortNotes = '-title';
                break;
        }
        this.postResource.list({
            '_sort': sortNotes,
            'type': 'post',
            'user_created_id': this.user.id,
            '_note_folder_id': folder_up === 0 ? 'null' : folder_up
        }).then(response => {
            this.list = response.data.data;
            this.loading = false;
        });
    }

    onClickFolder(row) {
        this.backFolder[this.level] = {
            noteFolder: this.noteFolder,
            folder_up_id: this.folder_up_id,
            title_folder: this.title_folder
        };
        this.noteFolder = row;
        this.level++;
        this.folder_up_id = this.noteFolder.id;
        this.title_folder = this.noteFolder.name;
        this.onQuery(this.folder_up_id);
    }

    onBackFolder() {
        this.level--;
        this.noteFolder = this.backFolder[this.level].noteFolder;
        this.folder_up_id = this.backFolder[this.level].folder_up_id > 0 ? this.backFolder[this.level].folder_up_id : 'null';
        this.title_folder = this.backFolder[this.level].title_folder;
        this.onQuery(this.folder_up_id);
    }

    async presentModal(row) {
        const modal = await this.modalCtrl.create({
            component: PostShowComponent,
            componentProps: {post: row},
            cssClass: 'new-post-modal-css',
            backdropDismiss: false
        });
        modal.present();
        modal.onDidDismiss().then((data) => {
            //
        });
    }

    onDismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalCtrl.dismiss({
            'dismissed': true
        });
    }

}
