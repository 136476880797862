import { BaseCopyResources } from './base-copy-resources';
import { BaseResources } from './base-resources';
import {Injectable} from '@angular/core';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
  pathPrefix: '/auth'
})

export class AuthResource extends BaseCopyResources {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/login',
    auth: false
  })
  login: IResourceMethod<Object, Object>;

  @ResourceAction({
    pathPrefix: '/auth',
    method: ResourceRequestMethod.Get,
    path: '/me',
    auth: false
  })
  me: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/email',
    auth: false
  })
  recovery: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/reset',
    auth: false
  })
  reset: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/token/verify',
    auth: false
  })
  verify: IResourceMethod<Object, Object>;
}
