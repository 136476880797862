<ion-item-divider sticky color="light" padding-end="" padding-start="" *ngIf="global['result']['strong']['data']">
    <ion-label slot="start" class="text-primary">{{"Results For" | translate}}
        : {{global['result']['strong']['queryText']}}</ion-label>
</ion-item-divider>
<div class="ion-no-padding">
    <div *ngIf="global['result']['strong']['loading']">
        <div class="ion-padding custom-skeleton" *ngFor="let ske of [1,2,3,4,5,6,7]">
            <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
        </div>
    </div>
    <table class="main" [innerHTML]="global['result']['strong']['data']"></table>
  <!--div class="content-center background-placeholder" *ngIf="(!global['result']['strong']['data'] && !global['result']['strong']['loading']) && global['mobile']">
    <fa-icon [icon]="['fas', 'pen-fancy']" size="3x" style="transform: rotate(135deg);"></fa-icon>
    <h2 class="title"><strong>{{'Enjoy your study' | translate}}!</strong></h2>
    <div>{{'Enter a word or phrase to begin a search' | translate}}</div>
  </div-->
</div>
