import { DomSanitizer } from '@angular/platform-browser'
import { PostResource } from './../post-resource.service'
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core'
import { AlertController, Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import * as _ from 'lodash'
import { HttpClient, HttpEventType } from '@angular/common/http'
import * as moment from 'moment'
import { Storage } from '@ionic/storage'
import { ActivatedRoute, Router } from '@angular/router'
import { File } from '@ionic-native/file/ngx'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers'
import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { Downloader, DownloadEventData, ProgressEventData } from 'capacitor-downloader'

import { LocalNotifications } from '@capacitor/local-notifications'


@Component({
  selector: 'app-post-list-downloaded',
  templateUrl: './post-list-downloaded.component.html',
  styleUrls: ['./post-list-downloaded.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostListDownloadedComponent implements OnInit {
  public HASH_DOWNLOADED = 'listDownloaded';
  public objectKeys = Object.keys;
  public list = [];
  public global = {};
  public navigation;
  public downloading;
  public progress;
  public imageDownloaderId;

  constructor(
    protected http: HttpClient,
    public storage: Storage,
    protected route: ActivatedRoute,
    public router: Router,
    public platform: Platform,
    public globalProvider: GlobalProvider,
    protected postResource: PostResource,
    protected events: Events,
    protected translate: TranslateService,
    protected file: File,
    protected alertController: AlertController,
    private domSanitizer: DomSanitizer,
    public _zone: NgZone
  ) {
    this.global = globalProvider['global'];
  }

  async ngOnInit() {
    this.navigation = this.router.getCurrentNavigation();
    /*if (this.platform.is('cordova')) {
      alert("cordova")
      this.file.checkDir(this.file.dataDirectory, 'cgc').then(() => {
        this.startDownload();
        this.file.listDir(this.file.dataDirectory, 'cgc').then((response) => {
        });
      }).catch(() => {
        this.file.createDir(this.file.dataDirectory, 'cgc', false).then((resCreate) => {
          console.log('createDir', resCreate);
          this.startDownload();
        });
      });
    } else */
    if (this.platform.is('capacitor') || this.platform.is('cordova')) {
      this.file

        .checkDir(this.file.dataDirectory, 'cgc')
        .then(() => {
          console.log('checkDir');
          this.startDownload();
          this.file.listDir(this.file.dataDirectory, 'cgc').then(response => {
            console.log('response', response);
          });
        })
        .catch(() => {
          this.file
            .createDir(this.file.dataDirectory, 'cgc', false)
            .then(resCreate => {
              console.log('createDir', resCreate);
              this.startDownload();
            });
        });
    } else {
      this.startDownload();
    }
  }

  startDownload() {
    this.getListDownloaded()
      .then(list => {
        this.global['listDownloaded'] = list || [];
        if (
          this.navigation &&
          this.navigation.extras &&
          this.navigation.extras.state
        ) {
          this.setPostDowloaded(this.navigation.extras.state);
        }
      })
      .catch(e => {
        console.log('error', e);
      });
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter--');
  }

  async onRemove(params, parentID) {
    this.translate
      .get(['Remove ' + params['type'], 'Cancel', 'Ok', 'Confirm'], {})
      .subscribe(async (res: string) => {
        const alert = await this.alertController.create({
          header: res['Confirm'] + '!',
          message: res['Remove ' + params['type']],
          buttons: [
            {
              text: res['Cancel'],
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: res['Ok'],
              handler: async () => {
                const parent = _.findLast(this.global['listDownloaded'], [
                  'id',
                  parentID
                ]);
                parent.assets = _.reject(parent.assets, [
                  'type',
                  params['type']
                ]);
                if (_.isEmpty(parent.assets)) {
                  this.global['listDownloaded'] = _.reject(
                    this.global['listDownloaded'],
                    ['id', parentID]
                  );
                }
                this.setListDownloaded(this.global['listDownloaded']);
                if (
                  this.platform.is('capacitor') ||
                  this.platform.is('cordova')
                ) {
                  if (!_.isEmpty(params['filepath'])) {
                    //if (!params['new']) {
                    try {
                      this.file.removeFile(
                        this.file.dataDirectory + 'cgc/',
                        params['filename']
                      );
                    } catch (e) {}
                    /*} else {
                    try {
                      await Filesystem.deleteFile({
                        path: 'cgc/' + params['filename'],
                        directory: FilesystemDirectory.Data
                      });
                    } catch (e) {
                    }
                  }*/
                  }
                }
              }
            }
          ]
        });
        await alert.present();
      });
  }

  onCancel(params) {
    try {
      this.global['downloader'][params['id']]['fileTransfer'].cancel({
        id: this.global['downloader'][params['id']]['downloaderId']
      });
    } catch (e) {
      console.log('Error unsuscribe', e);
      try {
        this.global['downloader'][params['id']]['fileTransfer'].abort();
      } catch (e) {
        console.log('Error abort', e);
      }
    }
    params['status'] = 'canceled';
    this.downloading = null;
    this.setListDownloaded(this.global['listDownloaded']);
  }

  setPostDowloaded(post): void {
    const index = _.findIndex(this.global['listDownloaded'], [
      'id',
      post['post']['id']
    ]);
    let assets = [];

    if (index >= 0) {
      assets = this.global['listDownloaded'][index]['assets'];
      let find = _.find(this.global['listDownloaded'][index]['assets'], [
        'type',
        post['type']
      ]);
      if (!_.isEmpty(find)) {
        return;
      }
    }

    let asset = null;
    if (post['type'] == 'pdf' || post['type'] == 'book') {
      asset = {
        status: 'downloading',
        progressDownload: -1,
        id: post['post']['id'],
        title: post['post']['title'],
        url: environment.api + 'post/' + post['post']['id'] + '/pdf/download',
        type: 'pdf',
        post_id: post['post']['post_id'],
        new: true
      };
    } else if (post['type'] == 'post') {
      asset = {
        status: 'downloading',
        progressDownload: -1,
        id: post['post']['id'],
        title: post['post']['title'],
        url: environment.api + 'post/' + post['post']['id'],
        type: 'post',
        post_id: post['post']['post_id'],
        new: true
      };
    } else {
      asset = _.find(post['post']['assets'], ['type', post['type']]);
      asset['status'] = 'downloading';
      asset['progressDownload'] = -1;
      asset['url'] =
        environment.api +
        'post/' +
        asset['id'] +
        '/' +
        asset['type'] +
        '/download';
      asset['new'] = true;
    }

    //this.downloading = _.find(this.global['listDownloaded'], ['status', 'downloading']);
    this.getDownloading();
    if (this.downloading) {
      asset['status'] = 'prepare';
    }

    assets.unshift(asset);

    if (index >= 0) {
      this.global['listDownloaded'][index]['assets'] = assets;
    } else {
      post['post']['assets'] = assets;
      this.global['listDownloaded'].unshift(post['post']);
    }

    this.setListDownloaded(this.global['listDownloaded']);

    if (asset['status'] == 'downloading') {
      setTimeout(() => {
        this.generateDownload(asset);
      }, 2000);
    }
  }

  async setListDownloaded(list) {
    await this.storage.set(this.HASH_DOWNLOADED, list);
  }

  getListDownloaded() {
    return this.storage.get(this.HASH_DOWNLOADED);
  }

  getLinkVideo(params) {
    let config = {
      headers: {
        Authorization: 'Bearer ' + window.localStorage['token']
      }
    };
    return this.http.get(params['url'], config);
  }

  requestDownload(params) {
    let config = {
      headers: {
        Authorization: 'Bearer ' + window.localStorage['token']
      },
      //observe : 'events',
      //responseType: 'blob',
      reportProgress: true
    };

    if (
      params['type'] !== 'pdf' &&
      params['type'] !== 'post' &&
      params['type'] !== 'book'
    ) {
      config['observe'] = 'events';
      config['responseType'] = 'blob';
    }
    if (params['type'] === 'video') {
      return this.http.get(params['url'], {
        observe: 'events',
        responseType: 'blob',
        reportProgress: true
      });
    }
    return this.http.get(params['url'], config);
  }

  onDownload(params) {
    this.downloading = params;
    params['status'] = 'downloading';
    params['progressDownload'] = -1;
    this.generateDownload(params);
  }

  async generateDownload(params) {
    this.global['downloader'][params['id']] = {
      progressDownload: -1,
      title: params['title'],
      type: params['type'],
      post_id: params['post_id']
    };
    let link = null;
    if (
      params['type'] == 'video' &&
      params['url'].indexOf('player.vimeo.com/') < 0
    ) {
      link = await this.getLinkVideo(params).toPromise();
      if (link['data']) {
        params['url'] = link['data'];
      }
    }

    let blob = null;
    let name = '';
    if (params['type'] == 'video') {
      name = moment().format('x') + '.mp4';
    }
    if (params['type'] == 'audio') {
      name = moment().format('x') + '.mp3';
    }

    const downloader = new Downloader();
    const path = this.file.dataDirectory + 'cgc/';

    const data = await downloader.createDownload({
      url: params['url'],
      path: path,
      fileName: name.trim(),
      headers: {
        Authorization: 'Bearer ' + window.localStorage['token']
      }
    });
    //this.imageDownloaderId = data.value;
    this.global['downloader'][params['id']]['downloaderId'] = data.value;
    this.global['downloader'][params['id']]['fileTransfer'] = downloader;
    params['status'] = 'downloading';
    this.downloading = true;
    if (params['type'] == 'pdf' || params['type'] == 'book') {
      return this.dowloadPost(params);
    }
    await this.setListDownloaded(this.global['listDownloaded']);
    downloader
      .start(
        { id: this.global['downloader'][params['id']]['downloaderId'] },
        (progressData: ProgressEventData) => {
          this._zone.run(() => {
            const post = _.find(this.global['listDownloaded'], [
              'post_id',
              parseInt(params['post_id'])
            ]);
            const asset = _.find(post['assets'], ['id', params['id']]);
            asset['progressDownload'] = progressData.value;
            this.downloading = true;
            //this.setListDownloaded(this.global['listDownloaded']);
          });
        }
      )
      .then(async (completed: DownloadEventData) => {
        this._zone.run(async () => {
          const post = _.find(this.global['listDownloaded'], [
            'post_id',
            parseInt(params['post_id'])
          ]);
          const asset = _.find(post['assets'], ['id', params['id']]);
          asset['filepath'] = path;
          asset['filename'] = name;
          asset['url'] = name;

          this.notifyDownloadComplete(asset);

          asset['status'] = 'downloaded';
          await this.setListDownloaded(this.global['listDownloaded']);
          setTimeout(() => {
            this.downloading = null;
            this.setNextDownload();
          })
          console.log(`Image : ${completed.path}`);
        });
      })
      .catch(async error => {
        this._zone.run(async () => {
          const post = _.find(this.global['listDownloaded'], [
            'post_id',
            parseInt(params['post_id'])
          ]);
          const asset = _.find(post['assets'], ['id', params['id']]);
          console.log(error);
          if (this.platform.is('capacitor') || this.platform.is('cordova')) {
            this.notifyDownloadFailed(asset);
          }
          asset['status'] = 'failed';
          await this.setListDownloaded(this.global['listDownloaded']);
          setTimeout(() => {
            this.downloading = null;
            this.setNextDownload();
          })
        });
      });
    /*downloader.resume(
        { id: this.global['downloader'][params['id']]['downloaderId'] },
        resume => {
          console.log("resume ->", resume);
        }
      );*/

    return;
    this.global['downloader'][params['id']][
      'fileTransfer'
      ] = this.requestDownload(params)
      //.pipe(delay(1000))
      .subscribe(
        async result => {
          const post = _.find(this.global['listDownloaded'], [
            'post_id',
            parseInt(params['post_id'])
          ]);
          const asset = _.find(post['assets'], ['id', params['id']]);
          if (result['type'] === HttpEventType.DownloadProgress) {
            asset['progressDownload'] = (
              (result['loaded'] / result['total']) *
              100
            ).toFixed(0);
            asset['status'] = 'downloading';
            //this.setListDownloaded(this.global['listDownloaded']);
          }
          if (result['type'] === HttpEventType.Response) {
            if (
              (this.platform.is('capacitor') || this.platform.is('cordova')) &&
              asset['type'] != 'pdf' &&
              asset['type'] != 'post' &&
              asset['type'] != 'book'
            ) {
              try {
                let blob = null;
                let name = '';
                if (asset['type'] == 'video') {
                  blob = new Blob([result['body']], {
                    type: 'video/mp4'
                  });

                  name = moment().format('x') + '.mp4';
                }
                if (asset['type'] == 'audio') {
                  blob = new Blob([result['body']], {
                    type: 'audio/mpeg'
                  });
                  name = moment().format('x') + '.mp3';
                }

                const path = this.file.dataDirectory + 'cgc/';
                /* try {
              console.log(result['body']);
              const writeResult = await Filesystem.writeFile({
                path: 'cgc/' + name,
                data: await new Response(blob).text(),
                directory: FilesystemDirectory.Data,
                encoding: FilesystemEncoding.UTF8
              })
              console.log('Wrote file', writeResult);
            } catch(e) {
              if (this.platform.is('capacitor') || this.platform.is('cordova')) {
                this.notifyDownloadFailed(asset);
              }
              asset['status'] = 'failed';
              this.setListDownloaded(this.global['listDownloaded']);
              this.setNextDownload();
              console.error('Unable to write file', e);
              return;
            }*/
                this.file.writeFile(path, name, blob).then(() => {
                  asset['filepath'] = path;
                  asset['filename'] = name;
                  asset['url'] = name;

                  this.notifyDownloadComplete(asset);

                  asset['status'] = 'downloaded';
                  this.setListDownloaded(this.global['listDownloaded']);
                  this.setNextDownload();
                });
              } catch (e) {
                console.error('Unable to write file', e);
              }
            } else {
              asset['status'] = 'downloaded';
              this.setListDownloaded(this.global['listDownloaded']);
              this.setNextDownload();
            }
          }
          if (result['status_code'] === 200) {
            if (asset['type'] == 'pdf' || asset['type'] == 'book') {
              asset['data'] = result['data'];
            }

            if (asset['type'] == 'post') {
              asset['data'] = result['data'];
            }
            asset['status'] = 'downloaded';
            this.setListDownloaded(this.global['listDownloaded']);
            this.setNextDownload();
          }
        },
        error => {
          const post = _.find(this.global['listDownloaded'], [
            'post_id',
            params['post_id']
          ]);
          const asset = _.find(post['assets'], ['id', params['id']]);
          if (this.platform.is('capacitor') || this.platform.is('cordova')) {
            this.notifyDownloadFailed(asset);
          }
          asset['status'] = 'failed';
          this.setListDownloaded(this.global['listDownloaded']);
          this.downloading = null;
          this.getDownloading();
          //this.downloading = _.find(this.global['listDownloaded'], ['status', 'prepare']);
          setTimeout(() => {
            this.generateDownload(this.downloading);
          }, 2000);
        }
      );
  }

  dowloadPost(params) {
    this.global['downloader'][params['id']][
      'fileTransfer'
      ] = this.requestDownload(params).subscribe(
      async result => {
        const post = _.find(this.global['listDownloaded'], [
          'post_id',
          parseInt(params['post_id'])
        ]);
        const asset = _.find(post['assets'], ['id', params['id']]);
        if (result['status_code'] === 200) {
          if (asset['type'] == 'pdf' || asset['type'] == 'book') {
            asset['data'] = result['data'];
          }

          if (asset['type'] == 'post') {
            asset['data'] = result['data'];
          }
          asset['status'] = 'downloaded';
          this.setListDownloaded(this.global['listDownloaded']);
          setTimeout(() => {
            this.downloading = null;
            this.setNextDownload();
          })
        }
      },
      error => {}
    );
  }

  notifyDownloadFailed(asset) {
    this.translate.get('Download failed', {}).subscribe(async (res: string) => {
      const notifs = await LocalNotifications.schedule({
        notifications: [
          {
            title: res,
            body: res + ' ' + asset['title'],
            id: 1
          }
        ]
      });
    });
  }

  notifyDownloadComplete(asset) {
    this.translate
      .get('Download complete', {})
      .subscribe(async (res: string) => {
        const notifs = await LocalNotifications.schedule({
          notifications: [
            {
              title: res,
              body: res + ' ' + asset['title'],
              id: 1
            }
          ]
        });
      });
  }

  getDownloading() {
    let downloading = null;
    _.each(this.global['listDownloaded'], (v, k) => {
      if (!this.downloading) {
        console.log(k, v, v['assets']);
        downloading = _.find(v['assets'], row => {
          return row['status'] == 'downloading';
        });
        if (downloading) {
          this.downloading = downloading;
        }
      }
    });
  }
  setNextDownload() {
    this.downloading = null;
    let downloading = null;
    _.each(this.global['listDownloaded'], (v, k) => {
      if (!this.downloading) {
        downloading = _.find(v['assets'], row => {
          return row['status'] == 'prepare' || row['status'] == 'canceled';
        });
        if (downloading) {
          this.downloading = downloading;
          this.generateDownload(this.downloading);
        }
      }
    });
  }

  async onSelect(data) {
    this.global['searchType'] = data['type'];
    if (
      this.global['originSelect'] == 'pdf' ||
      this.global['originSelect'] == 'video' ||
      this.global['originSelect'] == 'audio'
    ) {
      this.global['originSelect'] = this.global['originSelect'];
    } else {
      this.global['originSelect'] = 'download';
    }
    this.global['visors'] = [];
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    let url = '';
    if (this.global['mobile']) {
      url = '/tabs/study/post/downloaded/viewer';
      /*if (data['type'] === 'audio') {
        url = '/tabs/study/post/downloaded/viewerMusic';
      }*/
    } else {
      url = '/search';
    }

    /*if (data['type'] === 'audio') {
      data['url'] = data['url'].replace("file:///","/");
      this.router.navigate([url, data]);
      return true;
    }*/
    const path = this.file.dataDirectory + 'cgc/';
    data['local'] = true;
    if (data['url_complete']) {
      data['url'] = data['url_complete'];
    } else {
      /*if (data['new']) {
        let ret = await Filesystem.getUri({
          path: 'cgc',
          directory: FilesystemDirectory.Data
        });
        console.log(JSON.stringify(ret));
        console.log(FilesystemDirectory.Data);
        data['url'] = ret['uri'] + data['filename'];
      } else {*/
      data['url'] = path + data['filename'];
      //}
      console.log(data['url']);
      /*   if (this.platform.is('ios')) {
          // @ts-ignore
          data['url'] = window['Ionic']['WebView'].convertFileSrc(data['url']);
        } else {*/
      // @ts-ignore
      if (data['type'] === 'video') {
        data['url'] = window['Ionic']['WebView'].convertFileSrc(data['url']);
      }
      /*} */
      console.log(data['url']);
    }
    /*if (data['type'] === 'audio') {
      this.router.navigate([url, data]);
      return true;
    }*/

    this.router.navigate([url]).then(() => {
      this.global['loadingViewer'] = true;
      if (data['type'] === 'audio') {
        this.global['loadingViewer'] = true;
        this.events.publish('event:viewer', {
          type: data['type'],
          data: data
        });
      } else if (data['type'] === 'video') {
        this.events.publish('event:viewer', {
          type: 'video',
          data: data
        });
      } else if (data['type'] === 'pdf' || data['type'] === 'book') {
        this.global['result'][data['type']]['title'] = data['title'];

        this.events.publish('event:viewer', {
          type: 'pdf',
          data: data
        });
      } else if (data['type'] === 'post') {
        this.global['result'][data['type']]['title'] = data['data']['title'];
        this.events.publish('event:viewer', {
          type: 'post',
          data: data['data']
        });
      }
    });
  }
}
