import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { LoadingController, ModalController, ToastController, ActionSheetController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { MediaObject, Media } from '@ionic-native/media/ngx';
import * as _ from 'lodash';
import { MusicControlsService } from './musicControls.service';
import { App } from '@capacitor/app';

import { AudioStatisticService } from './audio-statistics.service';
import { Store } from '@ngrx/store'
import { PlayerState } from '../state/interfaces/player-state.interface'
import { deleteQueueTrack, setPlayerCurrentTrack } from '../state/actions/player.actions'
import { Capacitor } from '@capacitor/core'
declare var window;
var statusApp = {
  isActive: true
};
App.addListener('appStateChange', state => {
  statusApp = state
  /*if (state.isActive) {

  } else if (!state.isActive) {
    cordova.plugins.backgroundMode.enable();
    cordova.plugins.backgroundMode.overrideBackButton();
    cordova.plugins.backgroundMode.disableWebViewOptimizations();
  }*/
});
@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  selectedMusics: any = [];
  playButton: boolean;
  footer: boolean;
  togPlay: boolean;
  slidSelectedMusics: any = [];
  arrayData: Array<any> = [];
  trackCurrent = null;
  musicPrevious = null;
  fromSongs = '';
  file: MediaObject;
  togMinus: boolean;
  togHeart: boolean;
  togRepeat = 0;
  buttonToggle = false;
  trackListType = 'GENERAL'
  trackList = []
  trackHistory = [{}]
  trackHistoryCurrent = 1;
  tracSearchkList = {};
  slidesMusic = [
    { title: 'Playlist' },
    { title: 'Artists' },
    { title: 'Albums' },
  ];
  slidesPodcasts = [
    { title: 'Episodes' },
    { title: 'My Downloads' },
    { title: 'Shows' },
  ];
  public userName = new BehaviorSubject<any>('');
  nameOfUser = this.userName.asObservable();

  public trackUrl = new BehaviorSubject<any>('');
  urlTrack = this.trackUrl.asObservable();

  public selectedMusic = new BehaviorSubject<any>('');
  musicSelected = this.selectedMusic.asObservable();

  public heartValue = new BehaviorSubject<any>('');
  valueHeart = this.heartValue.asObservable();

  public playValue = new BehaviorSubject<any>('');
  valuePlay = this.playValue.asObservable();

  public minusValue = new BehaviorSubject<any>('');
  valueMinus = this.minusValue.asObservable();

  public songsDetails = new BehaviorSubject<any>('');
  detailOfSong = this.songsDetails.asObservable();

  // tslint:disable-next-line: variable-name
  public Username(user_name: string) {
    this.userName.next(user_name);
  }

  // tslint:disable-next-line: variable-name
  public soundUrl(track_url: any) {
    this.trackUrl.next(track_url);
  }

  // tslint:disable-next-line: variable-name
  public toggleHeart(heart_value: boolean) {
    this.heartValue.next(heart_value);
  }

  // tslint:disable-next-line: variable-name
  public togglePlay(play_value: boolean) {
    this.playButton = play_value
    this.playValue.next(play_value);
  }


  public setPreventTrack(track, history = true, play = true) {
    return false;
    if (history) {
      this.trackHistory.push(track)
      this.trackHistoryCurrent = this.trackHistory.length - 1;
    }

    if (!this.file || track['id'] !== this.trackCurrent['id']) {
      this.is_ready = false;
      if (this.file) {
        this.stop();
      }
      this.togglePlay(false);
      this.trackCurrent = track;
      this.getDurationAndSetToPlay();
      if (this.play) {
        this.settingMusicControl();
        this.play();
      }
      this.selectedMusic.next(track);
    }
  }
  // tslint:disable-next-line: variable-name
  public setTrack(track, history = true) {
    if (!track['music_type']) {
      track['music_type'] == 'player';
    }
    if (history) {
      this.trackHistory.push(track)
      this.trackHistoryCurrent = this.trackHistory.length - 1;
    }
    if (!this.file || track['id'] !== this.trackCurrent['id']) {
      this.audioStatisticService.changeSession();
      this.is_ready = false;
      if (this.file) {
        this.stop();
      }
      this.audioStatisticService.start()
      this.DISPLAY_DURATION = '00:00'
      this.DISPLAY_POSITION = '00:00'
      this.duration = -1;
      this.duration_string = null;
      this.position = 0;
      this.togglePlay(false);
      this.trackCurrent = track;
      this.getDurationAndSetToPlay();

      this.play();
      this._store.dispatch(setPlayerCurrentTrack({ track: track }))
      this.selectedMusic.next(track);
      this.settingMusicControl();
    }
  }

  // tslint:disable-next-line: variable-name
  public toggleMinus(minus_value: boolean) {
    this.minusValue.next(minus_value);
  }
  // tslint:disable-next-line: variable-name
  public mightLikesongs(might_Like: any) {
    this.songsDetails.next(might_Like);
  }

  is_playing = false;
  isInPlay = false;
  is_ready = false;

  message: any;

  duration: any = -1;
  duration_string: string;
  position: any = 0;
  DISPLAY_POSITION: any = '00:00';
  DISPLAY_DURATION: any = '00:00';
  lastPosition;
  togRandom: boolean = false;
  timer = null;
  constructor(
    protected audioStatisticService: AudioStatisticService,
    public loadCtrl: LoadingController,
    public modalCtrl: ModalController,
    private musicControlsService: MusicControlsService,
    public media: Media,
    public toastCtrl: ToastController,
    public actionCtrl: ActionSheetController,
    public platform: Platform,
    protected _zone: NgZone,
    protected _store: Store<{ player: PlayerState }>
  ) {
    try {
      if (Capacitor.isNativePlatform()) {
        this.timer = new window.nativeTimer();
      }
    } catch(e) {
      console.error(e);
    }

    this.platform.resume.subscribe(async () => {
      if (this.trackCurrent) {
        this.activeTimer()
      }
    });

    this.togMinus = false;
    this.togHeart = false;
    this.togPlay = false;
    this.musicControlsService.events.subscribe((action) => {
      this._zone.run(() => {
        const message = action.message;
          console.log('message', message);

          switch (message) {
            case 'music-controls-next':
              this.audioStatisticService.event({
                ...this.trackCurrent,
                "event": message,
                position: this.lastPosition,
                duration : this.DISPLAY_DURATION
              });
              // Do something
              this.nextTrack();
              break;
            case 'music-controls-previous':
              this.audioStatisticService.event({
                ...this.trackCurrent,
                "event": message,
                position: this.lastPosition,
                duration : this.DISPLAY_DURATION
              });
              this.previousTrack();
              // Do something
              break;
            case 'music-controls-pause':
              this.audioStatisticService.event({
                ...this.trackCurrent,
                "event": message,
                position: this.lastPosition,
                duration : this.DISPLAY_DURATION
              });
              // Do something
              console.log('############################## music pause');
              this.pause(false);
              /*this.file.pause();
            this.musicControlsService.listen();
            this.musicControlsService.updateIsPlaying(false);*/
              break;
            case 'music-controls-play':
              this.audioStatisticService.event({
                ...this.trackCurrent,
                "event": message,
                position: this.lastPosition,
                duration : this.DISPLAY_DURATION
              });
              // Do something
              console.log('############################## music play');
              this.play();
              break;
            case 'music-controls-destroy':
              this.audioStatisticService.event({
                ...this.trackCurrent,
                "event": message,
                position: this.lastPosition,
                duration : this.DISPLAY_DURATION
              });
              this.file.pause();
              this.musicControlsService.destroy();
              this.togglePlay(false);
              /*this.playButton = false;
            this.togglePlay(false);*/
              // Do something
              break;
            // External controls (iOS only)
            case 'music-controls-toggle-play-pause':
              // Do something
              break;
            case 'music-controls-skip-to':
                this.audioStatisticService.event({
                  ...this.trackCurrent,
                  "event": message,
                  position: this.lastPosition,
                  duration : this.DISPLAY_DURATION
                });
                this.position = action['position'];
                this.lastPosition = action['position'];
                this.file.seekTo(parseFloat(action['position']) * 1000);
              // Do something
              break;
            case 'music-controls-seek-to':
              // Do something
              break;
            case 'music-controls-skip-forward':
              // Do something
              break;
            case 'music-controls-skip-backward':
              // Do something
              break;

            // Headset events (Android only)
            // All media button events are listed below
            case 'music-controls-media-button':
              // Do something
              break;
            case 'music-controls-headset-unplugged':
              // Do something
              break;
            case 'music-controls-headset-plugged':
              // Do something
              break;
            default:
              break;
          }
        });
      }

    );
  }

  settingMusicControl() {
    //this.musicControlsService.destroy(); // it's the same with or without the destroy
    this.musicControlsService.create({
      track: this.trackCurrent.title,
      artist: this.trackCurrent.artist,
      cover: environment.resources + this.trackCurrent.image,
      isPlaying: true,
      dismissable: true,
      duration: parseFloat(this.trackCurrent['playtime'] || 0), // optional, default: 0
      elapsed: 1, // optional, default: 0
      // hide previous/next/close buttons:
      hasPrev: this.trackHistoryCurrent > 1, // show previous button, optional, default: true
      hasNext: true, // show next button, optional, default: true
      hasClose: true, // show close button, optional, default: false
      hasSkipForward: false, // show skip forward button, optional, default: false
      hasSkipBackward: false, // show skip backward button, optional, default: false
      skipForwardInterval: 0, // display number for skip forward, optional, default: 0
      skipBackwardInterval: 0, // display number for skip backward, optional, default: 0
      hasScrubbing: true, //optional. default to false. Enable scrubbing from control center progress bar

      // iOS only, optional
      album: this.trackCurrent.album // optional, default: ''
      // duration: 0, // optional, default: 0
      // elapsed: 0, // optional, default: 0

      // Android only, optional
      // text displayed in the status bar when the notific\ation (and the ticker) are updated
      //ticker: 'Now playing test'
    });

    //this.musicControlsService.listen();
    //this.musicControlsService.updateIsPlaying(true);
    //this.togglePlay(true);
  }

  previousTrack() {
    if (this.trackHistoryCurrent == 1) {
      return;
    }
    this.musicPrevious = this.trackCurrent;
    const index = this.trackHistoryCurrent - 1
    this.trackHistoryCurrent = index;
    this.setTrack(this.trackHistory[index], false);
  }

  clearHistory() {
    this.trackHistory = [{}]
    this.trackHistoryCurrent = 1;
  }

  currTimePosChanged(event) {

    if (Math.abs(this.lastPosition - parseInt(event['detail']['value'], 10)) >= 1) {
      this.file.seekTo(event['detail']['value'] * 1000);
    }

  }


  nextTrack() {
    if (!_.isEmpty(this.trackHistory) && this.trackHistoryCurrent < this.trackHistory.length - 1) {
      console.log('next history');
      const index = this.trackHistoryCurrent  + 1
      this.trackHistoryCurrent = index;
      this.setTrack(this.trackHistory[index], false)
      console.log('next history', this.trackHistoryCurrent);
      return;
    }
    console.log('next track');

    let index = null;
    if (!this.togRandom) {
      index = _.findIndex(this.trackList, ['id', this.trackCurrent['id']]);
      ++index
      //if (this.togRepeat === 1) {
      if (_.isEmpty(this.trackList[index]) && this.togRepeat === 2) {
        index = 0;
      }
    } else if (this.togRandom) {
      index = _.random(0, this.trackList.length - 1);
    }


    //this.trackHistory.push(this.trackCurrent)
    //this.trackHistoryCurrent = this.trackHistory.length;
    this.musicPrevious = this.trackCurrent;

    //remove for queue
    this._store.dispatch(deleteQueueTrack({id: this.trackCurrent.id}));

    this.setTrack(this.trackList[index], true)

  }

  /*play(trckUrl: string) {
    if (!this.file || this.trckUrl !== trckUrl) {
      if (this.file) {
        this.file.release();
      }
      //this.file = this.media.create(trckUrl);
      this.getDurationAndSetToPlay(trckUrl);
    }
    this.trckUrl = trckUrl;
    //this.file.play();
    this.file.onStatusUpdate.subscribe(status => console.log("onStatusUpdate", status)); // fires when file status changes
    this.file.onSuccess.subscribe(() => console.log('Action is successful'));

    this.file.play();
    this.playButton = true;
    this.settingMusicControl();
    this.togglePlay(true);
    this.file.play();
  }*/



  async loader(spinner?: any, duration?: number, message?: string, cssClass?: string) {
    const loader = await this.loadCtrl.create({
      spinner,
      duration,
      message,
      translucent: true,
      cssClass
    });
    loader.present();
  }


  async loadModal(component?: any, props?: any, index?: undefined, download?: boolean) {
    const modal = await this.modalCtrl.create({
      component,
      componentProps: {
        value: props, index, download
      },
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'customCss'
    });
    return modal;
  }


  async showToast(message: string, color: string = 'light') {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'bottom',
      cssClass: 'toastCss',
      color
    });
    return toast;
  }
  async presentActionSheet(header: string, ) {
    const actionSheet = await this.actionCtrl.create({
      header,
      cssClass: 'actionCss',
      buttons: [{
        text: 'Relevence',
        role: 'destructive',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Custom order',
        handler: () => {
          console.log('Share clicked');
        }
      }, {
        text: 'Name',
        handler: () => {
          console.log('Play clicked');
        }
      }, {
        text: 'Recently played',
        handler: () => {
          console.log('Favorite clicked');
        }
      }, {
        text: 'Recently added',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    return actionSheet;
  }


  getDurationAndSetToPlay() {
    console.log('clear for new track');
    if (this.timer) {
      this.timer.stop();
    }
    this.duration = ~~(this.trackCurrent['playtime']);
    this.DISPLAY_DURATION = this.trackCurrent['playtime_string'];
    this.lastPosition = 0;
    this.position = 0;
    this.setToPlayback();
  }



  setToPlayback() {

    this.file = this.media.create(this.trackCurrent['url_user']);

    this.file.onSuccess.subscribe(() => {
      console.log("succes load audio");
      if (this.DISPLAY_POSITION == '00:00') {
        this.trackCurrent = {
          ...this.trackCurrent,
          playtime: this.file.getDuration(),
        }
        this.duration = ~~(this.trackCurrent['playtime']);
        this.DISPLAY_DURATION = this.toHHMMSS(this.duration);
        this.musicControlsService.updateDuration({
          duration: this.trackCurrent['playtime']
        });
      }
      this.file.getCurrentPosition().then((position) => {
        let validate =  false;

        if (this.platform.is('ios')) {
          validate = parseInt(position, 10) >= parseInt(this.trackCurrent['playtime'], 10);
        } else if (this.platform.is('android')) {
          validate = this.lastPosition > 0 && position < 0;
        }


        console.log("################ validate OS ", validate)
        if (validate) {
          if (this.togRepeat === 1) {
            this.position = 0;
            this.lastPosition = 0;
            this.file.seekTo(0);
            this.play();
            return;
          }
          this.position = 0;
          this.lastPosition = 0;
          this.nextTrack();
        }
      })

    });

    this.file.onStatusUpdate.subscribe(status => {
      console.log("stooooooooped" , status)
      this._zone.run(() => {this.message = status;
      switch (status) {
        case 1: //STARTING
            this.isInPlay = false;
            break;
          case 2:   // 2: RUNNING
          //setTimeout(() => {
            this.audioStatisticService.event({
              ...this.trackCurrent,
              "event": 'music-controls-play',
              position: this.lastPosition,
              duration : this.DISPLAY_DURATION
            });
          //}, (this.gp.global['config']['AUDIO_STATISTICS_TIMER_TRACK'] || 5000))
          this.isInPlay = true;
            this.is_playing = true;
            break;
          case 3:   // 3: PAUSED
            this.isInPlay = true;
            this.is_playing = false;
            this.audioStatisticService.event({
            ...this.trackCurrent,
            "event": 'music-controls-paused',
            position: this.lastPosition,
            duration : this.DISPLAY_DURATION
          });
          break;
        case 4:   // 4: STOPPED
          this.audioStatisticService.event({
            ...this.trackCurrent,
            "event": 'music-controls-stop',
            position: this.lastPosition,
            duration : this.DISPLAY_DURATION
          });
        console.log("stooooooooped 1" , status)
          this.isInPlay = false;
            this.is_playing = false;
            break;
        }
        setTimeout(async () => {
          if (this.is_playing) {
              this.activeTimer();
          } else {
            this.timer.stop();
          }
        }, 1000)
      })
    });

    this.message = 'audio file set';
    this.is_ready = true;

    this.getAndSetCurrentAudioPosition();
  }

  activeTimer() {
    this.timer.stop();
    setTimeout(() => {
      this.timer.start(
        1,
        500,
        () => {
          console.log('start');
        },
        errorMessage => {
          console.log('error', errorMessage);

          // invoked after unsuccessful start
        }
      );
      this.timer.onTick = tick => {
        this.checkPositionTrack();
      };
    }, 500)

  }

  checkPositionTrack() {
    this._zone.run(() => {
      if (!this.file) {
        return;
      }
      this.file.getCurrentPosition().then((position) => {
        if (this.DISPLAY_POSITION == '00:00') {
          this.trackCurrent = {
            ...this.trackCurrent,
            playtime: this.file.getDuration(),
          }
          this.duration = ~~(this.trackCurrent['playtime']);
          this.DISPLAY_DURATION = this.toHHMMSS(this.duration);
          this.musicControlsService.updateDuration({
            duration: this.trackCurrent['playtime']
          });
        }
        this.audioStatisticService.event({
          ...this.trackCurrent,
          "event": 'playing',
          position: this.lastPosition,
          duration : this.DISPLAY_DURATION
        });
        if (position >= 0 && position < this.duration) {
          // update position for display
          this.musicControlsService.updateElapsed({
            elapsed: position,
            isPlaying: true
          });
          this.position = position;
          this.lastPosition = position;
          this.DISPLAY_POSITION = this.toHHMMSS(this.position);
          //}
        }
      });
    });
  }

  getAndSetCurrentAudioPosition() {

    const diff = 1;
    const self = this;

    /*this.get_position_interval = setInterval(() => {
      this.checkPositionTrack();
    }, 500);*/
  }

  toHHMMSS(secs) {
    const SEC_NUM = parseInt(secs, 10);
    const minutes = Math.floor(SEC_NUM / 60) % 60;
    const seconds = SEC_NUM % 60;

    return [minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00' || i >= 0)
      .join(':');
  }


  play() {
    this.file.play({ playAudioWhenScreenIsLocked: true });
    this.musicControlsService.updateIsPlaying(true);
    this.togglePlay(true);
    setTimeout(async () => {
      this.activeTimer();
    }, 500)
  }

  pause(value: boolean) {
    this.file.pause();
    value = false;
    this.is_playing = false;
    this.musicControlsService.updateIsPlaying(value);
    this.togglePlay(false);
    if (this.timer) {
      this.timer.stop();
    }
  }


  stop(clear = false) {
    //this.file.stop();

    this.file.release();
    this.musicControlsService.updateIsPlaying(true);

    //clearInterval(this.get_position_interval);
    this.DISPLAY_DURATION = '00:00'
    this.DISPLAY_POSITION = '00:00'
    this.duration = -1;
    this.duration_string = null;
    this.position = 0;
    //this.musicControlsService.destroy();
    this.togglePlay(false);
    if (this.timer) {
      this.timer.stop();
    }
    if (clear) {
      this.trackCurrent = null;
      this.file = null;
      this.musicControlsService.destroy();
    }
  }

  onToggleRepeat() {
    this.togRepeat++;
    if (this.togRepeat === 3) {
      this.togRepeat = 0;
    }
  }

}



