import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController, ToastController } from '@ionic/angular'
import { UserResource } from '@xplat-cgc/ionic/features/ui/pages/auth/user-resource'
import {
  GlobalProvider,
  GlobalService,
  ToastService
} from '@xplat-cgc/ionic/features/providers'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-aboutus',
  templateUrl: './remove-account-modal.page.html',
  styleUrls: ['./remove-account-modal.page.scss'],
})
export class RemoveAccountModalPage implements OnInit {

  code: string

  isLoading: boolean = false

  constructor(
    public modalController: ModalController,
    protected userResource: UserResource,
    protected globalProvider: GlobalProvider,
    protected globalService: GlobalService,
    protected toastService: ToastService,
    protected translate: TranslateService
  ) { }
  public ngOnInit() {
  }

  async onRemoveAccount() {
    this.isLoading = true
    const translate = await this.translate
      .get([
        'The code entered does not match',
        'Account deleted successfully'
      ])
      .toPromise();
    this.userResource.removeConfirm({
      code: this.code,
      id: this.globalProvider.global['user'].id
    }).then((res) => {
      this.isLoading = false
      this.modalController.dismiss();
      this.globalService.logout('/load');
      this.toastService.success({
        message: translate['Account deleted successfully']
      });
    }).catch((err) => {
      this.isLoading = false
      this.toastService.error({
        message: translate['The code entered does not match']
      });
    })
  }
}
