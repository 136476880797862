import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { State, Store } from '@ngrx/store'
import { debounceTime, tap } from 'rxjs/operators'
import { PlayerState } from '../interfaces/player-state.interface'
import * as PlayerActions from '../actions/player.actions'
import { selectPlayerDownloads } from '../selectors/player.selector'
import { Storage } from '@ionic/storage'

@Injectable()
export class PlayerEffects {
  public HASH_DOWNLOADED = 'listDownloadedMusic'
  loadPlayerDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlayerActions.loadPlayerDownloads),
      tap(() => {
        this.storage.get(this.HASH_DOWNLOADED).then((downloads) => {
          if (downloads) {
            this._store.dispatch(PlayerActions.setPlayerDownloads({ downloads }))
          }
        })
      })
    )
  }, {
    dispatch: false
  })

  updatePlayerDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlayerActions.deletePlayerDownload, PlayerActions.addPlayerDownload, PlayerActions.updatePlayerDownload),
      debounceTime(1000),
      concatLatestFrom(() => this._store.select(selectPlayerDownloads)),
      tap(([, downloads]) => {
        this.storage.set(this.HASH_DOWNLOADED, downloads)
      })
    )
  }, {
    dispatch: false
  })


  constructor(
    private actions$: Actions,
    public storage: Storage,
    protected _store: Store<{ player: PlayerState }>,
    private _state: State<{ player: PlayerState }>
  ) {
  }
}
