import { Component, OnInit } from '@angular/core'
import { SearchMainComponent } from '@xplat-cgc/ionic/features/ui/pages/search/search-main'

@Component({
  selector: 'app-search-main-mobile',
  templateUrl: './search-main-mobile.component.html',
  styleUrls: ['./search-main-mobile.component.scss']
})
export class SearchMainMobileComponent extends SearchMainComponent
  implements OnInit {
  ngOnInit() {}

  ionViewDidEnter() {
    this.global['searchType'] = this.route.snapshot.paramMap.get('section');
  }
}
