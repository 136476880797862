import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-broadcast',
    templateUrl: './broadcast.page.html',
    styleUrls: ['./broadcast.page.scss'],
})
export class BroadcastPage implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
