import {Injectable} from '@angular/core';
import {
    IResourceMethod,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod,
    ResourceResponseBodyType
} from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
    pathPrefix: '/notification/user'
})
export class NotificationUserResource extends BaseResources {
}
