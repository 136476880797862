import { Component, OnInit, ViewChild } from '@angular/core'
import { IonContent, PopoverController } from '@ionic/angular'
import { AuthMenuComponent } from '../auth/auth-menu/auth-menu.component'
import { TranslateService } from '@ngx-translate/core'
import { GlobalProvider } from '../../../providers'

@Component({
    selector: 'app-post',
    templateUrl: './post.page.html',
    styleUrls: ['./post.page.scss'],
})
export class PostPage implements OnInit {

    public global = {};
    @ViewChild(IonContent) content: IonContent;
    constructor(
        protected globalProvider: GlobalProvider,
        public popoverController: PopoverController,
        protected translate: TranslateService
    ) {
        this.global = this.globalProvider['global'];
    }

    ngOnInit() {
    }

    onChangeLang(lang) {
        this.global['lang'] = lang;
        window.localStorage.setItem('lang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: AuthMenuComponent,
            event: ev,
            translucent: true
        });
        return await popover.present();
    }

}
