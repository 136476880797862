import {Injectable} from '@angular/core';
import {ResourceParams} from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
    pathPrefix: '/noteFolder'
})
export class NoteFolderResource extends BaseResources {

}
