<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Not Permission</ion-title>
    <!--Button Close -->
    <ion-buttons slot="end">
      <ion-button  (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <!-- Generate content show not permission for view de section -->
  <ion-list>
    <ion-item>
      <ion-icon name="alert" item-start></ion-icon>
      <h2>Not Permission</h2>
    </ion-item>
    <ion-item>
      <p>Sorry, you don't have permission to view this section.</p>
    </ion-item>
  </ion-list>
</ion-content>
