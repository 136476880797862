import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ModalController,
  NavController,
  Platform,
  PopoverController
} from '@ionic/angular';
import { GlobalProvider, UserData } from '@xplat-cgc/ionic/features/providers';
import { PostWeekComponent } from '../../post/post-week/post-week.component';
import { AuthMeComponent } from '../auth-me/auth-me.component';
import { NotificationListComponent } from '../../notification/notification-list/notification-list.component';
import { PostListComponent } from '../../post/post-list/post-list.component';

@Component({
  selector: 'app-auth-menu',
  templateUrl: './auth-menu.component.html',
  styleUrls: ['./auth-menu.component.scss']
})
export class AuthMenuComponent implements OnInit {
  public global = {};
  constructor(
    private popoverController: PopoverController,
    protected userData: UserData,
    protected router: Router,
    public globalProvider: GlobalProvider,
    protected navController: NavController,
    public modalController: ModalController,
    public platform: Platform
  ) {
    this.global = this.globalProvider.global;
  }

  ngOnInit() {}

  onClose(data) {
    this.popoverController.dismiss(data);
  }

  onOpen(url) {
    if (this.global['mobile']) {
      this.navController.navigateRoot(url);
      return true;
    }

    if (url === '/post/week') {
      this.presentModal(PostWeekComponent);
    } else if (url === '/auth/me') {
      this.presentModal(AuthMeComponent);
    } else if (url === '/notification/list') {
      this.presentModal(NotificationListComponent);
    } else if (url === '/note/list') {
      this.presentModal(PostListComponent);
    }
  }

  onLogout() {
    this.popoverController.dismiss();
    this.userData.logout().then(() => {
      this.navController.navigateRoot('/auth/login');
    });
  }

  async presentModal(component) {
    this.popoverController.dismiss();
    setTimeout(async () => {
      const modal = await this.modalController.create({
        component: component,
        backdropDismiss: false
      });
      modal.present();
      modal.onDidDismiss().then(() => {
        this.globalProvider.load().then(() => {
          this.onClose(this.globalProvider.get('user'));
        });
      });
    });
  }
}
