import { PlanComponent } from './../../ui/components/plan/plan.component';
import { Storage } from '@ionic/storage';
import { UserData } from '@xplat-cgc/ionic/features/providers/user-data';
import { ModalController, NavController, Platform } from '@ionic/angular';
// import { NotificationListComponent } from '@xplat-cgc/ionic/features/ui/pages/notification/notification-list/notification-list.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BaseResources } from '../resources/base-resources';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import { File } from '@ionic-native/file/ngx';
import { PermissionModalPage } from '@xplat-cgc/ionic/features/ui/pages/permission-modal/permission-modal.page'

@Injectable()
export class GlobalService {
  selects = [];
  public baseResources;
  public lang = {};
  public timezone = 'America/Kentucky/Louisville';

  constructor(
    baseResources: BaseResources,
    protected globalProvider: GlobalProvider,
    protected translate: TranslateService,
    protected modalController: ModalController,
    private _navController: NavController,
    protected userData: UserData,
    protected file: File,
    public storage: Storage,
    protected platform: Platform
  ) {
    this.baseResources = baseResources;
    this.getLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(window.localStorage.getItem('lang'));
      this.globalProvider.global['lang'] = window.localStorage.getItem('lang');
      this.getLang();
    });
  }

  async changePlan() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: PlanComponent,
      backdropDismiss: false,
      componentProps: {
        user: this.globalProvider.global['user'],
        close: true,
        instanceModalParent: this.modalController
      }
    });
    modal.present();
  }

  getLang() {
    this.translate
      .get([
        'Year(s)',
        'Month(s)',
        'Day(s)',
        'Hour(s)',
        'Minute(s)',
        'Second(s)',
        'Ago'
      ])
      .subscribe((res: string) => {
        this.lang = res;
      });
  }

  getImageLogin() {
    if (
      this.globalProvider['global']['config'] &&
      this.globalProvider['global']['config']['images'] &&
      this.globalProvider['global']['config']['images']['login']
    ) {
      return (
        'url(' +
        environment.resources +
        this.globalProvider['global']['config']['images']['login'] +
        ')'
      );
    }
    return 'url(assets/img/carrousel-1.jpg)';
  }

  checkLabelAgo(text) {
    if (this.globalProvider.global['lang'] == 'es') {
      return this.lang['Ago'] + ' ' + text;
    } else {
      return text + ' ' + this.lang['Ago'];
    }
  }

  ago(timestamp) {
    const date = moment(timestamp);
    const ago = {};
    ago['years'] = moment()
      .tz(this.timezone)
      .diff(date, 'years');
    if (ago['years']) {
      return this.checkLabelAgo(ago['years'] + ' ' + this.lang['Year(s)']);
    }
    ago['months'] = moment()
      .tz(this.timezone)
      .diff(date, 'months');
    if (ago['months']) {
      return this.checkLabelAgo(ago['months'] + ' ' + this.lang['Month(s)']);
    }
    ago['days'] = moment()
      .tz(this.timezone)
      .diff(date, 'days');
    if (ago['days']) {
      return this.checkLabelAgo(ago['days'] + ' ' + this.lang['Day(s)']);
    }
    ago['hours'] = moment()
      .tz(this.timezone)
      .diff(date, 'hours');
    if (ago['hours']) {
      return this.checkLabelAgo(ago['hours'] + ' ' + this.lang['Hour(s)']);
    }

    ago['minutes'] = moment()
      .tz(this.timezone)
      .diff(date, 'minutes');
    if (ago['minutes']) {
      return this.checkLabelAgo(ago['minutes'] + ' ' + this.lang['Minute(s)']);
    }

    ago['seconds'] = moment()
      .tz(this.timezone)
      .diff(date, 'seconds');
    if (ago['seconds']) {
      return this.checkLabelAgo(ago['seconds'] + ' ' + this.lang['Second(s)']);
    }
  }

  fillBatch(params) {
    return new Promise((resolve, reject) => {
      this.baseResources.selects(_.assign({}, params)).then(
        response => {
          resolve(response);
        },
        () => {
          reject([]);
        }
      );
    });
  }

  async presentModalNotification() {
    /*const modal = await this.modalController.create({
          component: NotificationListComponent
        });
        return await modal.present();*/
  }

  public accentsTidy(s) {
    let r = s.toLowerCase();
    r = r.replace(new RegExp(/\s/g), '');
    r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
    r = r.replace(new RegExp(/æ/g), 'ae');
    r = r.replace(new RegExp(/ç/g), 'c');
    r = r.replace(new RegExp(/[èéêë]/g), 'e');
    r = r.replace(new RegExp(/[ìíîï]/g), 'i');
    r = r.replace(new RegExp(/ñ/g), 'n');
    r = r.replace(new RegExp(/[òóôõö]/g), 'o');
    r = r.replace(new RegExp(/œ/g), 'oe');
    r = r.replace(new RegExp(/[ùúûü]/g), 'u');
    r = r.replace(new RegExp(/[ýÿ]/g), 'y');
    r = r.replace(new RegExp(/\W/g), '');
    return r;
  }

  public isPlanFree() {
    if (_.isEmpty(this.globalProvider.global['user'])) {
      return true;
    }
    if (
      this.globalProvider.global['user']['plan_id'] && _.indexOf([1], this.globalProvider.global['user']['plan_id']) >= 0
    ) {
      return true;
    }
    return false;
  }

  async removeData() {

  }

  logout(path = '/auth/login') {
    delete this.globalProvider.global['user'];
    delete this.globalProvider.global['token'];
    this.globalProvider.global['visors'] = [];
    delete window.localStorage['token'];
    window.localStorage.removeItem('token');
    this.globalProvider.global['result'] = {
      bible: {},
      strong: {},
      post: {
        data: {
          data: []
        }
      },
      postWeek: {},
      pdf: {
        data: {
          data: []
        }
      }
    };
    this.userData.logout().then(async () => {
      this._navController.navigateRoot(path);
    });
  }

  onChangeLang(lang) {
    this.globalProvider.global['lang'] = lang;
    window.localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  showModalPermission() {
    this.modalController.create({
      component: PermissionModalPage,
      cssClass: 'modal-permission'
    }).then(modal => {
      modal.present();
    });
  }
}
