import { createReducer, on } from '@ngrx/store'
import * as PlayerActions from '../actions/player.actions'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IPlaylist, ITrack, ITrackDownload, PlayerState } from '../interfaces/player-state.interface'

export function sortByOrder(a: ITrack, b: ITrack): number {
  return a.index - b.index
}

export interface StateQueue extends EntityState<ITrack> {
  selectId?: string | number | null
  loading: boolean,
}


export interface DownloadQueue extends EntityState<ITrackDownload> {
  selectId?: string | number | null
  loading: boolean,
}


export interface StatePlaylist extends EntityState<IPlaylist> {
  selectId?: string | number | null
  loading: boolean,
}

export const adapterQueue: EntityAdapter<ITrack> = createEntityAdapter<ITrack>({
  sortComparer: sortByOrder
})

export const adapterPlaylist: EntityAdapter<IPlaylist> = createEntityAdapter<IPlaylist>({})

export const adapterDownload: EntityAdapter<ITrackDownload> = createEntityAdapter<ITrackDownload>({})

export const initialStateQueue: StateQueue = adapterQueue.getInitialState({} as StateQueue)
export const initialStatePlaylist: StatePlaylist = adapterPlaylist.getInitialState({} as StatePlaylist)
export const initialStateDownload: DownloadQueue = adapterDownload.getInitialState({} as DownloadQueue)

export const initialPlayerState = {
  isLoading: false,
  queue: initialStateQueue,
  playlist: initialStatePlaylist,
  download: initialStateDownload,
  currentTrack: null,
  currentTrackInfo: null,
  isPlaying: false,
  currentPlaylist: null,
  update: null,
}

const _appReducer = createReducer(
  initialPlayerState,
  on(PlayerActions.setPlayerState, (state, { player }) => ({ ...state, ...player })),
  on(PlayerActions.updateAllPlaylists, (state, { update }) => ({ ...state, update })),
  on(PlayerActions.updatePlayerState, (state, { player }) => ({ ...state, ...player })),
  on(PlayerActions.clearPlayerCurrentTrackInfo, (state) => {
    return {
      ...state,
      currentTrackInfo: null
    }
  }),
  on(PlayerActions.setPlayerCurrentTrackInfo, (state, { track }) => {
    return {
      ...state,
      currentTrackInfo: track
    }
  }),
  on(PlayerActions.setCurrentPlaylist, (state, { playlist }) => {
    return {
      ...state,
      currentPlaylist: {
        ...state.currentPlaylist,
        ...playlist
      }
    }
  }),
  on(PlayerActions.updatePlayerCurrentTrackInfo, (state, { track }) => {
    console.log(state.currentTrackInfo)
    return {
      ...state,
      currentTrackInfo: {
        ...state.currentTrackInfo,
        ...track
      }
    }
  }),
  on(PlayerActions.setPlayerCurrentTrack, (state, { track }) => {
    return {
      ...state,
      currentTrack: track
    }
  }),
  on(PlayerActions.mapQueueTrack, (state, { entityMap }) => {
    return {
      ...state,
      queue: adapterQueue.mapOne(entityMap, state.queue)
    }
  }),
  on(PlayerActions.updateQueueTrack, (state, { update }) => {
    return {
      ...state,
      queue: adapterQueue.updateOne(update, state.queue)
    }
  }),
  on(PlayerActions.setQueueTrack, (state, { track }) => {
    return {
      ...state,
      queue: adapterQueue.setOne(track, state.queue)
    }
  }),
  on(PlayerActions.addQueueTrack, (state, { track }) => {
    return {
      ...state,
      queue: adapterQueue.addOne(track, state.queue)
    }
  }),
  on(PlayerActions.mapQueueTracks, (state, { entityMap }) => {
    return {
      ...state,
      queue: adapterQueue.map(entityMap, state.queue)
    }
  }),
  on(PlayerActions.setQueue, (state, { queue }) => {
    return {
      ...state,
      queue: adapterQueue.setAll(queue, state.queue)
    }
  }),
  on(PlayerActions.clearQueueTracks, state => {
    return {
      ...state,
      queue: adapterQueue.removeAll({ ...state.queue, selectTrackId: null })
    }
  }),
  on(PlayerActions.deleteQueueTrack, (state, { id }) => {
    return {
      ...state,
      queue: adapterQueue.removeOne(id + '', state.queue)
    }
  }),


  on(PlayerActions.setPlaylists, (state, { playlists }) => {
    return {
      ...state,
      playlist: adapterPlaylist.setAll(playlists, state.playlist)
    }
  }),
  on(PlayerActions.addPlaylist, (state, { playlist }) => {
    return {
      ...state,
      playlist: adapterPlaylist.addOne(playlist, state.playlist)
    }
  }),

  on(PlayerActions.updatePlaylist, (state, { update }) => {
    return {
      ...state,
      playlist: adapterPlaylist.updateOne(update, state.playlist)
    }
  }),
  on(PlayerActions.deletePlaylist, (state, { id }) => {
    return {
      ...state,
      playlist: adapterPlaylist.removeOne(id + '', state.playlist)
    }
  }),
  on(PlayerActions.mapPlaylists, (state, { entityMap }) => {
    return {
      ...state,
      playlist: adapterPlaylist.map(entityMap, state.playlist)
    }
  }),
  on(PlayerActions.clearPlaylists, state => {
    return {
      ...state,
      playlist: adapterPlaylist.removeAll({ ...state.playlist, selectTrackId: null })
    }
  }),

  on(PlayerActions.setPlayerDownloads, (state, { downloads }) => {
    return {
      ...state,
      download: adapterDownload.setAll(downloads, state.download)
    }
  }),
  on(PlayerActions.addPlayerDownload, (state, { download }) => {
    return {
      ...state,
      download: adapterDownload.addOne(download, state.download)
    }
  }),

  on(PlayerActions.updatePlayerDownload, (state, { update }) => {
    return {
      ...state,
      download: adapterDownload.updateOne(update, state.download)
    }
  }),
  on(PlayerActions.deletePlayerDownload, (state, { id }) => {
    return {
      ...state,
      download: adapterDownload.removeOne(id + '', state.download)
    }
  }),
  on(PlayerActions.mapPlayerDownloads, (state, { entityMap }) => {
    return {
      ...state,
      download: adapterDownload.map(entityMap, state.download)
    }
  }),
  on(PlayerActions.clearPlayerDownloads, state => {
    return {
      ...state,
      download: adapterDownload.removeAll({ ...state.download, selectTrackId: null })
    }
  })
)

export const playerReducer = (state: any, action: any) => _appReducer(state, action)

export const {
  selectAll: selectAllQueue
} = adapterQueue.getSelectors((state: PlayerState) => state?.queue)

export const {
  selectAll: selectAllDownloads
} = adapterDownload.getSelectors((state: PlayerState) => state?.download)

export const {
  selectAll: selectAllPlaylists
} = adapterPlaylist.getSelectors((state: PlayerState) => state?.playlist)
