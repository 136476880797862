<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()">
        <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <style type="text/css">
    ol.lst-kix_list_1-3 {
      list-style-type: none;
    }
    ol.lst-kix_list_1-4 {
      list-style-type: none;
    }
    .lst-kix_list_2-6 > li:before {
      content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
    }
    .lst-kix_list_2-7 > li:before {
      content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
    }
    .lst-kix_list_2-7 > li {
      counter-increment: lst-ctn-kix_list_2-7;
    }
    ol.lst-kix_list_1-5 {
      list-style-type: none;
    }
    ol.lst-kix_list_1-6 {
      list-style-type: none;
    }
    .lst-kix_list_2-1 > li {
      counter-increment: lst-ctn-kix_list_2-1;
    }
    ol.lst-kix_list_1-0 {
      list-style-type: none;
    }
    .lst-kix_list_2-4 > li:before {
      content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
    }
    .lst-kix_list_2-5 > li:before {
      content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
    }
    .lst-kix_list_2-8 > li:before {
      content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
    }
    ol.lst-kix_list_1-1 {
      list-style-type: none;
    }
    ol.lst-kix_list_1-2 {
      list-style-type: none;
    }
    .lst-kix_list_1-1 > li {
      counter-increment: lst-ctn-kix_list_1-1;
    }
    ol.lst-kix_list_2-6.start {
      counter-reset: lst-ctn-kix_list_2-6 0;
    }
    ol.lst-kix_list_1-8.start {
      counter-reset: lst-ctn-kix_list_1-8 0;
    }
    ol.lst-kix_list_2-3.start {
      counter-reset: lst-ctn-kix_list_2-3 0;
    }
    ol.lst-kix_list_1-5.start {
      counter-reset: lst-ctn-kix_list_1-5 0;
    }
    ol.lst-kix_list_1-7 {
      list-style-type: none;
    }
    .lst-kix_list_1-7 > li {
      counter-increment: lst-ctn-kix_list_1-7;
    }
    ol.lst-kix_list_1-8 {
      list-style-type: none;
    }
    ol.lst-kix_list_2-5.start {
      counter-reset: lst-ctn-kix_list_2-5 0;
    }
    .lst-kix_list_2-0 > li {
      counter-increment: lst-ctn-kix_list_2-0;
    }
    .lst-kix_list_2-3 > li {
      counter-increment: lst-ctn-kix_list_2-3;
    }
    .lst-kix_list_2-6 > li {
      counter-increment: lst-ctn-kix_list_2-6;
    }
    ol.lst-kix_list_1-7.start {
      counter-reset: lst-ctn-kix_list_1-7 0;
    }
    .lst-kix_list_1-2 > li {
      counter-increment: lst-ctn-kix_list_1-2;
    }
    ol.lst-kix_list_2-2.start {
      counter-reset: lst-ctn-kix_list_2-2 0;
    }
    .lst-kix_list_1-5 > li {
      counter-increment: lst-ctn-kix_list_1-5;
    }
    .lst-kix_list_1-8 > li {
      counter-increment: lst-ctn-kix_list_1-8;
    }
    ol.lst-kix_list_1-4.start {
      counter-reset: lst-ctn-kix_list_1-4 0;
    }
    ol.lst-kix_list_1-1.start {
      counter-reset: lst-ctn-kix_list_1-1 0;
    }
    ol.lst-kix_list_2-2 {
      list-style-type: none;
    }
    ol.lst-kix_list_2-3 {
      list-style-type: none;
    }
    ol.lst-kix_list_2-4 {
      list-style-type: none;
    }
    ol.lst-kix_list_2-5 {
      list-style-type: none;
    }
    .lst-kix_list_1-4 > li {
      counter-increment: lst-ctn-kix_list_1-4;
    }
    ol.lst-kix_list_2-0 {
      list-style-type: none;
    }
    .lst-kix_list_2-4 > li {
      counter-increment: lst-ctn-kix_list_2-4;
    }
    ol.lst-kix_list_1-6.start {
      counter-reset: lst-ctn-kix_list_1-6 0;
    }
    ol.lst-kix_list_2-1 {
      list-style-type: none;
    }
    ol.lst-kix_list_1-3.start {
      counter-reset: lst-ctn-kix_list_1-3 0;
    }
    ol.lst-kix_list_2-8.start {
      counter-reset: lst-ctn-kix_list_2-8 0;
    }
    ol.lst-kix_list_1-2.start {
      counter-reset: lst-ctn-kix_list_1-2 0;
    }
    .lst-kix_list_1-0 > li:before {
      content: '(' counter(lst-ctn-kix_list_1-0, lower-latin) ') ';
    }
    ol.lst-kix_list_2-6 {
      list-style-type: none;
    }
    .lst-kix_list_1-1 > li:before {
      content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
    }
    .lst-kix_list_1-2 > li:before {
      content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
    }
    ol.lst-kix_list_2-0.start {
      counter-reset: lst-ctn-kix_list_2-0 0;
    }
    ol.lst-kix_list_2-7 {
      list-style-type: none;
    }
    ol.lst-kix_list_2-8 {
      list-style-type: none;
    }
    .lst-kix_list_1-3 > li:before {
      content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
    }
    .lst-kix_list_1-4 > li:before {
      content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
    }
    ol.lst-kix_list_1-0.start {
      counter-reset: lst-ctn-kix_list_1-0 0;
    }
    .lst-kix_list_1-0 > li {
      counter-increment: lst-ctn-kix_list_1-0;
    }
    .lst-kix_list_1-6 > li {
      counter-increment: lst-ctn-kix_list_1-6;
    }
    .lst-kix_list_1-7 > li:before {
      content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
    }
    ol.lst-kix_list_2-7.start {
      counter-reset: lst-ctn-kix_list_2-7 0;
    }
    .lst-kix_list_1-3 > li {
      counter-increment: lst-ctn-kix_list_1-3;
    }
    .lst-kix_list_1-5 > li:before {
      content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
    }
    .lst-kix_list_1-6 > li:before {
      content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
    }
    .lst-kix_list_2-0 > li:before {
      content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
    }
    .lst-kix_list_2-1 > li:before {
      content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
    }
    ol.lst-kix_list_2-1.start {
      counter-reset: lst-ctn-kix_list_2-1 0;
    }
    .lst-kix_list_2-5 > li {
      counter-increment: lst-ctn-kix_list_2-5;
    }
    .lst-kix_list_2-8 > li {
      counter-increment: lst-ctn-kix_list_2-8;
    }
    .lst-kix_list_1-8 > li:before {
      content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
    }
    .lst-kix_list_2-2 > li:before {
      content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
    }
    .lst-kix_list_2-3 > li:before {
      content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
    }
    .lst-kix_list_2-2 > li {
      counter-increment: lst-ctn-kix_list_2-2;
    }
    ol.lst-kix_list_2-4.start {
      counter-reset: lst-ctn-kix_list_2-4 0;
    }
    ol {
      margin: 0;
      padding: 0;
    }
    table td,
    table th {
      padding: 0;
    }
    .c5 {
      background-color: #ffffff;
      color: #333333;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 14pt;
      font-family: 'Calibri';
      font-style: normal;
    }
    .c14 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 12pt;
    }
    .c4 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: 'Calibri';
      font-style: normal;
    }
    .c0 {
      background-color: #ffffff;
      padding-top: 0pt;
      padding-bottom: 7.5pt;
      line-height: 1;
      orphans: 2;
      widows: 2;
      text-align: justify;
    }
    .c2 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1;
      orphans: 2;
      widows: 2;
      text-align: center;
      height: 12pt;
    }
    .c8 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1;
      orphans: 2;
      widows: 2;
      text-align: justify;
    }
    .c12 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1;
      orphans: 2;
      widows: 2;
      text-align: center;
    }
    .c22 {
      -webkit-text-decoration-skip: none;
      color: #0000ff;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      font-size: 11pt;
    }
    .c7 {
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-family: 'Calibri';
      font-style: normal;
    }
    .c13 {
      text-decoration: none;
      vertical-align: baseline;
      font-family: 'Calibri';
      font-style: normal;
    }
    .c18 {
      text-decoration: none;
      vertical-align: baseline;
      font-family: 'Arial';
      font-style: normal;
    }
    .c23 {
      text-decoration-skip-ink: none;
      -webkit-text-decoration-skip: none;
      text-decoration: underline;
    }
    .c10 {
      /*max-width: 441.9pt;
    padding: 70.8pt 85pt 70.8pt 85pt;*/
    }
    .c19 {
      margin-left: 36pt;
      padding-left: 0pt;
    }
    .c16 {
      color: inherit;
      text-decoration: inherit;
    }
    .c9 {
      color: #000000;
      font-size: 12pt;
    }
    .c17 {
      color: #333333;
      font-size: 10pt;
    }
    .c1 {
      color: #333333;
      font-size: 11pt;
    }
    .c11 {
      padding: 0;
      margin: 0;
    }
    .c6 {
      background-color: #ffffff;
    }
    .c21 {
      margin-left: 36pt;
    }
    .c20 {
      text-indent: -36pt;
    }
    .c3 {
      font-weight: 700;
    }
    .c15 {
      height: 12pt;
    }
    .title {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 36pt;
      padding-bottom: 6pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    .subtitle {
      padding-top: 18pt;
      color: #666666;
      font-size: 24pt;
      padding-bottom: 4pt;
      font-family: 'Georgia';
      line-height: 1;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    li {
      color: #000000;
      font-size: 12pt;
      font-family: 'Calibri';
    }
    p {
      margin: 0;
      color: #000000;
      font-size: 12pt;
      font-family: 'Calibri';
    }
    h1 {
      padding-top: 24pt;
      color: #000000;
      font-weight: 700;
      font-size: 24pt;
      padding-bottom: 6pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h2 {
      padding-top: 18pt;
      color: #000000;
      font-weight: 700;
      font-size: 18pt;
      padding-bottom: 4pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h3 {
      padding-top: 14pt;
      color: #000000;
      font-weight: 700;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h4 {
      padding-top: 12pt;
      color: #000000;
      font-weight: 700;
      font-size: 12pt;
      padding-bottom: 2pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h5 {
      padding-top: 11pt;
      color: #000000;
      font-weight: 700;
      font-size: 11pt;
      padding-bottom: 2pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h6 {
      padding-top: 10pt;
      color: #000000;
      font-weight: 700;
      font-size: 10pt;
      padding-bottom: 2pt;
      font-family: 'Calibri';
      line-height: 1;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
  </style>
  <div class="c6 c10">
    <p class="c2"><span class="c13 c6 c3 c17"></span></p>
    <p class="c12">
      <span class="c17 c6 c3 c18"
        >Christ Gospel Churches International Inc.</span
      >
    </p>
    <p class="c12"><span class="c3 c5">TERMS AND CONDITIONS OF USE</span></p>
    <p class="c8 c15"><span class="c13 c1 c6 c3"></span></p>
    <p class="c8">
      <span class="c1 c6"
        >These Terms and Conditions are applicable to your access to, and use
        of, the CGCII Website at &nbsp;</span
      ><span class="c6 c22"
        ><a
          class="c16"
          href="https://www.google.com/url?q=http://christgospel.org/&amp;sa=D&amp;ust=1573010611005000"
          >http://christgospel.org/</a
        ></span
      ><span class="c7 c1 c6"
        >&nbsp;or the CGCII mobile application (&ldquo;the App&rdquo;) and the
        software systems that underlie the same.
      </span>
    </p>
    <p class="c8 c15"><span class="c7 c1 c6"></span></p>
    <p class="c8"><span class="c13 c1 c6 c3">Acceptance </span></p>
    <p class="c8">
      <span class="c7 c1 c6"
        >By your use or access to the Website or the App, you accept and agree
        to be bound by these Terms and Conditions, and by the CGCII Privacy
        Policy which is incorporated therein. Please read these Terms and
        Conditions, and the Privacy Policy carefully.
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /><br />IF YOU DO
        NOT AGREE WITH THESE TERMS AND CONDITIONS OF USE, THEN YOU ARE
        PROHIBITED FROM USING THE WEBSITE OR THE APP, AND YOU MUST DISCONSITUE
        SUCH USE IMMEDIATELY.
      </span>
    </p>
    <p class="c8 c15"><span class="c7 c1 c6"></span></p>
    <p class="c8">
      <span class="c1 c6"
        >It is an explicit condition of your access or use of the Website or the
        App that you not do so for unlawful purposes or in violation of these
        Terms and Conditions. If you fail to adhere to this condition, we </span
      ><span class="c1"
        >reserve the right to take appropriate legal action against you,
        including without limitation pursuing civil, criminal or injunctive
        redress.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br
      /></span>
    </p>
    <p class="c0">
      <span class="c1 c3">Definitions<br /></span
      ><span class="c1"
        >&ldquo;The Website&rdquo; means the web-based software, System,
        software, tools, functions and services offered through the </span
      ><span class="c1 c6"
        >http://christgospel.org/ website.
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /><br />&ldquo;The
        App&rdquo; means the </span
      ><span class="c1"
        >web-based software, System, software, tools, functions and services
        offered through the </span
      ><span class="c7 c1 c6"
        >Christ Gospel Churches Int&rsquo;l mobile application.</span
      >
    </p>
    <p class="c0">
      <span class="c1 c6"
        >&ldquo;System&rdquo; means the source code, software, and data bases
        that underlie and facilitate the Website and the App.
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /><br /></span
      ><span class="c7 c1"
        >&ldquo;You&rdquo; or &ldquo;your&rdquo; means any person who uses the
        Website, or who registers with the App, the organization to which your
        account with the Website or the App may be &nbsp;associated, and any
        person who uses your account.</span
      >
    </p>
    <p class="c0">
      <span class="c7 c1"
        >&ldquo;Content&rdquo; means all information, data, and material in
        whatever form including text, graphics, audio, video, trademarks and
        service marks. It includes any content that may be able to
        copyrighted.</span
      >
    </p>
    <p class="c0">
      <span class="c7 c1"
        >&ldquo;CGCII&rdquo;, &ldquo;we&rdquo; and &ldquo;us&rdquo; mean Christ
        Gospel Churches International Inc. based in Jeffersonville, Indiana,
        USA. &nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Changes to these terms and
        conditions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c7 c1"
        >We reserve the right to revise these Terms and Conditions of Use at any
        time, in our sole discretion, and without any notice or advance warning.
        It is your responsibility to review the Terms and Conditions of Use on
        an ongoing basis. Any changes will be effective when we post them. Your
        use of the Website or App following the posting of changes shall mean
        that you accept and agree to be bound by the changes, even if you are
        not aware of them.
      </span>
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Changes to the Website or the
        App&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c7 c1"
        >We reserve the right, in our sole discretion, to remove or change or
        make unavailable any of the Content or features of the Website or the
        App at any time and without notice or warning. We will not be liable to
        you or to any third party for any such change or removal or
        unavailability. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3">Termination <br /></span
      ><span class="c7 c1"
        >We reserve the right, in our sole discretion and without notice or
        warning, to deny access or use of the Website or the App to any person
        for any reason or for no reason. Such denial may include blocking
        certain IP addresses, or blocking users in any country or region. We
        further reserve the right, in our sole discretion and without notice or
        warning, to delete your account and any content or information provided
        by you have provided. &nbsp;
      </span>
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Privacy Policy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c1"
        >All data about yourself that you send to us, or that we collect or
        receive about you through the Website, the App, System, or from others,
        will be subject to our Privacy Policy. Please review our Privacy Policy
        by following this </span
      ><span class="c1 c23">link</span
      ><span class="c7 c1"
        >. By using the Website or the App you consent to all actions taken by
        us that is consistent with our Privacy Policy and these Terms and
        Conditions. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3">Legal <br /></span
      ><span class="c1">All Content contained on the CGC Website and</span
      ><span class="c1 c6">&nbsp;the App </span
      ><span class="c7 c1"
        >is provided without any warranty of any kind. You may only view, use,
        or share the material on the CGC Website or the App at your own
        risk.</span
      >
    </p>
    <p class="c0">
      <span class="c1">The Content contained on the CGC Website and</span
      ><span class="c1 c6">&nbsp;the App</span
      ><span class="c1"
        >&nbsp;is not intended for distribution in any country or jurisdiction
        where registration is required or where distribution thereof is illegal.
        Consequently, persons who access the Website or</span
      ><span class="c1 c6"
        >&nbsp;the App from such countries or jurisdictions, do so on their own
        initiative and are solely responsible for compliance with applicable
        local laws. &nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1">The contents of the Website </span
      ><span class="c1 c6">or the App or System</span
      ><span class="c7 c1"
        >&nbsp;do not constitute advice and should not be relied upon in making,
        or refraining from making, any decision.</span
      >
    </p>
    <p class="c0">
      <span class="c7 c1"
        >We further reserve the right, in our sole discretion, to take
        appropriate legal action against you, including without limitation
        pursuing civil, criminal or injunctive redress.</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Intellectual property&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br /></span
      ><span class="c1">The Website and </span><span class="c1 c6">the </span
      ><span class="c1"
        >App are the proprietary property of CGCII. Unless otherwise indicated,
        the Content and System, as well as trademarks, service marks, logos, and
        all other intellectual property in the Website, App</span
      ><span class="c1 c6">&nbsp;or System</span
      ><span class="c7 c1"
        >&nbsp;are owned by or licensed to CGCII or are otherwise used by CGC as
        permitted by law. The same are protected by copyright and trademark laws
        and unfair competition laws of the USA, foreign jurisdictions and
        International conventions.
      </span>
    </p>
    <p class="c0">
      <span class="c1">In accessing the Website, </span
      ><span class="c1 c6">the </span><span class="c1">App </span
      ><span class="c1 c6">or System</span
      ><span class="c1"
        >&nbsp;you agree that you will do so solely for your personal,
        non-commercial use. Subject to these Terms and Conditions of Use,
        private users of the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App are hereby granted a limited license to download, copy and/or print
        Content on the Website and </span
      ><span class="c1 c6">the </span
      ><span class="c7 c1"
        >App for personal, non-commercial home use only.</span
      >
    </p>
    <p class="c0">
      <span class="c7 c1"
        >Except as expressly provided in these Terms and Conditions of Use, no
        part of &nbsp;the Content or System or trademarks, service marks, logos,
        or other intellectual property on the Website or the App may be copied,
        republished, uploaded, publicly displayed, distributed, sold, licensed,
        or otherwise exploited for commercial purposes without prior, express
        written permission by CGCII.
      </span>
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Links to and from other
        websites&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c1">Throughout this Website, </span
      ><span class="c1 c6">application or System</span
      ><span class="c1"
        >&nbsp;you may find links to third party websites. The provision of a
        link to such a website does not mean that CGCII endorses such websites.
        If you visit any website via a link on this Website or </span
      ><span class="c1 c6">the </span
      ><span class="c7 c1">App, you do so entirely at your own risk.</span>
    </p>
    <p class="c0">
      <span class="c1">Any party wishing to link to the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c7 c1"
        >App may only do so subject to the following conditions:</span
      >
    </p>
    <ol class="c11 lst-kix_list_1-0 start" start="1">
      <li class="c8 c19 c6">
        <span class="c7 c1"
          >You may not imply that CGCII endorses the services or products of
          another party without prior, written agreement by CGCII to do
          so;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br
        /></span>
      </li>
      <li class="c8 c6 c19">
        <span class="c7 c1"
          >You may not misrepresent your relationship with this Website or the
          App; and&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br
        /></span>
      </li>
      <li class="c8 c19 c6">
        <span class="c7 c1"
          >The website from which you link to the Website or </span
        ><span class="c7 c1 c6">the </span
        ><span class="c7 c1"
          >App may not contain offensive or controversial content, or content
          that infringes on any intellectual property rights or other rights of
          third parties. The question of whether content is offensive or
          controversial, or infringes on any intellectual property rights or
          other rights of third parties, shall be in the sole discretion of
          CGCII. &nbsp;</span
        >
      </li>
    </ol>
    <p class="c8 c6 c20 c15 c21"><span class="c7 c1"></span></p>
    <p class="c0 c20">
      You hereby agree that if you linked the Website or the App in breach of
      the Terms and Conditions of Use, you shall be liable to indemnify CGCII
      for any loss or damage suffered to the Website or the App or System
      &nbsp;as a result of such
      linkage.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Disclaimers and limitation of
        liability&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c1"
        >The Content, System, trademarks, service marks, logos, and all other
        intellectual property are made available on an AS IS and AS AVAILABLE
        basis, and are made available without any representation or endorsement.
        Your use of the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c7 c1"
        >App, access thereto, or use of the Content, services and tools offered
        thereon, will be entirely at your own risk.
      </span>
    </p>
    <p class="c0">
      <span class="c1"
        >To the fullest extent permitted by law, we disclaim all warranties of
        any kind whether express or implied, in connection with the Website, </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App or System, including without limitation the implied warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
        We make no warranty or representation about the accuracy or completeness
        of the contents of the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App, or any websites linked to them. We will assume no liability or
        responsibility for errors or inaccuracies of the contents of the Website
        or </span
      ><span class="c1 c6">the </span
      ><span class="c7 c1">App or of linked websites. &nbsp; </span>
    </p>
    <p class="c0">
      <span class="c1"
        >To the extent permitted by law, CGCII and its officers, directors,
        employees and agents make no warranty or representation about the
        absence of, and will not accept any liability or responsibility for: (1)
        dysfunctionality of the Website, </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App or System; (2) errors or inaccuracies of the Content; (3)
        interruptions or cessation of transmission; (4) unauthorized access to
        or use of our servers; and (5) bugs, viruses, trojan horses, or the like
        that may be transmitted to or through the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App or System; (6) any loss of or damage, including loss or damage to
        business, opportunity, data, or profits, property damage, economic loss,
        personal injury, emotional injury or distress, consequential damage, or
        any other kind of damages suffered as a result of your access or use of
        the Website or </span
      ><span class="c1 c6">the </span
      ><span class="c1"
        >App, or as a result of any content posted or transmitted or made
        available thereon.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3">Indemnity<br /></span
      ><span class="c1"
        >You agree to indemnify and hold harmless CGCII and its employees,
        officers and agents from and against all liabilities, legal fees,
        damages, losses, costs and other expenses in relation to any claims or
        actions brought against any of them arising out of any breach by you of
        these Terms and Conditions of Use, or any other liability arising out of
        your use of this Website, </span
      ><span class="c1 c6">the </span><span class="c1">App </span
      ><span class="c1 c6">or System in breach of </span
      ><span class="c1"
        >the Terms and Conditions of
        Use.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3">Severance<br /></span
      ><span class="c7 c1"
        >If any of these Terms and Conditions should be found by a court of
        competent jurisdiction to be invalid, illegal or unenforceable, then
        such Term or Condition shall be severed and the remaining Terms and
        Conditions shall survive and remain in full force and effect and
        continue to be binding and
        enforceable.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3">Waiver<br /></span
      ><span class="c1"
        >If you breach any of these Terms and Conditions and CGCII takes no
        action as a result of it, then it shall not be mean that CGCII has
        thereby waived its rights or remedies to take action. In addition
        thereto, CGCII would still be entitled to assert its rights and remedies
        in any other instance where you breached these Terms and
        Conditions.</span
      >
    </p>
    <p class="c0">
      <span class="c1 c3"
        >Governing law and
        courts&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c7 c1"
        >These Terms and Conditions, including the Privacy Policy, shall be
        governed by and construed in accordance with the laws of the State of
        Indiana, USA without regard to its conflict of laws principles.
      </span>
    </p>
    <p class="c0">
      <span class="c7 c1"
        >You hereby agree that the state courts of Clark County, Indiana shall
        have exclusive jurisdiction to adjudicate any dispute that may arise
        from these Terms and Conditions between you and CGC or its employees,
        officers and agents, and you irrevocably submit to the jurisdiction of
        the same courts.</span
      >
    </p>
    <p class="c0" id="h.gjdgxs">
      <span class="c1 c3"
        >Contact details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /></span
      ><span class="c7 c1"
        >Our postal address: P.O. Box 786, Jeffersonville, IN 47131, USA</span
      >
    </p>
    <p class="c0">
      <span class="c7 c1"
        >Physical address: 2614 E 10th Street, Jeffersonville, IN 47130.
      </span>
    </p>
    <p class="c0"><span class="c7 c1">Telephone: +1 (812) 282-8458</span></p>
    <p class="c0">
      <span class="c7 c1"
        >For further information, please visit:
        http://christgospel.org/contact-us-2/</span
      >
    </p>
    <p class="c8 c15"><span class="c4"></span></p>
    <div>
      <p class="c12">
        <span class="c4">Page </span><span class="c4">&nbsp;of </span>
      </p>
      <p class="c14"><span class="c7 c9"></span></p>
    </div>
  </div>
</ion-content>
