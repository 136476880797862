import { Storage } from '@ionic/storage';
import { TermsPage } from '@xplat-cgc/ionic/features/ui/pages/terms/terms.page';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Crop } from '@ionic-native/crop/ngx';
import { FileUploader } from 'ng2-file-upload';
import {
  AlertController,
  ModalController,
  NavController,
  Platform
} from '@ionic/angular';
import {
  CheckoutPaymentResource,
  GlobalProvider,
  GlobalService,
  ToastService,
  UserData
} from '@xplat-cgc/ionic/features/providers';
import { UserResource } from '../user-resource';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import * as moment from 'moment';
import { PlanComponent } from '@xplat-cgc/ionic/features/ui/components/plan/plan.component';
import { TranslateService } from '@ngx-translate/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FamiliaPlanResource } from '@xplat-cgc/ionic/features/providers/resources/familia-plan-resource.service';
import { SubscriptionResource } from '@xplat-cgc/ionic/features/providers/resources/subscription-resource';
import { Camera, CameraResultType } from '@capacitor/camera';
import { RemoveAccountModalPage } from '@xplat-cgc/ionic/features/ui/pages/remove-account-modal/remove-account-modal.page'

@Component({
  selector: 'app-auth-me',
  templateUrl: './auth-me.component.html',
  styleUrls: ['./auth-me.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthMeComponent implements OnInit {
  public formUser;
  public user = null;
  public environment = environment;
  public moment;
  public dataUser = {};
  public churches: any[] = [];
  public countries: any[];
  public fileUrl: any = null;
  public respData: any;
  public isApp = false;
  public updated = false;
  public urlUpload = '';
  public token = '';
  public temp_photo = '';
  public global = {};
  public submitted = false;
  public emailInvited = '';
  public familiarPlan = [];
  public tutor = false;
  public backgroundMusic;
  public uploader: FileUploader = new FileUploader({
    url: 'upload',
    queueLimit: 1,
    authToken: 'Bearer ' + window.localStorage['token']
  });

  public loading = false;

  public version;

  constructor(
    public globalProvider: GlobalProvider,
    public fb: UntypedFormBuilder,
    protected resource: UserResource,
    protected toastService: ToastService,
    protected router: Router,
    private route: ActivatedRoute,
    public userData: UserData,
    protected globalService: GlobalService,
    private crop: Crop,
    public platform: Platform,
    private cdr: ChangeDetectorRef,
    protected checkoutPaymentResource: CheckoutPaymentResource,
    protected navController: NavController,
    protected modalController: ModalController,
    protected translate: TranslateService,
    protected familarPlanResource: FamiliaPlanResource,
    protected alertController: AlertController,
    private appVersion: AppVersion,
    private subscriptionResource: SubscriptionResource,
    private storage: Storage
  ) {
    this.moment = moment;
    this.global = this.globalProvider['global'];
    try {
      this.appVersion
        .getVersionNumber()
        .then(v => {
          this.version = v;
        })
        .catch(() => {
          this.version =
            (!environment.production ? 'DEV - ' : '') + environment.version;
        });
    } catch (e) {
      this.version =
        (!environment.production ? 'DEV - ' : '') + environment.version;
    }
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.formUser = this.fb.group({
      name: [
        '',
        Validators.compose([Validators.minLength(3), Validators.required])
      ],
      last_primary_name: [
        '',
        Validators.compose([Validators.minLength(3), Validators.required])
      ],
      last_second_name: [''],
      cellphone: ['', Validators.compose([Validators.required])],
      birthdate: [
        '',
        Validators.compose([Validators.minLength(8), Validators.required])
      ],
      gender_id: ['', Validators.compose([Validators.required])],
      church_id: ['', Validators.compose([])],
      country_id: ['', Validators.compose([Validators.required])],
      photo: ['']
    });

    //this.init();
  }

  ionViewDidEnter() {
    this.init();
  }

  async onChangePlan() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: PlanComponent,
      backdropDismiss: false,
      componentProps: {
        user: this.user,
        close: true,
        hideId: this.global['user']['plan_id'],
        instanceModalParent: this.modalController
      }
    });
    modal.present();
  }

  public onBackgroundMusicChange() {
    this.storage.set('background_music', this.backgroundMusic);
  }

  async init() {

    this.backgroundMusic = await this.storage.get('background_music');

    this.getUser();
    if (this.global['mobile']) {
      this.isApp = true;
    }

    this.uploader.onBeforeUploadItem = item => {
      item.withCredentials = false;
      item['url'] = this.urlUpload;
    };
    this.uploader.onCompleteItem = (item, response) => {
      const respData = JSON.parse(response);
      this.user['photo'] = respData['data']['photo'];
      this.global['user']['photo'] = respData['data']['photo'];
    };
  }

  async getUser() {
    await this.userData.getUser().then(data => {
      if (_.isEmpty(data)) {
        return false;
      }
      this.user = {
        ...data['user'],
        birthdate: (data['user']['birthdate'] ? (new Date(data['user']['birthdate']))?.toISOString()?.substring(0, 10) : null)
      };
      this.dataUser = this.user;
      this.cdr.detectChanges()
      this.onGetFamiliarPlan();
      this.urlUpload =
        environment.api + 'user/uploadPhoto/' + this.dataUser['id'];
      this.globalService
        .fillBatch({
          country: {
            _sort: 'name'
          },
          church: {
            _sort: 'name',
            country_id: this.dataUser['country_id'] || 'null'
          }
        })
        .then(data => {
          if (data['data']['church'].length > 0) {
            this.churches = [
              ...(this.churches || []),
              ...(data['data']['church'] || [])
            ];
          }
          this.countries = [
            ...(this.countries || []),
            ...(data['data']['country'] || [])
          ]
          this.cdr.detectChanges()
        });
    });
  }

  trackyByFn(index, item){
    return item?.id || index;
  }

  onActivatedSubscription() {
    this.subscriptionResource
      .restore({
        id: this.global['user']['id']
      })
      .then(response => {
        this.toastService.success({
          message: response['message'],
          position: 'top'
        });
        this.global['user']['subscription'] = response['data'];
      })
      .catch(() => {});
  }

  onCancelSubscription() {
    this.subscriptionResource
      .delete({
        id: this.global['user']['id']
      })
      .then(response => {
        this.toastService.success({
          message: response['message'],
          position: 'top'
        });
        this.global['user']['subscription'] = response['data'];
      });
  }

  private areEqual(group: UntypedFormGroup) {
    return group.get('password').value ===
      group.get('password_confirmation').value
      ? null
      : { mismatch: true };
  }

  async onSave() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formUser.invalid) {
      return;
    }

    /*if (!this.user['profile_id']) {
        this.user['profile_id'] = this.dataUser['user']['roles'][0] ? this.dataUser['user']['roles'][0].id : null;
    }*/
    this.resource
      .update(
        _.pick(this.user, [
          'id',
          'name',
          'last_primary_name',
          'last_second_name',
          'cellphone',
          'birthdate',
          'gender_id',
          'church_id',
          'country_id',
          'photo'
        ])
      )
      .then(async response => {
        this.globalProvider.set('user', response['data']);
        //this.user = response['data'];
        this.dataUser['user'] = response['data'];
        this.uploader.uploadAll();
        this.toastService.success({
          message: response['message']
        });
        this.updated = true;
        await this.userData.getUser().then(async data => {
          data['user'] = this.dataUser;
          await this.userData.setUser(data).then(() => this.onClose());
        });
      });
  }

  onUploadPhoto() {
    if (this.isApp) {
      this.cropUploadApp();
    }
  }

  async cropUploadApp() {
    let image = null;
    try {
      image = await Camera.getPhoto({
        quality: 60,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
    } catch (e) {}
    if (!image) {
      return;
    }
    this.crop.crop(image.path, { quality: 100 }).then(
      newImage => {
        //TODO: upload image
       /*  const fileTransfer: FileTransferObject = this.transfer.create();
        const fileName = newImage.substr(newImage.lastIndexOf('/') + 1);
        const uploadOpts: FileUploadOptions = {
          fileKey: 'file',
          fileName: fileName.split('?')[0],
          httpMethod: 'POST',
          chunkedMode: false,
          headers: {
            Authorization: 'Bearer ' + window.localStorage['token']
          }
        };
        fileTransfer.upload(newImage, this.urlUpload, uploadOpts).then(
          data => {
            this.respData = JSON.parse(data.response);
            this.fileUrl = this.respData.fileUrl;
            this.user['photo'] = this.respData['data']['photo'];
            this.global['user']['photo'] = this.respData['data']['photo'];
          },
          err => {
            console.log(err);
          }
        ); */
      },
      error => console.error('Error cropping image', error)
    );
  }

  onClearQueue(uploader) {
    uploader.clearQueue();
  }

  onClearPhoto() {
    this.temp_photo = this.user['photo'];
    this.user['photo'] = null;
  }

  onClose() {
    /*if (!this.updated) {
      this.user['photo'] = this.temp_photo;
    }*/
    this.globalProvider['global']['user'] = this.user;
    if (!this.global['mobile']) {
      this.modalController.dismiss();
    }
  }

  ngAfterViewInit() {
    //this.cdr.detectChanges();
  }

  onChangePaymentMethod() {
    this.modalController.dismiss({
      dismissed: true
    });
    this.navController.navigateRoot('/auth/payment/change');
  }

  ngOnInit() {
    this.user = this.globalProvider.global['user'];
    if (this.user) {
      this.checkoutPaymentResource
        .payment()
        .then(response => {
          this.user['payment'] = response['data'];
        })
        .catch(() => {});
    }
  }

  onDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  onChangeLang(lang) {
    this.global['lang'] = lang;
    window.localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  async onPresentTerms() {
    const modal = await this.modalController.create({
      component: TermsPage
    });
    return await modal.present();
  }

  onChangeCountry() {
    if (!this.user['country_id']) {
      this.globalService
        .fillBatch({
          church: {
            _sort: 'name',
            'country_id-in': 'null'
          }
        })
        .then(response => {
          this.churches = [
            ...response['data']['church']
          ]
        });
      return false;
    }
    this.globalService
      .fillBatch({
        church: {
          _sort: 'name',
          'country_id-in': this.user['country_id'] + ',null'
        }
      })
      .then(response => {
        this.churches = [
          ...(response['data']['church'] || [])
        ]
      });
  }

  onGetFamiliarPlan() {
    if (!this.global['user']) {
      return false;
    }

    this.familarPlanResource
      .get({
        id: this.global['user']['id']
      })
      .then(response => {
        this.familiarPlan = response['data']['familiar_plan'];
        if (response['data']['id'] !== this.global['user']['id']) {
          this.tutor = response['data'];
        }
      })
      .catch(() => {});
  }

  async onInviteFamiliarPlan(email) {
    const translate = await this.translate
      .get([
        'You can only have 2 guests',
        'Confirm',
        'Cancel',
        'Removed correctly',
        'Ok',
        'The user is already registered and has a plan, to send the invitation the user must cancel the plan'
      ])
      .toPromise();
    if (this.familiarPlan.length >= 2) {
      const alert = await this.alertController.create({
        message: translate['You can only have 2 guests'] + '!',
        buttons: [
          {
            text: translate['Ok'],
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel: blah');
            }
          }
        ]
      });

      await alert.present();
      return true;
    }
    this.loading = true;
    this.familarPlanResource
      .store({
        user_id: this.user['id'],
        email: email
      })
      .then(async response => {
        this.familiarPlan = response['data']['familiar_plan'];
        const translate = await this.translate
          .get(['The invitation has been sent', 'Ok'])
          .toPromise();
        this.toastService.success({
          message: translate['The invitation has been sent']
        });
      })
      .catch(async error => {
        if (
          error.status === 422 &&
          error['body'] &&
          error['body']['errors']['userWithPayedPlan']
        ) {
          const alert = await this.alertController.create({
            message:
              translate[
                'The user is already registered and has a plan, to send the invitation the user must cancel the plan'
              ] + '!',
            buttons: [
              {
                text: translate['Ok'],
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  console.log('Confirm Cancel: blah');
                }
              }
            ]
          });
          await alert.present();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onResendInvitation(email) {
    this.loading = true;
    const translate = await this.translate
      .get(['The invitation has been sent'])
      .toPromise();
    this.familarPlanResource
      .resend({
        user_id: this.user['id'],
        email: email
      })
      .then(async response => {
        this.toastService.success({
          message: translate['The invitation has been sent']
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onRemoveFamiliar(item) {
    const translate = await this.translate
      .get([
        'Are you sure to remove your invited?',
        'Confirm',
        'Cancel',
        'Removed correctly'
      ])
      .toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Are you sure to remove your invited?'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: blah => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.loading = true;
            this.familarPlanResource
              .delete({
                id: item['id']
              })
              .then(response => {
                this.familiarPlan = response['data']['familiar_plan'];
                this.toastService.success({
                  message: translate['Removed correctly']
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      ]
    });

    await alert.present();
  }

  onLogout() {
    this.globalService.logout('/load');
  }

  async onRemove() {
    const translate = await this.translate
      .get([
        'Are you sure to remove your account?',
        'Confirm',
        'Cancel',
        'Removed correctly',
        'The code has been sent to your email'
      ])
      .toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Are you sure to remove your account?'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: blah => {
            console.log('Confirm Cancel: blah');
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.loading = true;
            this.resource
              .remove({
                id: this.user['id']
              })
              .then(response => {
                this.toastService.success({
                  message: translate['The code has been sent to your email']
                })
                this.modalController.create({
                  component: RemoveAccountModalPage,
                  cssClass: 'modal-permission'
                }).then(modal => {
                  modal.present();
                })
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      ]
    });

    await alert.present();

  }
}
