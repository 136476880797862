import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {PostResource} from '../post-resource.service';
import {saveAs} from 'file-saver';
import {File} from '@ionic-native/file/ngx';
import {Platform} from '@ionic/angular';

@Component({
    selector: 'app-post-show',
    templateUrl: './post-show.component.html',
    styleUrls: ['./post-show.component.scss'],
})
export class PostShowComponent implements OnInit {

    @Input() post: any = {};

    public printing = false;

    constructor(
        protected modalCtrl: ModalController,
        protected postResource: PostResource,
        private file: File,
        public platform: Platform
    ) {
    }

    onClose() {
        this.modalCtrl.dismiss();
    }

    onPrint() {
        this.printing = true;
        // const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
        this.postResource.print({'id': this.post.id}).then((response) => {
            saveAs(response, this.post.title + '.pdf');
        });
    }

    ngOnInit() {
    }

}
