import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {NgxLoadingModule} from 'ngx-loading';
import {QuillModule} from 'ngx-quill';
import {FileUploadModule} from 'ng2-file-upload';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFile, faFolder, faSearch, faTimes, faTrash, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {SearchVisorComponent} from './search-visor/search-visor.component';
import {SearchBibleComponent} from './search-bible/search-bible.component';
import {SearchStrongComponent} from './search-strong/search-strong.component';
import {SearchPostComponent} from './search-post/search-post.component';
import {SearchMainComponent} from './search-main/search-main.component';
import {SearchViewerComponent} from './search-viewer/search-viewer.component';
import {PipesModule} from '../../../providers/pipes/pipes.module';
import {NgxAudioPlayerModule} from '../../../modules/player/ngx-audio-player.module';
import {PostSharedModule} from '../post';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {SearchMainMobileComponent} from '../search/search-main-mobile';

import { HeaderButtonsModule } from '../../components/header-buttons/header-buttons.module';
import { VisorModule } from '@xplat-cgc/ionic/features/modules/visor/visor.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        NgxLoadingModule,
        QuillModule,
        NgxLoadingModule,
        FileUploadModule,
        PipesModule,
        NgxAudioPlayerModule,
        PostSharedModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        VisorModule,
        HeaderButtonsModule,
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        SearchVisorComponent,
        SearchBibleComponent,
        SearchStrongComponent,
        SearchPostComponent,
        SearchMainComponent,
        SearchMainMobileComponent,
        SearchViewerComponent
    ],
    exports: [
        SearchVisorComponent,
        SearchBibleComponent,
        SearchStrongComponent,
        SearchPostComponent,
        SearchMainComponent,
        SearchMainMobileComponent,
        SearchViewerComponent
    ]
})
export class SearchSharedModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faTimes);
        library.add(faTrash);
        library.add(faFile);
        library.add(faFolder);
        library.add(faUserCircle);
        library.add(faSearch);
    }
}
