<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" *ngIf="global['mobile']">
            <ion-back-button [text]="''" defaultHref="/search"></ion-back-button>
        </ion-buttons>
        <ion-title  class="ion-text-uppercase">{{'Quick Notes (search only)' | translate}}</ion-title>
        <ion-buttons slot="end" *ngIf="!global['mobile']">
            <ion-button (click)="onDismiss()">
                <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-list>
        <ion-list-header>
            <ion-grid>
                <ion-row>
                    <ion-col size="4">
                    </ion-col>
                    <ion-col size="4">
                    </ion-col>
                    <ion-col size="4">
                        <ion-item>
                            <ion-label position="floating">{{'Sort By' | translate}}</ion-label>
                            <ion-select [(ngModel)]="sortType" interface="popover"
                                        (ionChange)="onQuery(folder_up_id)">
                                <ion-select-option value="created_at">{{'Date' | translate}} ASC</ion-select-option>
                                <ion-select-option value="-created_at">{{'Date' | translate}} DESC</ion-select-option>
                                <ion-select-option value="name">{{'Name' | translate}} ASC</ion-select-option>
                                <ion-select-option value="-name">{{'Name' | translate}} DESC</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-list-header>
        <ion-item (click)="onBackFolder()" style="cursor: pointer" *ngIf="level>0 && !loading">
            <ion-label>
                <fa-icon [icon]="['fas', 'folder']" size="lg"></fa-icon> &nbsp;<u><b>...</b></u></ion-label>
        </ion-item>
        <ion-item *ngFor="let folder of folders" (click)="onClickFolder(folder)" style="cursor: pointer">
            <ion-label>
                <fa-icon [icon]="['fas', 'folder']" size="lg"></fa-icon> &nbsp;{{folder.name}}
            </ion-label>

        </ion-item>
        <ion-item class="text-center" *ngIf="list.length == 0 && !loading">
            <ion-label>
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                <i>{{'No notes here' | translate}}...</i></ion-label>
        </ion-item>
        <ion-item class="text-center" *ngIf="loading">
            <ion-label>
                <fa-icon [icon]="['fas', 'redo-alt']"></fa-icon>
                {{'Loading' | translate}}...
            </ion-label>
        </ion-item>

        <ion-item *ngFor="let item of list" style="cursor: pointer;">
            <ion-label (click)="presentModal(item)"><u><strong>
                <fa-icon [icon]="['fas', 'file']" size="lg"></fa-icon>
                {{item.title}}</strong></u>
                <i style="padding-left: 15px">
                    <small>{{item.created_at | parseDate}}</small>
                </i>
            </ion-label>
            <ion-note slot="end" (click)="remove(item)">
                <fa-icon class="text-danger" [icon]="['fas', 'trash']" size="lg"></fa-icon>
            </ion-note>
        </ion-item>
    </ion-list>
</ion-content>
