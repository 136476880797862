import {Injectable} from '@angular/core';
import { ResourceParams, ResourceAction, IResourceMethodPromise } from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
    pathPrefix: '/music'
})
export class MusicResource extends BaseResources {
    @ResourceAction({
        isArray: false,
        path: '/dates'
    })
    dates: IResourceMethodPromise<any, any>;
}
