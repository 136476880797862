import {GlobalProvider} from '@xplat-cgc/ionic';
import {NavController} from '@ionic/angular';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
    constructor(protected globalProvider: GlobalProvider, protected navController: NavController) {
    }
    canActivate() {
        // If the user is not logged in we'll send them back to the home page
        if (!this.globalProvider['global']['user']) {
            if (this.globalProvider['global']['mobile']) {
              this.navController.navigateRoot('/auth/login');
            } else {
              this.navController.navigateRoot('/auth/login');
            }
        }
        return true;
    }
}
