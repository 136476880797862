import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { saveAs } from 'file-saver'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import Mark from 'mark.js/src/lib/mark.js'
import * as _ from 'lodash'
import { PostResource } from '../../../../ui/pages/post/post-resource.service'
import * as printJS from 'print-js'
import { Platform } from '@ionic/angular'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Keyboard } from '@capacitor/keyboard'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { NavigationEnd, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Capacitor } from '@capacitor/core'
import { NgxExtendedPdfViewerService, TextLayerRenderedEvent } from 'ngx-extended-pdf-viewer'

let broserG: any = null


if (Capacitor.isNativePlatform()) {
  Keyboard.addListener('keyboardDidHide', () => {
    if (broserG) {
      broserG.show()
    }
  })

  Keyboard.addListener('keyboardDidShow', (info) => {
    if (broserG) {
      broserG.hide()
    }
  })
}


//import Panzoom from '@panzoom/panzoom'


@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
  export class VisorComponent implements OnInit, OnDestroy {
  isSafari = false
  public counterHits = 0
  public elementList = []
  public panzoom = null
  browser
  @Input() data = {}
  browserExit$
  public markOptions = {
    'separateWordSearch': false,
    'diacritics': true,
    'acrossElements': true,
    'iframes': true,
    'debug': true,
    'log': window.console,
    'accuracy': {
      'value': 'partially',
      'limiters': ['“', '”', '’', ',', '.', '"', '\'', ':', ';', '-', '—', '(', ')', '{', '}', '=', '!', '?', '_']
    },
    exclude: [
      '.loading-indicator',
      'img',
      '._',
      '.pi',
      '#sidebar',
      'head',
      '.xa'
    ],
    'ignorePunctuation': ':;.,-–—‒—_(){}[]!’\'"+='.split(''),
    'done': (counter) => {
      console.log(counter)
      //this.counterHits = counter;
      this.globalProvider.set('loadingViewer', false)
      setTimeout(() => {
        this.setScroll()
      }, 500)
    }
  }
  @ViewChild('videoPlayer', { static: true }) video: ElementRef
  public markText = ''
  public idxScroll = 0
  public zoomLevel = 0
  public printing = false
  public global = {}
  public isLoading = false

  protected _unsubscribeAll: Subject<any> = new Subject<any>()

  constructor(
    public globalProvider: GlobalProvider,
    protected postResource: PostResource,
    public platform: Platform,
    private iab: InAppBrowser,
    private _location: Location,
    private http: HttpClient,
    private _router: Router,
    public _zone: NgZone,
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService
  ) {
    this.global = this.globalProvider['global']
  }

  ngOnDestroy() {
    console.log('ngOnDestroy')
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
    this.onCloseWindow()
  }

  onCloseWindow() {
    if (this.browser) {
      this.browser.close()
    }
    this.browser = null
    broserG = null
    if (this.browserExit$) {
      this.browserExit$.unsubscribe()
    }
  }

  ionViewDidLeave() {
    console.log('ionViewDidLeave')
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
    this.onCloseWindow()
  }

  ngOnInit() {
    this._router.events.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this._zone.run(() => {
            this._unsubscribeAll.next(true)
            this._unsubscribeAll.complete()
            this.onCloseWindow()
          })
        }
      }
    )
    this.globalProvider.set('loadingViewer', true)
    setTimeout(() => {
      this.markText = this.global['result']['pdf']['queryText']
      this.onReloadPdf()
    }, 500)
  }

  setHighlights() {

    if (!this.global['mobile']) {
      console.log(this.markText)
        this.ngxExtendedPdfViewerService.find(
          this.markText,
          {
            highlightAll: true,
            matchCase: false,
            wholeWords: false,
            matchDiacritics: false
          }
        )

      return
    }

    const markInstance = new Mark(document.querySelector('.context'))
    const keyword = this.markText
    const options = this.markOptions
    if (_.isEmpty(keyword)) {
      return true
    }
    markInstance.unmark({
      iframes: true,
      log: window.console,
      debug: true,
      done: () => {
        markInstance.mark(keyword, options)
      }
    })
  }

  setScroll() {
    let elementList = null
    let elementVisor = null

    if (this.global['mobile']) {
      this.setScrollMobile()
      return
    }
    if (this.global['mobile']) {
      elementVisor = document.getElementById('visor-pdf')
    } else {
      if (!this.isSafari) {
        elementVisor = document.getElementById('visor-pdf')['contentWindow']['document']
      } else {
        elementVisor = document.getElementById('result-visor')
      }

    }

    /* if (!this.global['mobile']) {
      document.getElementById('visor-pdf').style.height = elementVisor.body.scrollHeight + 'px';
    } */

    elementVisor.querySelectorAll('.pc')
      .forEach((item) => {
        item.classList.add('opened')
      })

    elementList = elementVisor
      .querySelectorAll('mark')
    elementList = _.groupBy(elementList, (b) => {
      return b.getAttribute('data-index')
    })
    elementList = _.map(elementList, (val) => {
      return val[0]
    })

    this.elementList = elementList
    this.idxScroll = 0
    if (this.elementList.length) {

      this.moveScroll(null)
    }
  }

  private _showTextLayer = false;

  public get showTextLayer(): boolean {
    return this._showTextLayer;
  }

  public highlightWords(event: TextLayerRenderedEvent): void {
    if (this.showTextLayer) {

    }
  }

  moveScroll(back = false) {
    if (this.global['mobile']) {
      this.moveScrollMobile(back)
      return
    } else {
      if (back) {
        this.ngxExtendedPdfViewerService.findPrevious()
      } else {
        this.ngxExtendedPdfViewerService.findNext()
      }
      return
    }
    if (this.elementList.length) {
      if (back != null && back) {
        --this.idxScroll
      } else if (back != null && !back) {
        ++this.idxScroll
      }

      if (this.idxScroll < 0) {
        this.idxScroll = this.elementList.length - 1
      }
      if (this.idxScroll > this.elementList.length - 1) {
        this.idxScroll = 0
      }

      let elementVisor = null
      if (this.global['mobile']) {
        elementVisor = document.getElementById('visor-pdf')
      } else {
        if (!this.isSafari) {
          elementVisor = document.getElementById('visor-pdf')['contentWindow']['document']
        } else {
          elementVisor = document.getElementById('result-visor')
        }
      }

      elementVisor.querySelectorAll('mark.current-mark').forEach((item) => {
        item.classList.remove('current-mark')
      })

      this.elementList[this.idxScroll].scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' })

      elementVisor.querySelectorAll('mark[data-index="' + this.idxScroll + '"]').forEach((item) => {
        item.classList.add('current-mark')
      })
    }
  }

  onReloadPdf() {
    if (this.global['mobile']) {
      //this.data['data']['data'] = ;
      this.data['data']['data'].replace('<head>', '<head><meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5.0, minimum-scale=1">')
      this.openVisor()
      this.globalProvider.set('loadingViewer', false)
    }

    if (!this.global['mobile']) {
      const element = document.getElementById('visor-pdf')
      const visor = document.getElementById('result-visor')

      //this.data['data']['data'] = `${this.data['data']['data']} <script>document.domain = '${window.location.origin}';</script>`


      if (this.isSafari) {
        visor.innerHTML = this.data['data']['data']
        console.log('visor', visor)
        this.onLoadPdf()
      } else {
        this.onLoadPdf()
        /* element['src'] = '.'
        element['contentWindow']['document'].open()
        element['contentWindow']['document'].write(this.data['data']['data'])
        element['contentWindow']['document'].close() */
      }
      //element['contentWindow']['document'].write(this.data['data']['data'])


    }

  }

  async setScrollMobile() {
    this.browser.executeScript({
      code: 'setScroll();  undefined;'
    }).then((response) => {
    }).catch((response) => {
    })
  };

  async moveScrollMobile(back = false) {
    this.browser.executeScript({
      code: 'moveScroll(' + (back ? 'true' : 'false') + ');  undefined;'
    }).then((response) => {
    }).catch((response) => {
    })
  };

  async markTextContainer() {
    this.browser.executeScript({
      code: 'try { const markInstance = new Mark(document.querySelector(\'body\')); ' +
        ' const keyword = \'' + this.markText + '\';' +
        ' const options = ' + JSON.stringify(this.markOptions) + ';' +
        ' options[\'done\'] = function () { setTimeout(() => { setScroll();}, 500); }; ' +
        ' markInstance.unmark({' +
        '   iframes: true,' +
        '   log: window.console,' +
        '   debug: true,' +
        '   done: () => {' +
        '     markInstance.mark(keyword, options);' +
        '   }' +
        ' }); } catch(e) { webkit.messageHandlers.cordova_iab.postMessage(e.message); } ;  undefined;'
    }).then((response) => {

      this.isLoading = false
      if (Capacitor.isNativePlatform()) {
        this.browser.show()
      }
    }).catch((response) => {
    })
  }

  onFocus() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.show()
      this.browser.hide()
    }
    /* Keyboard.setAccessoryBarVisible({ isVisible: true }); */
    /*  Keyboard.setResizeMode({
       mode: KeyboardResizeMode.Native
     }
     ).then((response) => {


     }); */

  }

  async saveDataVisor(data, name) {
    return await Filesystem.writeFile({
      path: name,
      data: data,
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })
  }

  async openVisor() {
    let contentLodash = await this.http.get(window.location.origin + '/assets/js/core.min.js?v=' + (new Date).valueOf(), { responseType: 'text' }).toPromise()
    let contentMark = await this.http.get(window.location.origin + '/assets/js/mark.min.js?v=' + (new Date).valueOf(), { responseType: 'text' }).toPromise()
    let contentHelpers = await this.http.get(window.location.origin + '/assets/js/visor_helpers.js?v=' + (new Date).valueOf(), { responseType: 'text' }).toPromise()
    let contentHelpersPermission = await this.http.get(window.location.origin + '/assets/js/visor_helpers_permission.js?v=' + (new Date).valueOf(), { responseType: 'text' }).toPromise()
    try {
      const result = await this.saveDataVisor(this.data['data']['data'], 'base.html')
      const fileLodash = await this.saveDataVisor(contentLodash, 'core.min.js')
      const fileMark = await this.saveDataVisor(contentMark, 'mark.min.js')
      const fileHelpers = await this.saveDataVisor(contentHelpers, 'visor_helpers.js')
      const fileHelpersPermission = await this.saveDataVisor(contentHelpersPermission, 'visor_helpers_permission.js')


      broserG = this.browser = this.iab.create(result['uri'],
        '_blank', {
          location: 'no',
          toolbar: 'no',
          /* closebuttoncaption: '', */
          hideurlbar: 'yes',
          hidenavigationbuttons: 'yes',
          toolbarcolor: '#01106E',
          closebuttoncolor: '#FFFFFF',
          enableViewportScale: 'yes',
          fullscreen: 'no'
        })
      this.isLoading = true
      if (Capacitor.isNativePlatform()) {
        this.browser.hide()
      }

      if (this.platform.is('ios')) {
        fileLodash['uri'] = environment.resources + 'visor/core.min.js?v=' + (new Date).valueOf()
        fileMark['uri'] = environment.resources + 'visor/mark.min.js?v=' + (new Date).valueOf()
        fileHelpers['uri'] = environment.resources + 'visor/visor_helpers.js?v=' + (new Date).valueOf()
        fileHelpersPermission['uri'] = environment.resources + 'visor/visor_helpers_permission.js?v=' + (new Date).valueOf()
      } else {
        /*fileLodash['uri'] = Capacitor.convertFileSrc(fileLodash['uri']);
        fileMark['uri'] = Capacitor.convertFileSrc(fileMark['uri']);
        fileHelpers['uri'] = Capacitor.convertFileSrc(fileHelpers['uri']);
        fileHelpersPermission['uri'] = Capacitor.convertFileSrc(fileHelpersPermission['uri']);*/
      }

      this.browser.on('loadstop').subscribe(event => {
        this.browser.executeScript({ file: fileLodash['uri'] }).then((response) => {
          this.browser.executeScript({ file: fileMark['uri'] }).then((response) => {
            this.browser.executeScript({ file: fileHelpers['uri'] }).then((response) => {
              this.markTextContainer()

              if (this.global['user'] && !this.global['user']['select_text_sermon']) {
                this.browser.executeScript({ file: fileHelpersPermission['uri'] }).then((response) => {
                }).catch((response) => {
                  console.log('fileHelpersPermission', response)
                })
              }
            }).catch((response) => {
              console.log('fileHelpers', response)
            })
          }).catch((response) => {
            console.log('fileMark', response)
          })
        }).catch((response) => {
          console.log('fileLodash', response)
        })
      })
      this.browser.on('message').subscribe(event => {
      })
      this.browserExit$ = this.browser.on('exit').subscribe(event => {
        this._location.back()
      })


    } catch (e) {
      console.error('Unable to write file', e)
    }

  }

  onKeyUpQuery(event) {
    if (event.keyCode === 13) {
      if (Capacitor.isNativePlatform()) {
        Keyboard.hide()
      }
      this.onReloadPdf()
    }
  }

  onZoomIn() {
    if (this.zoomLevel > 4) {
      return
    }
    if (this.global['mobile']) {
      return false
      this.browser.executeScript({
        code: 'onZoomIn();  undefined;'
      }).then((response) => {
      }).catch((response) => {
      })
      ++this.zoomLevel
    }

    if (this.isSafari) {
      const elementVisor = document.getElementById('result-visor')
      elementVisor.classList.remove('zoom-' + this.zoomLevel)
      this.zoomLevel++
      elementVisor.classList.add('zoom-' + this.zoomLevel)
      return false
    }

    if (!this.global['mobile']) {
      this.zoomLevel++
    }
    let element = null
    if (this.global['mobile']) {
      element = document.getElementById('visor-pdf')
    } else {
      element = document.getElementById('visor-pdf')
    }
    if (element) {
      element.classList.remove('zoom-' + this.zoomLevel)
      this.zoomLevel++
      element.classList.add('zoom-' + this.zoomLevel)
    }
  }


  onZoomOut() {
    if (this.zoomLevel < 1) {
      return
    }
    if (this.global['mobile']) {
      return false
      this.browser.executeScript({
        code: 'onZoomOut();  undefined;'
      }).then((response) => {
      }).catch((response) => {
      })
      --this.zoomLevel
      /*var zoom = this.zoomLevel * 2;
      this.panzoom.zoomAbs(this.panzoom.getTransform().x, this.panzoom.getTransform().y, Math.ceil((zoom + .5) / 10) + '.' + zoom);*/

    }
    if (this.isSafari) {
      const elementVisor = document.getElementById('result-visor')
      elementVisor.classList.remove('zoom-' + this.zoomLevel)
      this.zoomLevel--
      elementVisor.classList.add('zoom-' + this.zoomLevel)
      return false
    }

    if (!this.global['mobile']) {
      this.zoomLevel--
    }

    let element = null
    if (this.global['mobile']) {
      element = document.getElementById('visor-pdf')
    } else {
      element = document.getElementById('visor-pdf')
    }

    if (element) {
      element.classList.remove('zoom-' + this.zoomLevel)
      this.zoomLevel--
      element.classList.add('zoom-' + this.zoomLevel)
    }
  }

  onDownload() {
    this.printing = true
    this.postResource.printSearch({ 'id': this.global['idPDF'] }).then((response) => {
      saveAs(response, this.global['idPDF'] + '.pdf')
    })
  }

  onPrint() {
    this.printing = true
    this.postResource.printSearch({ 'id': this.global['idPDF'] }).then((response) => {
      const pdfFile = new Blob([response], {
        type: 'application/pdf'
      })
      const pdfUrl = URL.createObjectURL(pdfFile)
      printJS(pdfUrl)
    })
  }

  onLoadPdf() {

    if (!this.global['mobile']) {
      setTimeout(() => {
        this.setHighlights()
        return
        const element = document.getElementById('visor-pdf')
        console.log(element)
        if (!element) {
          if (this.isSafari) {
            this.setHighlights()
          }
          return false
        }
        //block.style.transformOrigin = `${originX}% -${originY}%`;

        // write new content
        const zoom = 'body {-moz-transform-origin: 0 0; -o-transform-origin: 0 0; -webkit-transform-origin: 0 0;}; body > div {margin: auto}' +
          '.current-mark{ background-color: #ffc196 !important;} #page-container {  -webkit-touch-callout: none;\n' +
          '  -webkit-user-select: none;\n' +
          '  -khtml-user-select: none;\n' +
          '  -moz-user-select: none;\n' +
          '  -ms-user-select: none;\n' +
          '  user-select: none;}\n' +
          '#page-contain {\n' +
          '  zoom: 1.0;\n' +
          '  -ms-zoom: 1.0;\n' +
          '  -webkit-zoom: 1.0;\n' +
          '  -moz-transform: scale(1.0);\n' +
          '  -o-transform: scale(1.0);\n' +
          '  -webkit-transform: scale(1.0);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-0 {\n' +
          '  zoom: 1.0;\n' +
          '  -ms-zoom: 1.0;\n' +
          '  -webkit-zoom: 1.0;\n' +
          '  -moz-transform: scale(1.0);\n' +
          '  -o-transform: scale(1.0);\n' +
          '  -webkit-transform: scale(1.0);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-1 {\n' +
          '  zoom: 1.1;\n' +
          '  -ms-zoom: 1.1;\n' +
          '  -webkit-zoom: 1.1;\n' +
          '  -moz-transform: scale(1.1);\n' +
          '  -o-transform: scale(1.1);\n' +
          '  -webkit-transform: scale(1.1);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-2 {\n' +
          '  zoom: 1.2;\n' +
          '  -ms-zoom: 1.2;\n' +
          '  -webkit-zoom: 1.2;\n' +
          '  -moz-transform: scale(1.2);\n' +
          '  -o-transform: scale(1.2);\n' +
          '  -webkit-transform: scale(1.2);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-3 {\n' +
          '  zoom: 1.3;\n' +
          '  -ms-zoom: 1.3;\n' +
          '  -webkit-zoom: 1.3;\n' +
          '  -moz-transform: scale(1.3);\n' +
          '  -o-transform: scale(1.3);\n' +
          '  -webkit-transform: scale(1.3);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-4 {\n' +
          '  zoom: 1.4;\n' +
          '  -ms-zoom: 1.4;\n' +
          '  -webkit-zoom: 1.4;\n' +
          '  -moz-transform: scale(1.4);\n' +
          '  -o-transform: scale(1.4);\n' +
          '  -webkit-transform: scale(1.4);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}\n' +
          '.zoom-5 {\n' +
          '  zoom: 1.5;\n' +
          '  -ms-zoom: 1.5;\n' +
          '  -webkit-zoom: 1.5;\n' +
          '  -moz-transform: scale(1.5);\n' +
          '  -o-transform: scale(1.5);\n' +
          '  -webkit-transform: scale(1.5);\n' +
          ` -moz-transform-origin: 0  center` +
          ` transform-origin: 0  center` +
          '}' +
          ''
        /*  const style = document.createElement('STYLE');
         style.type = 'text/css';
         style.appendChild(document.createTextNode('.current-mark { background-color: #ffc196 !important; }'));
         document.head.appendChild(style); */
        var cssLink = document.createElement('style')
        cssLink.type = 'text/css'
        cssLink.appendChild(document.createTextNode(zoom))

        if (!this.isSafari) {
          element['contentWindow']['document']['body'].appendChild(cssLink)
        }
        setTimeout(() => {
          console.log('setHighlights')
          this.setHighlights()
        }, 1000)
      }, 100)

    } else {
      this.setHighlights()
    }
  }

  protected readonly environment = environment
}
