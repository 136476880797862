<ion-grid style="padding: 0">
  <ion-row
    style="background-color: #fafafa; padding: 7px;position: fixed;width: 100%;z-index: 999;"
  >
    <ion-col size="6" class="ion-no-padding">
      <mat-form-field style="width: 100%" appearance="outline">
        <input
          matInput
          [(ngModel)]="markText"
          type="text"
          (click)="onFocus()"
          placeholder="{{ 'Find in document' | translate }}..."
          (keyup)="onKeyUpQuery($event)"
        />
        <fa-icon
          matSuffix
          [icon]="['fas', 'search']"
          (click)="onReloadPdf()"
          size="lg"
        ></fa-icon>
      </mat-form-field>
    </ion-col>
    <ion-col size="4" class="p-sm-0">
      <ion-buttons>
        <ion-button (click)="moveScroll(false)" class="m-sm-0">
          <fa-icon [icon]="['fas', 'arrow-down']" size="lg"></fa-icon>
        </ion-button>
        <ion-button (click)="moveScroll(true)" class="m-sm-0">
          <fa-icon [icon]="['fas', 'arrow-up']" size="lg"></fa-icon>
        </ion-button>
        <ion-button *ngIf="!global['mobile']" (click)="onZoomIn()" class="m-sm-0 ion-float-right">
          <fa-icon [icon]="['fas', 'search-plus']" size="lg"></fa-icon>
        </ion-button>
        <ion-button *ngIf="!global['mobile']" (click)="onZoomOut()" class="m-sm-0 ion-float-right">
          <fa-icon [icon]="['fas', 'search-minus']" size="lg"></fa-icon>
        </ion-button>
        <ion-button class="m-sm-0 ion-float-right"
                    (click)="onPrint()"
                    *ngIf="global['user'] && (global['user']['download_print_sermon'] || global['user']['country_id'] == 201) && !global['mobile']"
        >
          <fa-icon [icon]="['fas', 'print']" size="lg"></fa-icon>
        </ion-button>
        <ion-button class="m-sm-0 ion-float-right"
                    (click)="onDownload()"
                    *ngIf="global['user'] && (global['user']['download_print_sermon'] || global['user']['country_id'] == 201) && !global['mobile']"
        >
          <fa-icon [icon]="['fas', 'download']" size="lg"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-col>
  </ion-row>
</ion-grid>
<div
  id="row-visor"
  style="width:100%;overflow:auto !important;-webkit-overflow-scrolling:touch !important;position: absolute;
    top: 57px;"
  [ngStyle]="{'height': global['mobile'] ? 'calc(100vh - 170px)' : 'calc(100vh - 113px)'}">
  <div class="context content-result" id="result-visor">
    <div *ngIf="!global['mobile']">
      <ngx-extended-pdf-viewer
        *ngIf="data['data'] && data['data']['data']"
        [base64Src]="data['data']['data']"
        [height]="'auto'"
        [zoom]="zoomLevel === 0 ? 'page-width' : (100 + ((zoomLevel  * .1 ) * 100)) + '%'"
        [textLayer]="true"
        (pdfLoaded)="onLoadPdf()"
        [showToolbar]="false"
        [showBorders]="false"
        [minifiedJSLibraries]="environment.production"
        (textLayerRendered)=highlightWords($event);
        [showHandToolButton]="false"
      ></ngx-extended-pdf-viewer>
      <div style="position: absolute; left: -600000px; top: -177777px">
        jkahsdjkhakjdhjkahkjdhakjhdjk
      </div>
      <!--<iframe
        id="visor-pdf"
        frameborder="0"
        border="0"
        class="visor-pdf"
        zooming="true"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        (load)="onLoadPdf()"
        width="100"
        style="margin-top: 25px; width: 100%; height: calc(100vh - 144px)"
      ></iframe>-->
      <!--iframe class="visor-pdf" src="http://127.0.0.1/congregacion/temp/pdf255.html" id="visor-pdf" width="100" style="width: 100%; height: 100vh"></iframe-->
    </div>

    <!--  <div
       *ngIf="global['mobile']"
       #pdf
       id="visor-pdf"
       class="mobile"
       scrolling="yes"
       [innerHTML]="data['data']['data'] | safeHtml"
     ></div> -->
  </div>
  <ngx-loading *ngIf="!global['mobile']"
               [show]="isLoading"
               [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
