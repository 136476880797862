import { GlobalService } from '@xplat-cgc/ionic/features/providers/services/global-service';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';
import { BaseListComponent } from '@xplat-cgc/ionic/features/providers/base-list-component';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { NotificationUserResource } from '../notification-user.resource';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, Platform } from '@ionic/angular';
//import {BaseListComponent, GlobalProvider, GlobalService} from '@xplat-cgc/ionic/features/providers';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends BaseListComponent
  implements OnInit, AfterViewInit {
  @Input() type = '';
  public global = {};

  constructor(
    protected resource: NotificationUserResource,
    public globalProvider: GlobalProvider,
    protected translate: TranslateService,
    public platform: Platform,
    protected modalCtrl: ModalController,
    protected globalService: GlobalService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.global = this.globalProvider['global'];
  }

  ngAfterViewInit() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  ago(timestamp) {
    return this.globalService.ago(timestamp);
  }

  onDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  ngOnInit() {
    this.wheres = {
      user_id: this.globalProvider.global['user']['id'],
      _withJoin: 'notification',
      _limit: this.type === 'popover' ? 10 : 25
    };
    super.ngOnInit();
  }
}
