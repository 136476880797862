<ion-header>
  <ion-toolbar color="primary">
    <ion-title  class="ion-text-uppercase py-2">
      <ion-img style="height: 60px;" src="assets/img/logo_big_white.png"></ion-img>
    </ion-title>
    <ion-buttons slot="end" *ngIf="close">
        <ion-button (click)="onClose()">
            <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
        </ion-button>
    </ion-buttons>
    <!--ion-buttons slot="end">
        <div *ngIf="close" class="float-right text-white" (click)="onClose()"><fa-icon [icon]="['fa' , 'times']" size="2x pr-4"></fa-icon></div>
    </ion-buttons-->
  </ion-toolbar>
  </ion-header>
<ion-content>
  <div *ngIf="global['mobile']">
    <ion-img  src="assets/img/cgc-app-set-plans-{{global['lang'] || 'en'}}.jpeg"></ion-img>
  </div>
  <div class="alert alert-info" style="padding: 20px;font-weight: bolder;background: #e8d5b2;">
    {{'You will be able to enjoy your changes IMMEDIATELY. By making these changes today your next invoice will reflect prorated charges or credits and may be different than normally expected if today is not your end month payment date.' | translate}}
  </div>
  <div class="p-2" *ngIf="!global['mobile']">
    <form [formGroup]="formUser">
        <ion-list lines="none">
            <h5 class="title "><strong>{{'Select Plan' | translate}}</strong></h5>
            <ion-toolbar color="danger" *ngIf="user['plan_id'] == 4 && user['expire_trial']">
                <ion-title  class="ion-text-uppercase"><fa-icon [icon]="['fas', 'exclamation']"></fa-icon> &nbsp; &nbsp;{{'Your testing period is over, you must select a plan to enter' | translate}}</ion-title>
            </ion-toolbar>
            <ion-radio-group
                            [(ngModel)]="plan_id" formControlName="plan_id">
              <ng-container *ngFor="let plan of selects['plan']">
                <ion-item *ngIf="plan['id'] != hideId">
                  <ion-radio
                    mode="md"
                    color="secondary"
                    slot="start"
                    [value]="plan['id']" (click)="onSelectPlan(plan)"
                    class="mr-3"
                  ></ion-radio>
                  <p class="m-0">
                    <strong>
                      {{ plan['name'][global['lang'] || 'en'] }}
                      <strong>{{ plan['price'].hasOwnProperty(user['country_id']) && user['country_id'] == 117 ?  (plan['price'][user['country_id']]  | currency) + ' MXN' :  (plan['price'][201] | currency) + ' USD' }}</strong>
                    </strong>
                  </p>
                </ion-item>
                <ion-item  *ngIf="plan['id'] != hideId">
                  <p class="m-0" [innerHTML]="plan['description'][global['lang'] || 'en']"></p>
                </ion-item>
              </ng-container>
            </ion-radio-group>
            <div>
                <ion-button type="button" [disabled]="loading"
                            expand="block" color="secondary"
                            (click)="onSave()">
                    <fa-icon *ngIf="loading" [icon]="['fas', 'spinner']"
                            [spin]="true"></fa-icon>&nbsp;&nbsp;
                    {{'Next' | translate}}
                </ion-button>
            </div>

        </ion-list>
    </form>
  </div>
</ion-content>
<ion-footer *ngIf="global['mobile']">
  <ion-toolbar>
   <div class="text-center">
    <a href="javascript:" (click)="onChangeLang('en')">English</a>
        &nbsp;&nbsp;&nbsp;<a href="javascript:" (click)="onChangeLang('es')">Español</a>
    </div>
  </ion-toolbar>
</ion-footer>
