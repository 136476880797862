import {Injectable} from '@angular/core';
import { ResourceParams, ResourceRequestMethod, ResourceAction, IResourceMethod } from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
  pathPrefix: '/liveEvent'
})
export class LiveResource extends BaseResources {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: 'liveEvent',
    path: '/check',
    auth: false
  })
  check: IResourceMethod<any, any>;
}
