import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core'
import { SearchService } from '../search.service'
import { Platform } from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { Router } from '@angular/router'
import { GlobalProvider, StrongResource } from '@xplat-cgc/ionic/features/providers'
import * as _ from 'lodash'

@Component({
  selector: 'app-search-strong',
  templateUrl: './search-strong.component.html',
  styleUrls: ['./search-strong.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchStrongComponent implements OnInit {
  public global = {};

  constructor(
    public globalProvider: GlobalProvider,
    protected searchService: SearchService,
    protected strongResource: StrongResource,
    protected events: Events,
    public router: Router,
    private cdRef: ChangeDetectorRef,
    private elRef: ElementRef,
    public platform: Platform
  ) {
    this.global = this.globalProvider['global'];
    this.events.subscribe('event:loadResultStrong', data => {
      setTimeout(() => {
        this.addEvent();
      });
    });
  }

  addEvent() {
    if (this.global['result']['strong']['data']) {
      this.elRef.nativeElement
        .querySelectorAll('.lexiconcData')
        .forEach(item => {
          item.addEventListener('click', $event => {
            this.global['result']['strong'][
              'title'
            ] = $event.target.parentNode.querySelector('a').innerHTML;
            this.onSelectStrong(
              $event.target.parentNode.querySelector('a').getAttribute('class')
            );
            this.elRef.nativeElement
              .querySelectorAll('.item-mark')
              .forEach(tr => {
                tr.classList.remove('item-mark');
              });
            $event.target.parentNode.classList.add('item-mark');
          });
        });
    }
  }

  onSelectStrong(data) {
    this.global['originSelect'] = 'search';
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    if (this.global['mobile']) {
      this.router.navigate([
        '/tabs/study/search/section/' + this.global['searchType'] + '/viewer'
      ]);
    } else {
      this.router.navigate(['/search']);
    }
    this.global['loadingViewer'] = true;
    this.strongResource
      .chapter({
        id: data
      })
      .then(response => {
        this.events.publish('event:viewer', {
          type: 'strong',
          data: response['data']
        });
      })
      .catch(() => {
        this.global['loadingViewer'] = false;
      });
  }

  ionViewDidEnter() {
    this.addEvent();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onSelect(data) {
    this.global['originSelect'] = 'search';
    this.events.publish('event:viewer', {
      type: 'strong',
      data: {
        content: data['text']
      }
    });
  }

  ngOnInit() {
    this.addEvent();
  }

  onQuery(event) {
    this.searchService
      .onQueryStrong({
        query: this.global['queryText']
      })
      .then(response => {
        if (response['type'] === 'detail') {
          this.events.publish('event:viewer', {
            type: 'strong',
            data: response['data']
          });
        }
      })
      .finally(() => {
        event.target.complete();
      });
  }
}
