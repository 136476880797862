import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {
  IonSlides,
  ModalController,
  NavController,
  Platform,
  MenuController, AlertController
} from '@ionic/angular';
import {environment} from '../../../../core/environments/environment';
import {BaseResources} from '../../../providers/resources/base-resources';
import {GlobalProvider} from '../../../providers/global-provider';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UserResource} from '@xplat-cgc/ionic/features/ui/pages/auth/user-resource';
import {Router} from '@angular/router';
import {SubscriptionResource} from '../../../providers/resources/subscription-resource';

@Component({
  selector: 'QCH-ion-plan-modal',
  templateUrl: 'plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  public selects = {};
  public global = {};
  @Input() public user = {};
  @Input() public hideId = null;
  @Input() protected instanceModalParent = null;
  @Input() public close = false;
  public formUser;
  public loading = false;
  public plan_id = null;
  currentPlan;
  constructor(
    public fb: UntypedFormBuilder,
    private modalController: ModalController,
    private resource: UserResource,
    protected resourceSubscription: SubscriptionResource,
    protected translate: TranslateService,
    protected globalProvider: GlobalProvider,
    protected platform: Platform,
    protected navController: NavController,
    public router: Router,
    protected menu: MenuController,
    private http: HttpClient,
    private alertController: AlertController
  ) {
  }

  ngOnInit() {
    this.global = this.globalProvider['global'];
    let notIn = [];
    let data = {
      _sort: 'order'
    };
    if (this.global['user'] && this.global['user']['expire_trial']) {
      notIn.push(4);
    }
    /*if (!this.global['mobile']) {
      notIn.push(1);
    }*/
    if (notIn.length) {
      data['id-not-in'] = notIn.join(',');
    }
    data['public'] = true;
    this.currentPlan = this.user['plan'];
    const headers = new HttpHeaders();
    this.plan_id = this.global['user']['plan_id'];
    this.resource.selects({plan: data}).then(response => {
      this.selects['plan'] = response['data']['plan'];
      this.user = this.global['user'];

    });

    this.formUser = this.fb.group({
      plan_id: ['', Validators.compose([Validators.required])],
      terms: ['', Validators.compose([])]
    });
  }

  onClose() {
    this.modalController.dismiss();
    this.globalProvider['openModalPlan'] = false;
  }

  onSelectPlan(id) {
    this.currentPlan = id;
  }

  async onConfirmToPayment() {
    const translate = await this.translate.get([
      'Are you sure to change your payment plan?',
      'Confirm',
      'Cancel'
    ]).toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Are you sure to change your payment plan?'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.onSave(true);
          }
        }
      ]
    });

    await alert.present();
  }

  async onConfirmToFree() {
    const translate = await this.translate.get([
      'Are you sure to cancel your payment plan?',
      'Confirm',
      'Cancel'
    ]).toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Are you sure to cancel your payment plan?'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.onSave(true);
          }
        }
      ]
    });

    await alert.present();
  }

  async onConfirmToSamePayment() {
    const translate = await this.translate.get([
      'Are you sure to change your payment plan?',
      'The total of the new plan will be charged in the next period',
      'Confirm',
      'Cancel'
    ]).toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Are you sure to change your payment plan?'] + '\n' + translate['The total of the new plan will be charged in the next period'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.onSave(true);
          }
        }
      ]
    });

    await alert.present();
  }

  async onSelectPlanConfirm() {
    const translate = await this.translate.get([
      'Select Plan',
      'Confirm',
      'Cancel'
    ]).toPromise();
    const alert = await this.alertController.create({
      header: translate['Confirm'] + '!',
      message: translate['Select Plan'],
      buttons: [
        {
          text: translate['Cancel'],
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }

  onSave(confirm = false) {
    if (!this.plan_id) {
      this.onSelectPlanConfirm();
      return false;
    }
    /*if (!confirm && this.global['user']['plan']['price'][201] === 0 && this.currentPlan['price'][201] > 0) {
      this.onConfirmToPayment();
      return true;
    }*/
    if (!confirm && this.global['user']['plan']['price'][201] > 0 && this.currentPlan['price'][201] === 0) {
      this.onConfirmToFree();
      return true;
    }
    if (!confirm && this.global['user']['plan']['price'][201] > 0 && this.currentPlan['price'][201] > 0) {
      this.onConfirmToSamePayment();
      return true;
    }
    let method = 'update';
    if (!this.global['user']['subscription']) {
      method = 'store';
    }
    this.loading = true;
    /*this.resource
      .update({
        id: this.user['id'],
        plan_id: this.plan_id
      })
      .finally(() => {
        this.loading = false;
      })
      .then(res => {
        console.log(res);*/
    this.resourceSubscription
      [method]({
      id: this['global']['user']['id'],
      plan_id: this.plan_id
    })
      .then(async response => {
        if (this.global['mobile']) {
          this.navController.navigateRoot('/tabs');
        } else {
          this.navController.navigateRoot('/home');
        }
        this.modalController.dismiss();
        await this.globalProvider.load();
      })
      .catch(error => {
        if (error.status === 402) {
          this.navController.navigateRoot(
            '/auth/payment/plan/' + this.plan_id
          );
          this.modalController.dismiss();
        }
        /*if (error.status == 404) {
          if (
            (res['data']['subscription'] &&
              res['data']['subscription']['status'] === 'coupon') ||
            res['data']['plan']['price'] === 0
          ) {
            if (this.global['mobile']) {
              this.navController.navigateRoot('/tabs');
            } else {
              this.navController.navigateRoot('/home');
            }
          } else {
            this.navController.navigateRoot(
              '/auth/payment/plan/' + this.plan_id
            );
          }
          this.modalController.dismiss();
        }*/
      })
      .finally(() => {
        this.loading = false;
      });
    //});
  }

  onChangeLang(lang) {
    this.global['lang'] = lang;
    window.localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
}
