import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'

@Injectable({
    providedIn: 'root'
})
export class UserData {
    HAS_LOGGED_IN = 'hasLoggedIn';

    constructor(public events: Events, public storage: Storage) {
    }

    login(user): Promise<any> {
        const promise = this.storage.set(this.HAS_LOGGED_IN, true);
        window.localStorage['token'] = user['token'];
        promise.then(() => {
            return this.events.publish('user:login', user);
        });
        return promise;
    }

    logout(): Promise<any> {
        delete window.localStorage['token'];
        return this.storage
            .remove(this.HAS_LOGGED_IN)
            .then(() => {
                return this.storage.remove('user');
            })
            .then(() => {
                this.events.publish('user:logout');
            });
    }

    setUser(user): Promise<any> {
        return this.storage.set('user', user);
    }

    getUser() {
        return this.storage.get('user');
    }

    isLoggedIn(): Promise<boolean> {
        return this.storage.get(this.HAS_LOGGED_IN).then(value => {
            return value === true;
        });
    }
}
