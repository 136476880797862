import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-aboutus',
  templateUrl: './permission-modal.page.html',
  styleUrls: ['./permission-modal.page.scss'],
})
export class PermissionModalPage implements OnInit {

  constructor(public modalController: ModalController) { }
  public ngOnInit() {
  }

}
