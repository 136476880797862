import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArraySortPipe} from './array-sort.pipe';
import {HighlightifyPipe} from './highlightify.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {SafeUrlPipe} from './safe-url.pipe';
import { ParseDatePipe } from './parse-date.pipe';

@NgModule({
    imports: [CommonModule],
    exports: [ArraySortPipe, HighlightifyPipe, SafeHtmlPipe, SafeUrlPipe, ParseDatePipe],
    declarations: [ArraySortPipe, HighlightifyPipe, SafeHtmlPipe, SafeUrlPipe, ParseDatePipe]
})
export class PipesModule {
    constructor() {
    }
}
