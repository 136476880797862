import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import {
  GlobalService,
  ToastService
} from '@xplat-cgc/ionic/features/providers/services';
import { NoteFolderResource } from '../note-folder-resource.service';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';

@Component({
  selector: 'app-note-folder-add',
  templateUrl: './note-folder-add.component.html',
  styleUrls: ['./note-folder-add.component.scss']
})
export class NoteFolderAddComponent implements OnInit {
  @ViewChild('folderName') fName;
  @Input() user_id: any;

  public noteFolder: any = {};
  public newFolder: any = {};
  public loading = false;
  public addFolderHere = false;
  public folders = [];
  public backFolder = [];
  public level = 0;
  public folder_up_id = 0;
  public title_folder = 'My folders';

  constructor(
    protected modalCtrl: ModalController,
    protected toastService: ToastService,
    protected folderResource: NoteFolderResource,
    protected globalService: GlobalService,
    public platform: Platform,
    protected globalProvider: GlobalProvider
  ) {}

  async initial() {
    this.loading = true;
    this.folders = [];
    this.globalService
      .fillBatch({
        noteFolder: {
          _sort: 'name',
          user_id: this.user_id,
          folder_up_id: 'null'
        }
      })
      .then(data => {
        this.folders = data['data']['noteFolder'];
        this.loading = false;
      });
  }

  ngOnInit() {
    if (!this.user_id) {
      this.user_id = this.globalProvider.get('user')['id'];
    }
    this.initial();
  }

  onClose() {
    this.modalCtrl.dismiss();
  }

  onAdd() {
    this.newFolder.folder_up_id =
      this.folder_up_id > 0 ? this.folder_up_id : null;
    this.newFolder.user_id = this.user_id;
    this.folderResource
      .store(this.newFolder)
      .then(data => {
        this.toastService.success({
          message: data['message'],
          position: 'top'
        });

        this.loading = true;
        this.folders = [];
        this.globalService
          .fillBatch({
            noteFolder: {
              _sort: 'name',
              user_id: this.user_id,
              folder_up_id: this.folder_up_id === 0 ? 'null' : this.folder_up_id
            }
          })
          .then(response => {
            this.folders = response['data']['noteFolder'];
            this.loading = false;
          });

        this.addFolderHere = false;
        this.newFolder = {};
      })
      .catch(err => {
        console.log(err);
      });
  }

  onClickFolder(row) {
    this.backFolder[this.level] = {
      noteFolder: this.noteFolder,
      folder_up_id: this.folder_up_id,
      title_folder: this.title_folder
    };
    this.noteFolder = row;
    this.level++;
    this.folder_up_id = this.noteFolder.id;
    this.title_folder = this.noteFolder.name;
    this.loading = true;
    this.folders = [];
    this.globalService
      .fillBatch({
        noteFolder: {
          _sort: 'name',
          user_id: this.user_id,
          folder_up_id: this.folder_up_id
        }
      })
      .then(data => {
        this.folders = data['data']['noteFolder'];
        this.loading = false;
      });
  }

  onBackFolder() {
    this.addFolderHere = false;
    this.level--;
    this.noteFolder = this.backFolder[this.level].noteFolder;
    this.folder_up_id =
      this.backFolder[this.level].folder_up_id > 0
        ? this.backFolder[this.level].folder_up_id
        : 'null';
    this.title_folder = this.backFolder[this.level].title_folder;
    this.loading = true;
    this.folders = [];
    this.globalService
      .fillBatch({
        noteFolder: {
          _sort: 'name',
          user_id: this.user_id,
          folder_up_id: this.folder_up_id
        }
      })
      .then(data => {
        this.folders = data['data']['noteFolder'];
        this.loading = false;
      });
  }

  onSelectFolder() {
    this.modalCtrl.dismiss(this.noteFolder);
  }

  onNewFolder() {
    this.addFolderHere = true;
    setTimeout(() => {
      this.fName.setFocus();
    }, 100);
  }
}
