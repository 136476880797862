import { BaseCopyResources } from './../../../providers/resources/base-copy-resources';
import { BaseResources } from './../../../providers/resources/base-resources';
import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/user'
})
export class UserResource extends BaseCopyResources {
    @ResourceAction({
        pathPrefix: '/auth',
        method: ResourceRequestMethod.Post,
        path: '/signup',
        auth: false
    })
    store: IResourceMethod<Object, Object>;

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/sender'
    })
    sender: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}/remove'
  })
  remove: IResourceMethod<Object, Object>;


  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}/remove/confirm'
  })
  removeConfirm: IResourceMethod<Object, Object>;
}
