import { PipesModule } from '@xplat-cgc/ionic/features/providers/pipes/pipes.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxLoadingModule} from 'ngx-loading';
import {QuillModule} from 'ngx-quill';
import {FileUploadModule} from 'ng2-file-upload';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFile, faFolder, faTimes, faTrash, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {PostListComponent} from './post-list/post-list.component';
import {PostAddComponent} from './post-add/post-add.component';
import {PostShowComponent} from './post-show/post-show.component';
import {PostWeekComponent} from './post-week/post-week.component';
import {PostListDownloadedComponent} from './post-list-downloaded/post-list-downloaded.component';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        NgxLoadingModule,
        QuillModule,
        NgxLoadingModule,
        FileUploadModule,
        PipesModule
    ],
    declarations: [
        PostListComponent,
        PostAddComponent,
        PostListComponent, PostShowComponent, PostWeekComponent,
        PostListDownloadedComponent
    ],
    exports: [
        PostListComponent,
        PostAddComponent,
        PostListComponent, PostShowComponent, PostWeekComponent,
    ]
})
export class PostSharedModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faTimes);
        library.add(faTrash);
        library.add(faFile);
        library.add(faFolder);
        library.add(faUserCircle);
    }
}
