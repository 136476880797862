import { GlobalService } from './../../../providers/services/global-service';
import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BibleResource, StrongResource} from '../../../providers/resources';
import {GlobalProvider} from '../../../providers/global-provider';
import {PostResource} from '../post/post-resource.service';
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'

@Injectable()
export class SearchService {
    protected global = {};

    constructor(
        protected bibleResource: BibleResource,
        protected strongResource: StrongResource,
        protected globalProvider: GlobalProvider,
        protected postResource: PostResource,
        protected events: Events,
        protected globalService: GlobalService,
        public router: Router
    ) {
        this.global = this.globalProvider['global'];
    }


    public onQueryPost(params) {
        const key = params['type'];
        this.global['result'][key]['loading'] = true;
        if (_.isEmpty(this.global['result'][key])) {
            this.global['result'][key] = {};
        }

        if (!this.global['result'][key]['page']) {
            this.global['result'][key]['page'] = 0;
        }

        if (_.isEmpty(this.global['sort'])) {
            this.global['sort'] = 'created_at';
        }

        let queryParams = {
            query: params['query'],
            _page: this.global['result'][key]['page'],
            _limit: 40,
            'type-in': params['type'] == 'pdf' ? 'pdf,book' : params['type'],
            '_sort': this.global['result'][key]['sort']
        };

        if (params['type'] === 'post') {
            queryParams['user_created_id'] = this.global['user'] ? this.global['user']['id'] : '';
        }
        const promise = this.postResource.query(queryParams);

        promise.then((response) => {
            if (_.isEmpty(this.global['result'][key]['data']['data'])) {
                this.global['result'][key]['data']['data'] = [];
            }
            this.global['result'][key]['data'] = _.assign(_.omit(response['data'], 'data'), this.global['result'][key]['data']);
            _.forEach(response['data']['data'], (value) => {
                this.global['result'][key]['data']['data'].push(value);
            });
        }).finally(() => {
            this.global['result'][key]['loading'] = false;
        });
        return promise;
    }



    public onQueryBible(params) {
        this.global['result']['bible']['loading'] = true;
        if (_.isEmpty(this.global['result']['bible']['data'])) {
            this.global['result']['bible']['data'] = {};
        }

        if (!this.global['result']['bible']['page']) {
            this.global['result']['bible']['page'] = 0;
        }

        const book = _.findLast(this.globalProvider.books[this.global['lang']], (r) => {
            return this.globalService.accentsTidy(_.toLower(r['name'])) === this.globalService.accentsTidy(_.toLower(params['query']));
        });

        if (book) {
            params['query'] = params['query'] + ' 1';
        }

        const promise = this.bibleResource.query({
            query: params['query'],
            fuzziness: 0,
            sort: 'canonical',
            offset: this.global['result']['bible']['page'], limit: 40
        });
        promise.then((reponse) => {
            if (this.global['result']['bible']['data']['verses']) {
                _.forEach(reponse['data']['verses'], (value) => {
                    this.global['result']['bible']['data']['verses'].push(value);
                });
            } else if (this.global['result']['bible']['data']['passages']) {
                _.forEach(reponse['data']['passages'], (value) => {
                    this.global['result']['bible']['data']['passages'].push(value);
                });
            } else {
                this.global['result']['bible']['data'] = reponse['data'];
            }
        }).finally(() => {
            this.global['result']['bible']['loading'] = false;
        });
        return promise;
    }

    public onQueryStrong(params) {
        this.global['result']['strong']['loading'] = true;
        if (_.isEmpty(this.global['result']['strong']['data'])) {
            this.global['result']['strong']['data'] = '';
        }

        const promise = this.strongResource.query({
            query: params['query']
        });
        promise.then((response) => {
            if (response['type'] === 'detail' && this.global['searchType'] === 'strong') {
                if (this.global['mobile']) {
                    this.router.navigate(['/tabs/study/search/section/' + this.global['searchType'] + '/viewer']);
                }
                this.events.publish('event:viewer', {
                    type: 'strong',
                    data: response['data']
                });
                return true;
            }
            this.global['result']['strong']['data'] = response['data'];
            this.events.publish('event:loadResultStrong');
        }).finally(() => {
            this.global['result']['strong']['loading'] = false;
        });
        return promise;
    }
}
