import {Injectable} from '@angular/core';
import { ResourceParams, ResourceAction, IResourceMethodPromise, IResourceMethod, ResourceRequestMethod } from '@ngx-resource/core'
import { BaseResources } from '@xplat-cgc/ionic/features/providers';

@Injectable()
@ResourceParams({
    pathPrefix: '/playerMusicRecommendation'
})
export class PlayerMusicRecommendationResource extends BaseResources {
  @ResourceAction({
    isArray: false,
    path: '/fromCache'
  })
  queryFromCache: IResourceMethod<any, any>;

  @ResourceAction({
    isArray: false,
    path: '/{!id}/list'
  })
  queryList: IResourceMethod<any, any>;

  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Post,
    path: '/favorite/add'
  })
  addFavoriteTrack: IResourceMethod<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/createPlaylist'
  })
  createPlaylist: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/getPlaylists'
  })
  getPlaylists: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/addToPlaylist'
  })
  addToPlaylist: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/removeFromPlaylist'
  })
  removeFormPlaylist: IResourceMethod<Object, Object>;

  @ResourceAction({
    isArray: false,
    path: '/getAllPlaylists'
  })
  getAllPlaylists: IResourceMethod<any, any>;


  @ResourceAction({
    path: '/updateOrderPlaylist',
    method: ResourceRequestMethod.Put
  })
  updateOrderPlaylist: IResourceMethod<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/{!id}'
  })
  patch: IResourceMethod<Object, Object>;
}
