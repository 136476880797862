<ion-header>
  <ion-toolbar color="light">
    <ion-title class="text-primary"
      ><strong>{{ 'SEARCH' | translate }}</strong>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="light"  *ngIf="global['searchType'] != 'bible' && global['searchType'] != 'strong'">
    <ion-searchbar
      animated
      placeholder="{{ 'Search' | translate }}"
      [value]="queryText['bible']"
      (keyup)="onKeyUpQuery($event)"
      (ionClear)="onClear()"
      [hidden]="global['searchType'] != 'bible'"
    ></ion-searchbar>
    <ion-searchbar
      animated
      placeholder="{{ 'Search' | translate }}"
      [value]="queryText['pdf']"
      (keyup)="onKeyUpQuery($event)"
      (ionClear)="onClear()"
      [hidden]="global['searchType'] != 'pdf'"
    ></ion-searchbar>
    <ion-searchbar
      animated
      placeholder="{{ 'Search' | translate }}"
      [value]="queryText['strong']"
      (keyup)="onKeyUpQuery($event)"
      (ionClear)="onClear()"
      [hidden]="global['searchType'] != 'strong'"
    ></ion-searchbar>
    <ion-searchbar
      animated
      placeholder="{{ 'Search' | translate }}"
      [value]="queryText['post']"
      (keyup)="onKeyUpQuery($event)"
      (ionClear)="onClear()"
      [hidden]="global['searchType'] != 'post'"
    ></ion-searchbar>
    <div class="mx-4"><span class="text-danger">*</span><small>{{'This Field is required' | translate}}</small></div>
  </ion-toolbar>
</ion-header>
<ion-content
  class="content-result-search bg-light bible"
  color="light"
  [hidden]="global['searchType'] != 'bible'"
>
  <app-search-bible></app-search-bible>
</ion-content>
<ion-content
  class="content-result-search bg-light pdf"
  color="light"
  [hidden]="global['searchType'] != 'pdf'"
>
  <app-search-post [type]="'pdf'"></app-search-post>
</ion-content>
<ion-content
  class="content-result-search bg-light strong"
  color="light"
  [hidden]="global['searchType'] != 'strong'"
>
  <app-search-strong></app-search-strong>
</ion-content>
<ion-content
  class="content-result-search bg-light post"
  color="light"
  [hidden]="global['searchType'] != 'post'"
>
  <app-search-post [type]="'post'"></app-search-post>
</ion-content>
<app-post-add
  *ngIf="!globalService.isPlanFree() &&
    global['user'] &&
    !global['mobile'] &&
    userPermissions.hasOwnProperty('create.notes')
  "
></app-post-add>
