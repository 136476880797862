import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ToastController, AlertController} from '@ionic/angular';

@Injectable()
export class ToastService {
    protected toastController;

    constructor(
        toastController: ToastController,
        protected alertController: AlertController
    ) {
        this.toastController = toastController;
    }

    async error(data) {
        const toast = await this.toastController.create({
            message: data['message'],

            animated: true,

            duration: data['duration'] || 3000,
            color: 'danger',
            position: 'top'
        });
        toast.present();
    }

    async showErrors(data) {
        const errors = _.map(data);
        const toast = await this.toastController.create({
            message: _.join(errors, '\n'),

            animated: true,

            duration: 10000,
            color: 'warning',
            position: 'top'
        });
        toast.present();
    }

    async success(data) {
        const toast = await this.toastController.create({
            message: data['message'],

            animated: true,

            duration: 3000,
            color: 'success',
            position: 'top'
        });
        toast.present();
    }
}
