<ion-header class="header-main">
    <!--ion-progress-bar color="light" type="indeterminate" reversed="true"></ion-progress-bar-->
    <ion-toolbar color="light">
        <div [ngSwitch]="global['searchType']">
            <ion-title *ngSwitchCase="'bible'">{{global['result']['bible']['title']}}</ion-title>
            <ion-title *ngSwitchCase="'strong'">{{global['result']['strong']['title']}}</ion-title>
            <ion-title *ngSwitchCase="'pdf'">{{global['result']['pdf']['title']}}</ion-title>
            <ion-title *ngSwitchCase="'post'">{{global['result']['post']['title']}}</ion-title>
        </div>

        <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>

        <ion-buttons slot="end" *ngIf="user">
            <ion-button  *ngIf="showHelpStron">
            <fa-icon
              (click)="presentModaHelpStrong()"
              [icon]="['fas', 'exclamation-triangle']"
              size="2x"
            ></fa-icon>
            </ion-button>
            <ion-button>
                <fa-icon class="text-primary" *ngIf="!user['photo']" [icon]="['fas', 'user-circle']"
                         size="2x"></fa-icon>
                <ion-avatar *ngIf="user['photo']" style="height: 32px;width: 32px;">
                    <img loading="lazy"src="{{environment.resources + user['photo'] }}"/>
                </ion-avatar>
            </ion-button>
            <ion-button (click)="presentPopover($event)">
                <fa-icon class="text-primary" size="2x" [icon]="['fas', 'bell']"></fa-icon>
            </ion-button>
        </ion-buttons>
        <!--ion-title>LIBRARY</ion-title-->
    </ion-toolbar>

</ion-header>
<ion-router-outlet></ion-router-outlet>
<ion-footer *ngIf="global['user'] && global['user']['subscription'] && global['user']['subscription']['status'] == 'paused'">
    <ion-toolbar color="danger">
        <ion-item color="danger">
            <ion-label>
                <div class="pt-2">
                    <strong>{{'Your subscription is deactivated' | translate}}</strong>
                </div>
            </ion-label>
            <ion-button (click)="onOpenMe()" color="light" tappable="">{{'Configurar' | translate}}</ion-button>
        </ion-item>
    </ion-toolbar>
</ion-footer>
