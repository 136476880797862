<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="tabs/study"></ion-back-button>
    </ion-buttons>
    <QCH-ion-header-buttons></QCH-ion-header-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-title class="text-white text-center"
      ><strong>{{ 'SEARCH' | translate }}</strong>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="medium" *ngIf="!(global['searchType'] == 'bible' || global['searchType'] == 'strong')">
    <ion-searchbar
      animated
      placeholder="{{ 'Search' | translate }}"
      (keyup)="onKeyUpQuery($event)"
      (ionClear)="onClear()"
      [clearIcon]="global['searchType'] == 'bible' ? 'book' : 'close'"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content
  class="content-result-search bg-light bible"
  color="light"
  [hidden]="global['searchType'] != 'bible'"
>
  <app-search-bible></app-search-bible>
</ion-content>
<ion-content
  class="content-result-search bg-light pdf"
  color="light"
  [hidden]="global['searchType'] != 'pdf'"
>
  <app-search-post [type]="'pdf'"></app-search-post>
</ion-content>
<ion-content
  class="content-result-search bg-light strong"
  color="light"
  [hidden]="global['searchType'] != 'strong'"
>
  <app-search-strong></app-search-strong>
</ion-content>
<ion-content
  class="content-result-search bg-light post"
  color="light"
  [hidden]="global['searchType'] != 'post'"
>
  <app-search-post [type]="'post'"></app-search-post>
</ion-content>
