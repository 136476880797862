import { environment } from './../../../core/environments/environment';
import { MusicResource } from './../../ui/pages/music/music-resource';
/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaObject, Media } from '@ionic-native/media/ngx';
import { Platform, LoadingController } from '@ionic/angular';
import {GlobalProvider} from '../../providers';
@Component({
  selector: 'app-musicplayer-a',
  templateUrl: './musicplayer.page.html',
  styleUrls: ['./musicplayer.page.scss'],
})
export class MusicplayerPage implements OnInit, OnDestroy {
  title: any;
  artist: any;
  image: string = 'assets/album_art.jpg';
  filename: any = 'Hotel California';
  playList: any[] = [];
  duration: any = -1;
  currPlayingFile: MediaObject;
  storageDirectory: any;
  @Input() playTheTrack;
  position: any = 0;
  getPositionInterval: any;
  isPlaying = false;
  isInPlay = false;
  isReady = false;
  getDurationInterval: any;
  displayPosition: any = '00:00';
  displayDuration: any = '00:00';
  progress: any = '00:00';
  lastPosition = 0;
  global = {}
  constructor(
    public platform: Platform,
    private media: Media,
    protected musicResource: MusicResource,
    protected route: ActivatedRoute,
    protected globalProvider: GlobalProvider
  ) {

  }
  ngOnInit() {
    if (this.playTheTrack) {
      this.prepareAudioFile();
    }
  }

  ionViewDidLeave() {
    this.currPlayingFile.release();
    this.currPlayingFile.stop();
  }

  ionViewDidEnter() {
    clearInterval(this.globalProvider['intervalMedia']);
    if (this.playTheTrack) {
      return true;
    }

    //this.media.resumeRecord();

    // comment out the following line when adjusting UI in browsers
    if (this.route.snapshot['params']['url']) {
      this.playTheTrack = this.route.snapshot['params']['url'];
      this.prepareAudioFile();
      return true;
    }
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.musicResource.get({
        id: id
      }).then((response) => {
        //this.playTheTrack = 'https://ia800406.us.archive.org/16/items/JM2013-10-05.flac16/V0/jm2013-10-05-t12-MP3-V0.mp3';
        this.playTheTrack = response['data']['url'];
        this.image = environment.resources + response['data']['image'];
        this.filename = response['data']['title'];
        this.prepareAudioFile();
      }).catch(() => {
      });
    }
  }

  prepareAudioFile() {
    this.platform.ready().then((res) => {
      //this.currPlayingFile = this.media.create(this.playTheTrack);
      // On occassions, the plugin only gives duration of the file if the file is played
      // at least once
      //this.currPlayingFile.play();
      this.getDuration();
    });
  }

  getDuration() {
    this.currPlayingFile = this.media.create(this.playTheTrack)
    this.currPlayingFile.play({ playAudioWhenScreenIsLocked : true });
    this.currPlayingFile.setVolume(0.0);  // you don't want users to notice that you are playing the file

    setTimeout(() => {
      this.duration = this.currPlayingFile.getDuration()
      this.currPlayingFile.stop()
      this.displayDuration = this.toHHMMSS(this.duration);
      this.progress = this.toHHMMSS(this.duration);
      this.setToPlayback();
    }, 3000)


    return false;
    this.currPlayingFile = this.media.create(this.playTheTrack);
    // On occassions, the plugin only gives duration of the file if the file is played
    // at least once
    this.currPlayingFile.play({ playAudioWhenScreenIsLocked : true });
    this.currPlayingFile.setVolume(0.0);  // you don't want users to notice that you are playing the file
    const self = this;
    // The plugin does not give the correct duration on playback start
    // need to check for duration repeatedly
    let tempDuration = self.duration;
    this.getDurationInterval = setInterval(() => {
      if (self.duration === -1 || !self.duration) {
        self.duration = ~~(self.currPlayingFile.getDuration());  // make it an integer
      } else {
        if (self.duration !== tempDuration) {
          tempDuration = self.duration;
        } else {
          self.currPlayingFile.stop();
          self.currPlayingFile.release();
          clearInterval(self.getDurationInterval);
          this.displayDuration = this.toHHMMSS(self.duration);
          this.progress = this.toHHMMSS(self.duration);
          self.setToPlayback();

        }
      }
    }, 100);
  }

  setToPlayback() {
    this.currPlayingFile = this.media.create(this.playTheTrack);
    this.currPlayingFile.onStatusUpdate.subscribe(status => {
      switch (status) {
        case 1:
          break;
        case 2:   // 2: playing
          this.isPlaying = true;
          break;
        case 3:   // 3: pause
          this.isPlaying = false;
          break;
        case 4:   // 4: stop
        default:
          this.isPlaying = false;
          break;
      }


      console.log('subscribe');
    });
    console.log('audio file set');
    this.isReady = true;
    this.getAndSetCurrentAudioPosition();
  }

  getAndSetCurrentAudioPosition() {
    const diff = 2;
    this.globalProvider['intervalMedia'] = setInterval(() => {
      this.currPlayingFile.getCurrentPosition().then((position) => {
        /*console.log(position);
        if (position >= 0 && position < this.duration) {
          if (Math.abs( this.lastPosition - position) >= diff) {
            // set position
            this.currPlayingFile.seekTo(this.position);
          }
        }*/
        this.position = position
        this.lastPosition = position;
        this.displayPosition = this.toHHMMSS(this.position);
        this.progress = this.toHHMMSS(this.duration - this.position);
      });
    }, 1000);
    return false;
    //const diff = 1;
    const self = this;
    this.getPositionInterval = setInterval(() => {
      const lastPosition = self.position;
      self.currPlayingFile.getCurrentPosition().then((position) => {
        if (position >= 0 && position < self.duration) {
          if (Math.abs(lastPosition - position) >= diff) {
            // set position
            self.currPlayingFile.seekTo(lastPosition * 1000);
          } else {
            // update position for display
            self.position = position;
            this.displayPosition = this.toHHMMSS(self.position);
            this.progress = this.toHHMMSS(this.duration - this.position);
          }
        } else if (position >= self.duration) {
          self.stop();
          //self.setToPlayback();
        }
      });
    }, 100);
  }

  currTimePosChanged(event) {

    if (Math.abs(this.lastPosition - parseInt(event['detail']['value'], 10)) >= 1) {
      this.currPlayingFile.seekTo(event['detail']['value'] * 1000);
    }

  }
  play() {
    // for(let songs of this.playList){
    //   songs.play();
    //   console.log(songs);
    // }
    this.currPlayingFile.play({ playAudioWhenScreenIsLocked : true });
  }

  pause() {
    this.currPlayingFile.pause();
    // for(let songs of this.playList){
    //   songs.pause();
    // }
  }

  stop() {
    // for(let songs of this.playList){
    //   songs.stop();
    //   songs.release();
    //   clearInterval(this.getPositionInterval);
    //   this.position = 0;
    // }
    this.currPlayingFile.stop();
    this.currPlayingFile.release();
    clearInterval(this.getPositionInterval);
    this.position = 0;
  }

  controlSeconds(action) {
    const step = 5;
    const numberRange = this.position;
    switch (action) {
      case 'back':
        this.position = numberRange < step ? 0.001 : numberRange - step;
        break;
      case 'forward':
        this.position = numberRange + step < this.duration ? numberRange + step : this.duration;
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    console.log(' ngOnDestroy called');
    this.stop();
  }

  toHHMMSS(secs) {
    const secNum = parseInt(secs, 10);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00' || i >= 0)
      .join(':');
  }
}
