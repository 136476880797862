<div *ngIf="track">

  <div class="" *ngIf="serviceProvider.trackCurrent && track['url_user'] == serviceProvider.trackCurrent['url_user']">
    <ion-range min="0" max="{{serviceProvider.duration}}" [(ngModel)]="serviceProvider.position"
               (ionChange)="serviceProvider.currTimePosChanged($event)" color="primary">
    </ion-range>
    <div class="rangePlaytime">
      <ion-label class="startTime" color="primary">{{ serviceProvider.DISPLAY_POSITION}}</ion-label>
      <ion-label class="endTime" color="primary" *ngIf="serviceProvider.duration == -1">--:--</ion-label>
      <ion-label class="endTime" color="primary"
                 *ngIf="serviceProvider.duration != -1">{{ serviceProvider.DISPLAY_DURATION }}</ion-label>
    </div>
  </div>
  <ion-grid class="p-0 m-0">
    <ion-row justify-content-center align-items-center style="height: 100%" class="p-0 m-0">
      <ion-col class="p-0 m-0">
        <div class=""
             *ngIf="!serviceProvider.trackCurrent || track['url_user'] !=  serviceProvider.trackCurrent['url_user']">
          <div class="d-flex justify-content-center">
            <ion-button class="play_pause_btn" (click)="serviceProvider.setTrack(track)" color="primary"
                        justify-content-center align-items-center>
              <ion-icon name="play" class=""></ion-icon>
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>


<div class="background" *ngIf="!track">
  <ion-header>
    <ion-toolbar mode="md">
      <ion-buttons slot="start">
        <ion-button (click)="goBack()" fill="clear" color="light">
          <fa-icon class="text-white" [icon]="['fas', 'angle-left']"
                   size="2x"></fa-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button (click)="openOptionsModal()" fill="clear" color="light">
          <ion-icon name="ellipsis-vertical-outline" expand="icon-only">
          </ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="fixedContent">
      <ion-thumbnail
        [ngStyle]="{'background':'url('+ environment.resources + serviceProvider.trackCurrent['image'] + ')'}"></ion-thumbnail>
    </div>


    <div class="">
      <div class="px-3 text-white">
        <h5 class="font-weight-bold mb-0"> {{serviceProvider.trackCurrent['title']}}</h5>
        <h5 class="mt-2"> {{serviceProvider.trackCurrent['artist']}}</h5>
      </div>
      <ion-range min="0" max="{{serviceProvider.duration}}" [(ngModel)]="serviceProvider.position"
                 (ionChange)="serviceProvider.currTimePosChanged($event)" color="light">
      </ion-range>
      <div class="rangePlaytime">
        <ion-label class="startTime" color="light">{{ serviceProvider.DISPLAY_POSITION}}</ion-label>
        <ion-label class="endTime" color="light" *ngIf="serviceProvider.duration == -1">--:--</ion-label>
        <ion-label class="endTime" color="light"
                   *ngIf="serviceProvider.duration != -1">{{ serviceProvider.DISPLAY_DURATION }}</ion-label>
      </div>

    </div>

    <ion-grid>
      <ion-row justify-content-center align-items-center style="height: 100%">
        <ion-col>
          <div class="flexRow">
            <div>
              <ion-button fill="clear" color="light" (click)="serviceProvider.togRandom = !serviceProvider.togRandom">
                <ion-icon mode="ios" name="shuffle" *ngIf="!serviceProvider.togRandom"
                          class="Iconsize_For_Rev"></ion-icon>
                <ion-icon mode="ios" name="shuffle" *ngIf="serviceProvider.togRandom" color="success"
                          class="Iconsize_For_Rev"></ion-icon>
              </ion-button>
            </div>

            <div>
              <ion-button [disabled]="serviceProvider.trackHistoryCurrent == 1" fill="clear" color="light"
                          (click)="serviceProvider.previousTrack()">
                <ion-icon class="Iconsize_For_Rev" name="play-skip-back"></ion-icon>
              </ion-button>
            </div>

            <div>
              <ion-button class="play_pause_btn" (click)="serviceProvider.play()" color="light"
                          *ngIf="!serviceProvider.is_playing"
                          [disabled]="!serviceProvider.isInPlay" justify-content-center align-items-center>
                <ion-spinner *ngIf="!serviceProvider.isInPlay" class="darkColor" color="primary"
                             name="lines"></ion-spinner>
                <ion-icon *ngIf="serviceProvider.isInPlay" name="play" class="darkColor"></ion-icon>
              </ion-button>

              <ion-button class="play_pause_btn" (click)="serviceProvider.pause(false)" color="light"
                          *ngIf="serviceProvider.is_playing"
                          [disabled]="!serviceProvider.is_ready" justify-content-center align-items-center>
                <ion-icon name="pause" class="darkColor"></ion-icon>
              </ion-button>
            </div>

            <div>
              <ion-button [disabled]="serviceProvider.trackList.length <= 1" fill="clear" color="light"
                          (click)="serviceProvider.nextTrack()">
                <ion-icon name="play-skip-forward-outline" class="Iconsize_For_Rev"></ion-icon>
              </ion-button>
            </div>

            <div>
              <ion-button fill="clear" color="light" (click)="serviceProvider.onToggleRepeat()">
                <ion-badge color="danger" class="repeat" *ngIf="serviceProvider.togRepeat == 1">1</ion-badge>
                <br>
                <ion-icon mode="ios" name="repeat" *ngIf="!serviceProvider.togRepeat"
                          class="Iconsize_For_Rev"></ion-icon>
                <ion-icon mode="ios" name="repeat" *ngIf="serviceProvider.togRepeat" color="danger"
                          class="Iconsize_For_Rev">
                </ion-icon>
              </ion-button>


            </div>
          </div>
          <div class="flexRow" *ngIf="(track$ | async) as track">
            <div>
              <ion-button [routerLink]="['/player/tabs/queue']" fill="clear">
                <ion-icon name="list" color="medium"></ion-icon>

              </ion-button>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div>
              <ion-button (click)="onToFavorite(track)" fill="clear">
                <fa-icon [icon]="['fas', 'star-of-david']" size="lg" *ngIf="!track.favorite"
                         style="color: var(--ion-color-medium)"></fa-icon>
                <fa-icon [icon]="['fas', 'star-of-david']" size="lg" *ngIf="track.favorite"
                         style="color: var(--ion-color-success)"></fa-icon>
              </ion-button>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
