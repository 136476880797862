import {Injectable, OnInit} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class BaseListComponent implements OnInit {
    public list = null;
    public pagination;
    public wheres = {};
    public promise = null;
    protected resource;
    public eventLoading;
    public loading = false;
    public keyStorage = '';
    protected globalProvider = {};

    constructor() {
        this.pagination = {
            total: 1,
            page: 1,
            limit: 25,
            limits: [25, 50, 100],
            active: 1,
            inactive: 0,
            order: '-id'
        };
    }

    onQuery(event, promise = true) {

        if (this.list === null) {
            this.list = [];
        }
        this.eventLoading = event;
        if (this.keyStorage) {
            this.globalProvider['global'][this.keyStorage] = [];
        }
        const params = _.assign(
            {
                _page: this.pagination.page++,
                _limit: this.pagination.limit,
                _active: this.pagination.active,
                _inactive: this.pagination.inactive,
                _sortJoin: this.pagination.order
            },
            this.wheres
        );
        this.resource.loading = !event;
        this.loading = true;
        this.promise = this.resource.query({}, params);
        if (promise) {
            this.promise.then(data => {
                if (this.keyStorage) {
                    this.globalProvider['global'][this.keyStorage] = data['data']['data'];
                }
                _.forEach(data['data']['data'], value => {
                    this.list.push(value);
                });
                this.pagination = _.assign(
                    this.pagination,
                    _.omit(data['data'], ['data'])
                );
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            });
        }

        this.promise.finally(() => {
            if (event) {
                event.target.complete();
                this.eventLoading = null;
            }
        });
        return this.promise;
    }

    onDelete(row) {
        /*this.swalCustomService.deleteConfirm().then(() => {
                row.remove().then(() => {
                    row.deleted_at = true;
                    this.swalCustomService.deleteSuccess();
                });
            });*/
    }

    onRestore(row) {
        /*this.swalCustomService.restoreConfirm().then(() => {
                this.resource.one(row.id).customPUT(null, 'restore').then((response) => {
                    row.deleted_at = false;
                    this.swalCustomService.restoreSuccess();
                });
            });*/
    }

    onClearWhere() {
        this.wheres = {};
        this.onQuery(null);
    }

    onWhere(wheres) {
        this.wheres = _.assign(this.wheres, wheres);
        this.onQuery(null);
    }

    ngOnInit() {
        this.list = [];
        this.promise = null;
        this.onQuery(null);
    }
}
