import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/checkout/payment'
})
export class CheckoutPaymentResource extends BaseResources {
    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/'
    })
    delete: IResourceMethod<{ id: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/restore'
    })
    restore: IResourceMethod<{ id: any }, any>;

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/me'
    })
    payment: IResourceMethod<{ id: any }, any>;
}
