import { environment } from '@xplat-cgc/ionic/core/environments/environment'
/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, Input, OnInit } from '@angular/core'
import { AlertController, ModalController, NavParams } from '@ionic/angular'
import { SpotifyService } from '../spotify.service'
import { Router } from '@angular/router'
import { StatusBar } from '@capacitor/status-bar'
import { State, Store } from '@ngrx/store'
import { ITrack, ITrackDownload, PlayerState } from '../../state/interfaces/player-state.interface'
import { selectPlayerCurrentTrackInfo, selectPlayerDownloads, selectPlayerQueue } from '../../state/selectors/player.selector'
import { Observable, Subject } from 'rxjs'
import {
  addPlayerDownload,
  deletePlayerDownload,
  setPlayerCurrentTrackInfo,
  updatePlayerCurrentTrackInfo
} from '../../state/actions/player.actions'
import { MusicListService } from '../music-list/music-list.service'
import { Track } from '@xplat-cgc/ionic/features/modules/player/model/track.model'
import { takeUntil } from 'rxjs/operators'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { GlobalService } from '@xplat-cgc/ionic/features/providers/services/global-service'
import { TranslateService } from '@ngx-translate/core'
import { Directory, Filesystem } from '@capacitor/filesystem'


@Component({
  selector: 'app-songsactionmodal',
  templateUrl: './songsactionmodal.component.html',
  styleUrls: ['./songsactionmodal.component.scss']
})
export class SongsactionmodalComponent implements OnInit {
  @Input() value: any
  environment = environment
  modalData: any
  download: boolean = false
  clickedHeart: any
  album = '../../assets/album.jfif'
  data: any

  track$: Observable<ITrack>
  queue$: Observable<ITrack[]>
  queue: { [key: string]: Track } = {}
  downloads: { [key: string]: Track } = {}
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  public global: any = {};
  userPermissionsApp = [];

  constructor(
    public navParam: NavParams,
    public serviceProvider: SpotifyService,
    public modalCtrl: ModalController,
    public router: Router,
    protected _store: Store<{ player: PlayerState }>,
    private _state: State<{ player: PlayerState }>,
    public globalProvider: GlobalProvider,
    public globalService: GlobalService,
    public musicListService: MusicListService,
    private _translate: TranslateService,
    protected alertController: AlertController,

  ) {
    this.global = this.globalProvider?.global;
    this.queue$ = this._store.select(selectPlayerQueue)
    this.track$ = this._store.select(selectPlayerCurrentTrackInfo)
    this.modalData = this.navParam.get('value')

    const user = this.globalProvider?.global?.user
    if (user?.abilities?.front_permissions) {
      this.userPermissionsApp = user?.abilities?.front_permissions;
    }

  }

  ionViewWillLeave() {
    StatusBar.setBackgroundColor({
      color: '#01106E'
    })
  }

  ionViewWillEnter() {
    StatusBar.setBackgroundColor({
      color: '#34408B'
    })
  }

  ngOnInit() {
    this.queue$.pipe(takeUntil(this._unsubscribeAll)).subscribe((queue) => {
      setTimeout(() => {
        if (this._state.getValue()?.player?.queue?.entities) {
          this.queue = this._state.getValue()?.player?.queue?.entities
        }
      }, 400)
    })

    this._store.select(selectPlayerDownloads).pipe(takeUntil(this._unsubscribeAll)).subscribe((queue) => {
      setTimeout(() => {
        if (this._state.getValue()?.player?.downloads?.entities) {
          this.downloads = this._state.getValue()?.player?.downloads?.entities
        }
      }, 400)
    })

  }

  closeModal(params: Record<any, any> = {}) {
    this.modalCtrl.dismiss(params)
  }

  async goForActions(list: any, track?: ITrack | ITrackDownload | null) {
    switch (list?.type) {

      case 'removeplaylist':
        this.musicListService.removeTrackFromPlaylist(track, track?.player_music_recommendation).then
        ((data) => {
          this.closeModal({
            action: 'removeplaylist'
          })
        }).catch((err) => {
          this.closeModal()
        })
        break

      case 'playlist':
        if (!this.userPermissionsApp['playlist.musicAccess']) {
          this.globalService.showModalPermission()
          return
        }
        this._store.dispatch(updatePlayerCurrentTrackInfo({
          track: {
            addPlaylist: true
          }
        }))
        //addPlaylist
        this.closeModal()
        this.router.navigate(['/player/tabs/library'])
        break
      case 'add':
        const current = this.queue[track.id]
        const translate = await this._translate
          .get([
            'Song hidden in the list',
            'Song added in the list'
          ])
          .toPromise();
        if (current) {
          this.musicListService.removeQueue(track)
          const toast = await this.serviceProvider.showToast(translate['Song hidden in the list'])
          toast.present()
        } else if (!current) {
          this.musicListService.addQueue(track)
          const toast = await this.serviceProvider.showToast(translate['Song added in the list'])
          toast.present()
        }

        break
      case 'queue':
        this.router.navigate(['/player/tabs/queue'])
        this.closeModal()
        break
      case 'download':
        if (!this.userPermissionsApp['download.musicAccess']) {
          this.globalService.showModalPermission()
          return
        }
        this.downloadTrack(track)
        this.closeModal()
        break
      case 'removedownloads':
        console.log(track)
        this._translate
          .get(['Remove ', 'Cancel', 'Ok', 'Confirm'], {})
          .subscribe(async (res: string) => {
            const alert = await this.alertController.create({
              header: res['Confirm'] + '!',
              message: res['Remove '],
              buttons: [
                {
                  text: res['Cancel'],
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
                },
                {
                  text: res['Ok'],
                  handler:  async () => {
                    console.log('asdasdasdasdasd')
                    console.log(track)
                    if ('fileName' in track) {
                      await Filesystem.deleteFile({
                        path: `cgc-music/${track?.fileName}`,
                        directory: Directory.Data
                      }).then(e => {
                        this._store.dispatch(deletePlayerDownload({ id: track.id }))
                      }).catch(e => {
                        this._store.dispatch(deletePlayerDownload({ id: track.id }))
                      })
                    }
                    this.closeModal({
                      action: list?.type
                    })
                  }
                }
              ]
            })
            await alert.present()
          })


        break
    }
  }

  async onToFavorite(track: ITrack) {
    if (!this.userPermissionsApp['favorite.musicAccess']) {
      this.globalService.showModalPermission()
      return
    }
    this.musicListService.toFavorite(track).then((data) => {
      this._store.dispatch(setPlayerCurrentTrackInfo({ track: { ...track, favorite: data?.data } }))
    })
  }

  downloadTrack(track: ITrack) {
    this._store.dispatch(addPlayerDownload({
      download: {
        ...track,
        status: 'added'
      }
    }))
    this.router.navigate(['/player/tabs/download'])
  }

}
