import { LiveResource } from '@xplat-cgc/ionic/features/ui/pages/live/live-resource';
import {
  APP_INITIALIZER,
  NgModule,
  ErrorHandler,
  Injectable,
  isDevMode
} from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseResources } from '@xplat-cgc/ionic/features/providers/resources/base-resources';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { IonicStorageModule } from '@ionic/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy
} from '@angular/common';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider';
import { GlobalService } from '@xplat-cgc/ionic/features/providers/services/global-service';
import { AuthResource } from '@xplat-cgc/ionic/features/providers/resources/auth-resource';
import * as Sentry from '@sentry/browser';
import { BibleResource } from '@xplat-cgc/ionic/features/providers/resources/bible-resource.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SearchService } from '@xplat-cgc/ionic/features/ui/pages/search/search.service';
import { QuillModule } from 'ngx-quill';
import { PostResource } from '@xplat-cgc/ionic/features/ui/pages/post/post-resource.service';
import { NoteFolderResource } from '@xplat-cgc/ionic/features/ui/pages/note-folder/note-folder-resource.service';
import { NgxLoadingModule } from 'ngx-loading';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import {
  faAngleRight,
  faArrowLeft,
  faBible,
  faBookReader,
  faCaretSquareRight,
  faChevronRight,
  faChevronUp,
  faChurch,
  faExchangeAlt,
  faFolderOpen,
  faPenFancy,
  faSpinner,
  faStickyNote,
  faSyncAlt,
  faTrashAlt,
  faUserCircle,
  faExclamationTriangle,
  faHandsHelping,
  faAngleDown,
  faBroadcastTower,
  faUsers,
  faShareAlt,
  faTasks,
  faCalendarAlt,
  faSlash,
  faSignInAlt,
  faFrownOpen, faStarOfDavid, fas
} from '@fortawesome/free-solid-svg-icons'
import { faAudible } from '@fortawesome/free-brands-svg-icons';
// -- import {LangComponent} from './components/lang/lang.component';
// -- import {ServiceWorkerModule} from '@angular/service-worker';
import { ToastService } from '@xplat-cgc/ionic/features/providers/services/toast-service';
import { UserResource } from '@xplat-cgc/ionic/features/ui/pages/auth/user-resource';
import { StrongResource } from '@xplat-cgc/ionic/features/providers/resources/strong-resource.service';
import { File } from '@ionic-native/file/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { FileUploadModule } from 'ng2-file-upload';
import { NotificationUserResource } from '@xplat-cgc/ionic/features/ui/pages/notification/notification-user.resource';
import { environment } from '@xplat-cgc/ionic/core/environments/environment';
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';
import { LayoutPageModule } from './layout/layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons/faCaretSquareLeft';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initApp } from './app.init';
import { NgxMaskModule } from 'ngx-mask';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { CanActivateViaAuthGuard } from '@xplat-cgc/ionic/features/providers/CanActivateViaAuthGuard';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BaseCopyResources } from '@xplat-cgc/ionic/features/providers/resources/base-copy-resources';
import { PlanModule } from '@xplat-cgc/ionic/features/ui/components/plan/plan.module'
import { playerReducer } from '@xplat-cgc/player/state/reducers/player.reducer'
import { PlayerEffects } from '@xplat-cgc/player/state/effects/player.effect'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AudioStatisticService } from '@xplat-cgc/player/app/audio-statistics.service'
import { AudioStatisticsResource } from '@xplat-cgc/ionic'
import { MusicControlsService } from '@xplat-cgc/player/app/musicControls.service'
import { MusicControls } from '@ionic-native/music-controls/ngx'
import { SpotifyService } from '@xplat-cgc/player/app/spotify.service'
import { Media } from '@ionic-native/media/ngx'
import { StreamingMedia } from '@ionic-native/streaming-media/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faMountain } from '@fortawesome/free-solid-svg-icons/faMountain'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { SongsactionmodalModule } from '@xplat-cgc/player/app/songsactionmodal/songsactionmodal.module'
import { PermissionModalModule } from '@xplat-cgc/ionic/features/ui/pages/permission-modal/permission-modal.module'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
if (environment.production) {
Sentry.init({
  dsn: 'https://d8eeaf22edd34df89f7cac8cbdb1c575@sentry.io/1513974'
});

}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {
    console.error(error);
    if (environment.production) {
      try {
        Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({eventId});
      } catch (e) {
        console.error(e);
      }
    }
  }
}

@NgModule({
  imports: [
    NgxExtendedPdfViewerModule,
    PlanModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({
      mode: 'md', // 'md' | 'ios' | 'wp',
      animated: true
    }),
    AppRoutingModule,
    ResourceModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoadingBarHttpClientModule,
    QuillModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    FontAwesomeModule,
    FileUploadModule,
    LayoutPageModule,
    NgxMaskModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    StoreModule.forRoot({
      player: playerReducer
    }, {}),
    EffectsModule.forRoot([PlayerEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    SongsactionmodalModule,
    PermissionModalModule
  ],
  exports: [
    NgxExtendedPdfViewerModule
  ],
  providers: [
    InAppBrowser,
    SpotifyService,
    MusicControls,
    Media,
    StreamingMedia,
    AudioStatisticService,
    MusicControlsService,
    AudioStatisticsResource,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    BaseResources,
    BaseCopyResources,
    AuthResource,
    GlobalService,
    UserResource,
    GlobalProvider,
    NotificationUserResource,
    BibleResource,
    StrongResource,
    PostResource,
    NoteFolderResource,
    SearchService,
    ToastService,
    File,
    Crop,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [GlobalProvider],
      multi: true
    },
    CanActivateViaAuthGuard,
    Keyboard,
    AppVersion,
    LiveResource,
    Events
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas)
    // Add an icon to the library for convenient access in other components
    library.addIcons(faAudible)
    library.addIcons(faBible)
    library.addIcons(faBookReader)
    library.addIcons(faSpinner)
    library.addIcons(faChurch)
    library.addIcons(faAngleRight)
    library.addIcons(faTimes)
    library.addIcons(faCompass)
    library.addIcons(faQuoteRight)
    library.addIcons(faBookReader)
    library.addIcons(faFolderOpen)
    library.addIcons(faStickyNote)
    library.addIcons(faUserCircle)
    library.addIcons(faTrashAlt)
    library.addIcons(faChevronUp)
    library.addIcons(faChevronRight)
    library.addIcons(faCaretSquareLeft)
    library.addIcons(faCaretSquareRight)
    library.addIcons(faSignOutAlt)
    library.addIcons(faBook)
    library.addIcons(faPowerOff)
    library.addIcons(faRedoAlt)
    library.addIcons(faCreditCard)
    library.addIcons(faArrowLeft)
    library.addIcons(faExchangeAlt)
    library.addIcons(faSyncAlt)
    library.addIcons(faExclamationTriangle)
    library.addIcons(faFolderOpen)
    library.addIcons(faBell)
    library.addIcons(faSave)
    library.addIcons(faAudible)
    library.addIcons(faHome)
    library.addIcons(faHandsHelping)
    library.addIcons(faBible)
    library.addIcons(faBookReader)
    library.addIcons(faSpinner)
    library.addIcons(faChurch)
    library.addIcons(faAngleRight)
    library.addIcons(faAngleDown)
    library.addIcons(faPenFancy)
    library.addIcons(faCompass)
    library.addIcons(faQuoteRight)
    library.addIcons(faBookReader)
    library.addIcons(faFolderOpen)
    library.addIcons(faStickyNote)
    library.addIcons(faUserCircle)
    library.addIcons(faTrashAlt)
    library.addIcons(faChevronUp)
    library.addIcons(faChevronRight)
    library.addIcons(faCaretSquareLeft)
    library.addIcons(faCaretSquareRight)
    library.addIcons(faSignOutAlt)
    library.addIcons(faBook)
    library.addIcons(faRedoAlt)
    library.addIcons(faBroadcastTower)
    library.addIcons(faUsers)
    library.addIcons(faShareAlt)
    library.addIcons(faTasks)
    library.addIcons(faCalendarAlt)
    library.addIcons(faBell)
    library.addIcons(faSlash)
    library.addIcons(faPowerOff)
    library.addIcons(faSignInAlt)
    library.addIcons(faMountain)
    library.addIcons(faHandsHelping)
    library.addIcons(faChevronRight)
    library.addIcons(faChevronLeft)
    library.addIcons(faFacebookSquare)
    library.addIcons(faTwitterSquare)
    library.addIcons(faInstagram)
    library.addIcons(faClock)
    library.addIcons(faCalendar)
    library.addIcons(faExclamation)
    library.addIcons(faBroadcastTower)
    library.addIcons(faMusic)
    library.addIcons(faPenFancy)
    library.addIcons(faEnvelopeOpenText)
    library.addIcons(faShoppingCart)
    library.addIcons(faFrownOpen)
    library.addIcons(faExclamationTriangle)
    library.addIcons(faStarOfDavid)
  }
}
