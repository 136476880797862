/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter);
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { BehaviorSubject } from 'rxjs'
import { MusicControls } from '@ionic-native/music-controls/ngx'
import { Plugins } from '@capacitor/core'

const { CapacitorMusicControls } = Plugins;

class IElapsed {
  elapsed: number;
  isPlaying: boolean
}

@Injectable({
  providedIn: 'root'
})
export class MusicControlsService {

  public events = new BehaviorSubject<any>('');
  events$ = this.events.asObservable();

  constructor(
    public musicControls: MusicControls,
    public platform: Platform
  ) {
    if (this.platform.is('ios')) {
      CapacitorMusicControls?.addListener(
        'controlsNotification',
        (action: any) => {
          this.events.next(action)
        }
      );
    } else if (this.platform.is('android')) {
      this.musicControls.subscribe().subscribe(action => {
        this.events.next(JSON.parse(action))
      })
      this.musicControls.listen();
    }
    /*CapacitorMusicControls.subscribe().subscribe(action => {
    const message = JSON.parse(action).message;*/
  }

  create(options) {
    // CapacitorMusicControls.destroy(); // it's the same with or without the destroy
    if (this.platform.is('ios')) {
      CapacitorMusicControls?.create(options);
    } else if (this.platform.is('android')) {
      this.musicControls.create(options).catch(
        (error) => {
          console.log('createMusicControls.error',error);
        }
      )
        .then(
          (value) => {
            console.log('createMusicControls.value', value);
          }
        );;
    }
  }

  updateElapsed(options:IElapsed) {
    if (this.platform.is('ios')) {
      CapacitorMusicControls.updateElapsed(options);
    } else if (this.platform.is('android')) {
      this.musicControls.updateElapsed(options);
    }
  }

  updateIsPlaying(val) {
    if (this.platform.is('ios')) {
      CapacitorMusicControls?.updateIsPlaying(val || false);
    } else if (this.platform.is('android')) {
      this.musicControls.updateIsPlaying(val || false);
    }
  }

  destroy() {
    if (this.platform.is('ios')) {
      CapacitorMusicControls.destroy();
    } else if (this.platform.is('android')) {
      this.musicControls.destroy();
    }
  }

  updateDuration(duration) {
    if (this.platform.is('ios')) {
      CapacitorMusicControls.updateDuration(duration);
    } else if (this.platform.is('android')) {
      //this.musicControls.destroy();
    }
  }

}



