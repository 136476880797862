import { PlanComponent } from './../../ui/components/plan/plan.component';
import {Injectable} from '@angular/core';
import {
    Resource,
    ResourceAction,
    IResourceMethod,
    ResourceRequestMethod,
    ResourceHandler,
    IResourceActionInner,
    IResourceResponse,
    ResourceParams,
    ResourceGlobalConfig, ResourceActionReturnType
} from '@ngx-resource/core';
import {UserData} from '../user-data';
import {
    LoadingController,
    NavController,
    ToastController,
    Platform,
    ModalController
} from '@ionic/angular';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../core/environments/environment';
import {File} from '@ionic-native/file/ngx';
import {GlobalProvider} from '../global-provider';

ResourceGlobalConfig.url = environment.api;
ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;

@Injectable()
@ResourceParams({
  pathPrefix: '',
  returnAs: ResourceActionReturnType.Promise
})
export class BaseResources extends Resource {
  user: Object;
  loadingActive;
  loading = true;
  auth = true;
  global = {}

  @ResourceAction({
    method: ResourceRequestMethod.Options,
    path: '/',
    pathPrefix: '',
    auth: false
  })
  options: IResourceMethod<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/selects',
    auth: false
  })
  selects: IResourceMethod<any, any>;

  @ResourceAction({
    isArray: false,
    path: '/'
  })
  query: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/{!id}'
  })
  get: IResourceMethod<any, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post
  })
  store: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/sendEmail'
  })
  sendEmail: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}'
  })
  update: IResourceMethod<Object, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}'
  })
  delete: IResourceMethod<{ id: any }, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}/restore'
  })
  restore: IResourceMethod<{ id: any }, any>;

  constructor(
    handler: ResourceHandler,
    private userData: UserData,
    public loadingController: LoadingController,
    private router: Router,
    public storage: Storage,
    public toastController: ToastController,
    protected navController: NavController,
    protected translate: TranslateService,
    protected file: File,
    private _platform: Platform,
    private modalController: ModalController,
    private globaProvider: GlobalProvider
  ) {
    super(handler);
    this.global = this.globaProvider.global
  }

  async toLogin() {
    await this.userData.logout();
    this.router.navigateByUrl('/auth/login');
    /*const toast = await this.toastController.create({
                message: 'Su sesion ha terminado',


                duration: 2000,
                color: 'warning'
            });
            toast.present();*/
  }

  async noConexion() {
    const toast = await this.toastController.create({
      message: 'Ha ocurrido un error, debe contar con conexion a internet',

      animated: true,

      duration: 3000,
      color: 'danger'
    });
    toast.present();
  }


  async presentLoading() {
    if (this.loading) {
      this.loadingActive = await this.loadingController.create({
        spinner: 'dots',
        message: 'Cargando',
        duration: 10000
      });
      return await this.loadingActive.present();
    }
    return true;
  }

  async dismissLoading() {
    if (this.loadingActive && this.loading) {
      return await this.loadingActive.dismiss();
    }
  }
  async changePlan() {
    if (this.global['openModalPlan']) {
      return false;
    }
    this.global['openModalPlan'] = true
    const modal = await this.modalController.create({
      component: PlanComponent,
      backdropDismiss: false,
      componentProps: {
        //user: this.globalProvider.global['user'],
        close: true
      }
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    this.global['openModalPlan'] = false
  }

  async removeData() {
    try {
      this.file
      .removeDir(this.file.dataDirectory, 'cgc')
      .then(resCreate => {
        this.storage.remove('listDownloaded').then(() => {});
      })
      .catch(() => {
        this.storage.remove('listDownloaded').then(() => {});
      });
    } catch(e) {
      console.log('remove files error')
    }

  }

  $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse) {
    this.dismissLoading();
    if (resp['status'] === 401 && options['actionOptions']['auth'] !== false) {
      if (this.global['mobile']) {
        this.router.navigate(['auth/login']);
      } else {
        this.router.navigate(['auth/signup']);
      }
      //return this.toLogin();
      return;
    }

    if (
      resp.status === 402
      //&& resp['body']['data']['status'] === 'pending'
    ) {
      this.removeData();
      if (this.global['mobile']) {
        this.router.navigate(['auth/login']);
      } else {
        this.changePlan();
      }
    }
    if (resp.status === 500) {
      this.translate
        .get('Your payment was declined', {})
        .subscribe(async (res: string) => {
          const toast = await this.toastController.create({
            message: environment.production ? 'Error' : resp.body['message'],

            animated: true,

            duration: 5000,
            color: 'danger'
          });
          toast.present();
        });
    }
    if (resp.status >= 400 && resp.status < 500) {
      return resp;
    }
    if (options.returnData && this.$_canSetInternalData(options)) {
      options.returnData.$resolved = true;
    }
    if (options.actionAttributes && options.actionAttributes.onError) {
      options.actionAttributes.onError(resp);
    }
    throw resp;
  }

  $getHeaders(methodOptions: any) {
    //this.presentLoading();
    const headers = _.assign(super.$getHeaders(), methodOptions.headers);
    // Extending our headers with Authorization
    delete headers['Authorization'];
    //headers['withCredentials'] = true;
    headers['Language'] = window.localStorage['lang'] || 'en';
    headers['App'] = 'yes';
    const token = window.localStorage['token'];
    headers['Authorization'] = 'Bearer ' + token;

    return headers;
  }
}
