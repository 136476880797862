<ngx-loading-bar color="#009688"></ngx-loading-bar>
<ion-app>
    <ion-split-pane (ionSplitPaneVisible)="calculateWithMenu(500)" contentId="main-content">
        <ion-menu (ionWillOpen)="calculateWithMenu(500)" (ionWillClose)="calculateWithMenu(500)" contentId="main-content">
            <div class="m-0 d-flex flex-row" [ngClass]="{'menu-mini' : menuMini}">
                <div #menuElement class=" bg-primary text-white p-0  menu height-full">
                    <div class="logo text-center">
                        <img loading="lazy"src="assets/img/logo_white.png">
                    </div>
                    <ion-list lines="none" class="height-full ion-no-padding" *ngIf="menuMini">
                        <ion-item *ngFor="let page of appPages" tappable (click)="onSelectSection(page.section)"
                                  [ngClass]="{'check' : global['searchType'] == page.section}"
                                  [routerDirection]="'root'"
                                  [routerLink]="[page.url]">
                            <fa-icon slot="start" [icon]="page.icon" class="text-white"></fa-icon>
                        </ion-item>
                        <ion-item tappable (click)="onOpen('/note/list')" class="pt-5" *ngIf="userPermissions.hasOwnProperty('create.notes')">
                            <fa-icon slot="start" [icon]="['fas', 'book']" class="text-white"></fa-icon>

                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'Quick Notes' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                        <ion-item tappable (click)="onOpen('/auth/me')" [ngClass]="!userPermissions.hasOwnProperty('create.notes') ? 'pt-5' : ''">
                            <fa-icon slot="start" [icon]="['fas', 'user-circle']" class="text-white"></fa-icon>
                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'My account' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                    <ion-list lines="none" class="height-full ion-no-padding" *ngIf="!menuMini">
                        <ion-item *ngFor="let page of appPages" tappable (click)="onSelectSection(page.section)"
                                  [ngClass]="{'check' : global['searchType'] == page.section}"
                                  [routerDirection]="'root'"
                                  [routerLink]="[page.url]">
                            <fa-icon slot="start" [icon]="page.icon" class="text-white"></fa-icon>
                            <ion-label class="text-white">
                                {{page.title | translate}}
                              <p>
                                <small class="text-white" *ngIf="page.sub">{{page.sub}}</small>
                              </p>
                            </ion-label>


                            <fa-icon slot="end" [icon]="['fas', 'angle-right']" class="text-white"></fa-icon>
                        </ion-item>
                        <ion-item tappable (click)="onOpen('/note/list')" class="pt-4" *ngIf="userPermissions.hasOwnProperty('create.notes')">
                            <fa-icon slot="start" [icon]="['fas', 'book']" class="text-white"></fa-icon>

                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'My Quick Notes' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                      <ion-item tappable (click)="onSelectSection('live')"
                                [routerDirection]="'root'"
                                [routerLink]="['live']">
                        <fa-icon
                          slot="start"
                          [icon]="['fas', 'play']"
                          class="text-white"
                        ></fa-icon>
                        <ion-label class="text-white" *ngIf="!menuMini">
                          <strong>{{'CGC Live' | translate}}</strong>
                        </ion-label>
                        <fa-icon slot="end" *ngIf="global['config']['liveStream']"
                                 class="text-red"
                                 [icon]="['fas', 'circle']"
                        ></fa-icon>
                      </ion-item>
                        <ion-item tappable (click)="onOpen('/auth/me')" [ngClass]="!userPermissions.hasOwnProperty('create.notes') ? 'pt-5' : ''">
                            <fa-icon slot="start" [icon]="['fas', 'user-circle']" class="text-white"></fa-icon>
                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'My account' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                    </ion-list>

                    <ion-list class="no-padding" lines="none" style="position: absolute; bottom: 20px">
                        <ion-item *ngIf="!menuMini">
                            <div class="row">
                                <div class="col-6">
                                    <img loading="lazy"src="assets/img/usa.png" width="32" tappable="" (click)="onChangeLang('en')">
                                </div>
                                <div class="col-6">
                                    <img loading="lazy"src="assets/img/mx.png" width="32" tappable="" (click)="onChangeLang('es')">
                                </div>
                            </div>
                        </ion-item>
                        <ion-item *ngIf="menuMini" class="text-center ion-no-padding ion-no-margin">
                            <img loading="lazy"src="assets/img/usa.png" width="32" tappable="" (click)="onChangeLang('en')" class="pl-2">
                        </ion-item>
                        <ion-item *ngIf="menuMini" class="text-center ion-no-padding ion-no-margin">
                            <img loading="lazy"src="assets/img/mx.png" width="32" tappable="" (click)="onChangeLang('es')" class="pl-2">
                        </ion-item>
                        <ion-item tappable (click)="onCollapse()">
                            <fa-icon slot="start" [icon]="['fas', 'caret-square-right']" class="text-white"
                                     *ngIf="menuMini"></fa-icon>
                            <fa-icon slot="start" [icon]="['fas', 'caret-square-left']" class="text-white"
                                     *ngIf="!menuMini"></fa-icon>
                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'Hide' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                        <ion-item tappable (click)="onLogout()">
                            <fa-icon slot="start" [icon]="['fas', 'power-off']" class="text-white"></fa-icon>
                            <ion-label class="text-white" *ngIf="!menuMini">
                                <strong>{{'Logout' | translate}}</strong>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>
                <div #searchElement class="bg-light p-0 m-0 search flex-grow-1">
                    <app-search-main *ngIf="global['user']"></app-search-main>
                </div>
            </div>
        </ion-menu>
        <ion-router-outlet main id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
