import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/familiarPlan'
})
export class FamiliaPlanResource extends BaseResources {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/resend',
  })
  resend: IResourceMethod<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/checkToken',
  })
  checkToken: IResourceMethod<any, any>;
}
