import {Injectable} from '@angular/core';
import {
    IResourceMethod,
    ResourceAction,
    ResourceParams,
    ResourceRequestMethod,
    ResourceResponseBodyType
} from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
    pathPrefix: '/post'
})
export class PostResource extends BaseResources {
    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/{!id}/pdf'
    })

    pdf: IResourceMethod<any, any>;
    @ResourceAction({
        responseBodyType: ResourceResponseBodyType.ArrayBuffer,
        method: ResourceRequestMethod.Get,
        path: '/{!id}/audio.mp3',
        headers: {
            'Content-Type': '',
            'Accept': '*/*',
            'Range': 'bytes=0-'
        }
    })
    audio: IResourceMethod<any, any>;

    @ResourceAction({
        isArray: false,
        path: '/list'
    })
    list: IResourceMethod<any, any>;

    @ResourceAction({
        responseBodyType: ResourceResponseBodyType.Blob,
        method: ResourceRequestMethod.Get,
        path: '/print/{!id}'
    })
    print: IResourceMethod<any, any>;

    @ResourceAction({
        responseBodyType: ResourceResponseBodyType.Blob,
        method: ResourceRequestMethod.Get,
        path: '/printSearch/{!id}'
    })
    printSearch: IResourceMethod<any, any>;
}
