import { ViewChild, ElementRef, Directive } from '@angular/core';

@Directive()
export class BaseAudioPlayerFunctions {

    @ViewChild('audioPlayer') player: ElementRef;

    loaderDisplay: boolean = false;
    isPlaying: boolean = false;
    currentTime: number = 0;
    volume: number = 0.1;
    duration: number = 0.01;

    currTimePosChanged(event) {
        if (parseInt(this.player.nativeElement.currentTime, 10) !== event['detail']['value']) {
            this.player.nativeElement.currentTime = event['detail']['value'];
        }
    }

    bindPlayerEvent(): void {
        if(!this.player) {
          return;
        }
        this.player.nativeElement.load()
        /*this.player.nativeElement.addEventListener('play', () => {
        if ('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: 'Sample Audio',
            artist: 'John Doe',
            album: 'Sample Album',
            artwork: [
              { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
              { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
              { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
              { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
              { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
              { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
            ]
          });
        }
      });*/
        this.player.nativeElement.addEventListener('playing', () => {
            this.isPlaying = true;
            this.duration = Math.floor(this.player.nativeElement.duration);
        });
        this.player.nativeElement.addEventListener('pause', () => {
            this.isPlaying = false;
        });
        this.player.nativeElement.addEventListener('timeupdate', () => {
            this.currentTime = Math.floor(this.player.nativeElement.currentTime);
        });
        this.player.nativeElement.addEventListener('volume', () => {
            this.volume = Math.floor(this.player.nativeElement.volume);
        });
        this.player.nativeElement.addEventListener('loadstart', () => {
            this.loaderDisplay = true;
        });
        this.player.nativeElement.addEventListener('loadeddata', () => {
            this.loaderDisplay = false;
            this.duration = Math.floor(this.player.nativeElement.duration);
        });

        /*this.player.nativeElement.addEventListener('canplay', () => {
          console.log('canplay');
          this.loaderDisplay = false;
          this.duration = Math.floor(this.player.nativeElement.duration);
        });*/
    };

    playBtnHandler(): void {
        if (this.loaderDisplay) {
            return;
        }
        if (this.player.nativeElement.paused) {
            this.player.nativeElement.play(this.currentTime);
        } else {
            this.currentTime = this.player.nativeElement.currentTime;
            this.player.nativeElement.pause();
        }
    };

    play(): void {
        setTimeout(() => {
            this.player.nativeElement.play();
        }, 0);
    };

    toggleVolume() {
        if (this.volume === 0) {
            this.setVolume(1.0);
        } else {
            this.setVolume(0);
        }
    }

    private setVolume(vol) {
        this.volume = vol;
        this.player.nativeElement.volume = this.volume;
    }

}
