import { NavigationEnd, Router, RouterEvent } from '@angular/router'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
  AlertController,
  IonContent,
  MenuController,
  ModalController,
  NavController,
  Platform,
  PopoverController
} from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { TranslateService } from '@ngx-translate/core'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { AuthMenuComponent } from '@xplat-cgc/ionic/features/ui/pages/auth/auth-menu/auth-menu.component'
import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import { AuthMeComponent } from '@xplat-cgc/ionic/features/ui/pages/auth/auth-me/auth-me.component'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.page.html',
  styleUrls: ['layout.page.scss']
})
export class LayoutPage implements OnInit {
  public environment = environment;
  customPopoverOptions: any = {
    showBackdrop: false
  };
  public queryText = '';
  public global = {};
  public searchType = '';
  public showHelpStron = false;

  @ViewChild(IonContent) content: IonContent;

  public user;

  constructor(
    protected globalProvider: GlobalProvider,
    protected events: Events,
    public popoverController: PopoverController,
    private menu: MenuController,
    protected translate: TranslateService,
    public platform: Platform,
    private elRef: ElementRef,
    protected modalController: ModalController,
    protected navController: NavController,
    private _router: Router,
    private _alertController: AlertController
  ) {
    this.global = this.globalProvider['global'];
  }

  async onOpenMe() {
    const modal = await this.modalController.create({
      component: AuthMeComponent,
      backdropDismiss: false
    });
    modal.present();
  }

  ngOnInit() {
    if (!this.global['user']) {
      this.navController.navigateRoot('/login');
      return false;
    }
    this.user = this.globalProvider.global['user'];
    this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(e => {
        if (e['url'] == '/tabs/study/search/section/strong') {
          this.showHelpStron = true;
        } else {
          this.showHelpStron = false;
        }
      });
  }

  async presentModaHelpStrong() {
    const translate = await this.translate
      .get([
        "To Search with Strong's Numbers enter H(Hebrew) or G(Greek) before the Strong's Number. Example H789 or G789"
      ])
      .toPromise();
    const alert = await this._alertController.create({
      header: "Strong's",
      message:
        translate[
          "To Search with Strong's Numbers enter H(Hebrew) or G(Greek) before the Strong's Number. Example H789 or G789"
        ],
      buttons: ['Cancel']
    });

    /*const modal = await this.modalController.create({
      component: HelpStrongComponent
    });
    return await modal.present();*/
    await alert.present();
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: AuthMenuComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
      keyboardClose: true,
      backdropDismiss: true
    });
    popover.present();

    popover.onDidDismiss().then(user => {
      if (user['data']) {
        this.user['photo'] = user['data']['photo'];
      }
    });
  }
}
