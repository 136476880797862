import { ViewSharedModule } from './../../../providers/views.shared.module';
import { NotificationListComponent } from '@xplat-cgc/ionic/features/ui/pages/notification/notification-list/notification-list.component';
import { HelpModule } from './../help/help.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {HeaderButtonsComponent} from '@xplat-cgc/ionic/features/ui/components/header-buttons/header-buttons.component';
import { HelpStrongModule } from '../help-strong/help-strong.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        HelpModule,
        ViewSharedModule,
        HelpStrongModule
    ],
    exports: [HeaderButtonsComponent],
    declarations: [HeaderButtonsComponent]
})
export class HeaderButtonsModule {
}
