import { HeaderButtonsModule } from '@xplat-cgc/ionic/features/ui/components/header-buttons/header-buttons.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {BroadcastPage} from './broadcast.page';
import {BroadcastListComponent} from './broadcast-list/broadcast-list.component';
import {BroadcastDetailComponent} from './broadcast-detail/broadcast-detail.component';
import {BroadcastResource} from './broadcast-resource';
import {TranslateModule} from '@ngx-translate/core';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faChurch, faMapMarkedAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxLoadingModule} from 'ngx-loading';
import {UIModule} from '@xplat-cgc/ionic/features/ui/ui.module';
import {MusicplayerPageModule} from '../../../modules/musicplayer/musicplayer.module';
import {NgxAudioPlayerModule} from '../../../modules/player/ngx-audio-player.module';

const routes: Routes = [
    {
        path: '',
        component: BroadcastPage,
        children: [
            {
                path: '',
                component: BroadcastListComponent
            },
            {
                path: ':id/detail',
                component: BroadcastDetailComponent
            }
        ]
    }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule, FontAwesomeModule, NgxLoadingModule,
    NgxAudioPlayerModule,
    NgxLoadingModule, UIModule, HeaderButtonsModule, MusicplayerPageModule
  ],
    declarations: [BroadcastPage, BroadcastListComponent, BroadcastDetailComponent],
    providers: [
        BroadcastResource
    ]
})
export class BroadcastPageModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faChurch);
        library.add(faMapMarkedAlt);
    }
}
