<div
  *ngIf="data"
  [hidden]="global['loadingViewer']"
  class="lang-{{ global['lang'] }}"
>
  <div *ngIf="data['type'] == 'pdf' || data['type'] == 'book'">
    <app-visor [data]="data"></app-visor>
  </div>
  <div *ngIf="data['type'] == 'bible'" padding="" class="bible">
    <div
      [innerHtml]="
        data['data']['content']
          | highlightify: global['result']['bible']['queryText']
      "
    ></div>
  </div>
  <div *ngIf="data['type'] == 'strong'" padding="" class="strong lexiconTable">
    <div class="col-12">
      <div
        [innerHtml]="
          data['data'] | highlightify: global['result']['bible']['queryText']
        "
      ></div>
    </div>
  </div>

  <div *ngIf="data['type'] == 'video'">
    <div class="row vh-100 d-inline-block m-0 d-flex align-items-center">

      <div *ngIf="!data['data']['filepath']" class="align-self-center"   style="width: 100%">
        <iframe
          id="video"
          [src]="data['data']['url'] | safeUrl"
          width="100%"
          style="height: calc(100vh - 59px);"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          (load)="onLoadVideo()"
        ></iframe>
      </div>
      <div *ngIf="data['data']['filepath']" class="align-self-center" style="width: 100%;">
        <video #videoPlayer controls controlsList="nodownload" webkit-playsinline playsinline style="width: 100%; background: var(--ion-color-primary);">
          <source [src]="data['data']['url'] | safeUrl" type="video/mp4" style="width: 100%;"/>
        </video>
      </div>
    </div>
  </div>
  <div *ngIf="data['type'] == 'post'">
    <div padding="">
      <div [outerHTML]="data['data']['description']"></div>
    </div>
  </div>
</div>
<div
  *ngIf="data && data['type'] == 'audio' && data['data'] && data['data']['id']"
>
  <div class="content-center background-placeholder">
    <!--fa-icon [icon]="['fas', 'play-circle']" size="3x"></fa-icon-->
    <img loading="lazy"src="assets/img/logo.png" style="width: 100px;margin: auto;"/>
  </div>
  <div class="player-audio pb-3">
    <mat-basic-audio-player
      [track]="{'image': (data['data']['image'] ? data['data']['image'] : 'img/placeholder_music.png'), id: data['data']['id'], 'url_user' : data['data']['url'], 'title'  : data['data']['title'], 'data_audio' : data, 'music_type' : 'sermon'}"
      [audioUrl]="data['data']['url']"
    ></mat-basic-audio-player>
  </div>
</div>
<div
  class="content-center background-placeholder"
  *ngIf="global['loadingViewer']"
>
    <fa-icon [icon]="['fas', 'pen-fancy']" size="3x"  style="transform: rotate(135deg);"></fa-icon>
  <h2 class="title">
    <strong>{{ 'Enjoy your study' | translate }}!</strong>
  </h2>
  <div>{{ 'Enter a word or phrase to begin a search' | translate }}</div>
</div>
