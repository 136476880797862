import {Routes} from '@angular/router';
import {CanActivateViaAuthGuard} from '@xplat-cgc/ionic/features/providers/CanActivateViaAuthGuard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('@xplat-cgc/ionic/features/ui/pages/auth/auth.module').then(
            m => m.AuthPageModule
          )
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('@xplat-cgc/ionic/features/ui/pages/auth/auth.module').then(
            m => m.AuthPageModule
          )
      },
      {
        path: 'login',
        loadChildren: () =>
          import('@xplat-cgc/ionic/features/ui/pages/auth/auth.module').then(
            m => m.AuthPageModule
          )
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [CanActivateViaAuthGuard],
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomePageModule)
      }
    ]
  }
];

export {routes};
