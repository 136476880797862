import {DomSanitizer} from '@angular/platform-browser';
import {PipeTransform, Pipe} from '@angular/core';
import * as moment from 'moment';
import { format } from 'util';

@Pipe({name: 'parseDate'})
export class ParseDatePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }
    transform(value) {
      if (window.localStorage.getItem('lang') == 'es') {
        return moment(value, ['YYYY-MM-DD', 'YYYY/MM/DD'])
          .locale('es')
          .format('DD/MMM/YYYY');
      }
      return moment(value, ['YYYY-MM-DD', 'YYYY/MM/DD']).format('MMM/DD/YYYY');
    }
}
