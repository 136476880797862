import { environment } from '@xplat-cgc/ionic/core/environments/environment'
/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { SpotifyService } from '../spotify.service'
import { SongsactionmodalComponent } from '../songsactionmodal/songsactionmodal.component'
import { ActivatedRoute } from '@angular/router'
import { Media, MediaObject } from '@ionic-native/media/ngx'
import { NavController, Platform } from '@ionic/angular'
import { StatusBar } from '@capacitor/status-bar'
import { selectPlayerCurrentTrack } from '../../state/selectors/player.selector'
import { State, Store } from '@ngrx/store'
import { ITrack, PlayerState } from '../../state/interfaces/player-state.interface'
import { Observable } from 'rxjs'
import { MusicListService } from '../music-list/music-list.service'
import { setPlayerCurrentTrack } from '../../state/actions/player.actions'

@Component({
  selector: 'app-musicplayer',
  templateUrl: './musicplayer.page.html',
  styleUrls: ['./musicplayer.page.scss']
})
export class MusicplayerPage implements OnInit, OnDestroy {
  title: any
  artist: any
  image: any
  environment = environment
  filename: any = 'Hotel California'
  // tslint:disable-next-line: variable-name
  file: MediaObject
  storageDirectory: any
  // tslint:disable-next-line: variable-name
  play_The_track: any

  // tslint:disable-next-line: variable-name
  is_playing = false
  // tslint:disable-next-line: variable-name
  isInPlay = false
  // tslint:disable-next-line: variable-name
  is_ready = false

  message: any

  duration: any = -1
  // tslint:disable-next-line: variable-name
  duration_string: string
  position: any = 0

  // tslint:disable-next-line: variable-name
  get_duration_interval: any
  // tslint:disable-next-line: variable-name
  get_position_interval: any
  public src = 'http://www.sample-videos.com/audio/mp3/crowd-cheering.mp3'
  heartClicked: any
  // tslint:disable-next-line: variable-name
  remove_Icon_Clicked: any
  DISPLAY_POSITION: any = '00:00'
  DISPLAY_DURATION: any = '00:00'
  @Input() track = null
  track$: Observable<ITrack>
  selectedMusic$
  playValue$

  constructor(
    public serviceProvider: SpotifyService,
    public actvRoute: ActivatedRoute,
    public platform: Platform,
    private media: Media,
    private _musicListService: MusicListService,
    private cdr: ChangeDetectorRef,
    private navCtrl: NavController,
    protected _store: Store<{ player: PlayerState }>,
    private _state: State<{ player: PlayerState }>
  ) {
    this.track$ = this._store.select(selectPlayerCurrentTrack)
    // tslint:disable-next-line: variable-name
    this.serviceProvider.urlTrack.subscribe(filtered_song_from_user => {
      this.play_The_track = filtered_song_from_user
      console.log('track to play', this.play_The_track)
    })

    this.actvRoute.params.subscribe(data => {
      if (data) {
        (this.title = data.track), (this.artist = data.artis)
        this.image = data.image
      }
    })
  }

  ngOnInit() {
    if (this.track) {
      console.log(this.track)
      //this.serviceProvider.setTrack(this.track);
    }
    this.selectedMusic$ = this.serviceProvider.selectedMusic.subscribe(() => {
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges()
      }
    })
    this.playValue$ = this.serviceProvider.playValue.subscribe(valuePlay => {
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges()
      }
    })
  }

  async openOptionsModal() {
    const modal = await this.serviceProvider.loadModal(
      SongsactionmodalComponent,
      this.serviceProvider.trackCurrent
    )
    await modal.present()
  }

  ionViewWillLeave() {
    StatusBar.setBackgroundColor({
      color: '#01106E'
    })
  }

  ionViewWillEnter() {
    StatusBar.setBackgroundColor({
      color: '#34408B'
    })
  }

  ionViewDidEnter() {
    // comment out the following line when adjusting UI in browsers
    this.play_The_track = this.serviceProvider.trackCurrent.url_user
    //this.prepareAudioFile();
  }

  play() {
    this.file.play()
  }

  getAndSetCurrentAudioPosition() {
    const diff = 1
    const self = this
    this.get_position_interval = setInterval(() => {
      // tslint:disable-next-line: variable-name
      const last_position = self.position
      self.file.getCurrentPosition().then(position => {
        if (position >= 0 && position < self.duration) {
          if (Math.abs(last_position - position) >= diff) {
            // set position
            self.file.seekTo(last_position * 1000)
          } else {
            // update position for display
            self.position = position
            this.DISPLAY_POSITION = this.toHHMMSS(self.position)
          }
        } else if (position >= self.duration) {
          self.stopPlayRecording()
          self.setRecordingToPlay()
        }
      })
    }, 100)
  }

  toHHMMSS(secs) {
    const SEC_NUM = parseInt(secs, 10)
    const minutes = Math.floor(SEC_NUM / 60) % 60
    const seconds = SEC_NUM % 60

    return [minutes, seconds]
      .map(v => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i >= 0)
      .join(':')
  }

  setRecordingToPlay() {
    this.file = this.media.create(this.play_The_track)
    this.file.onStatusUpdate.subscribe(status => {
      console.log(status)
      this.message = status
      switch (status) {
        case 1:
          this.isInPlay = false
          break
        case 2: // 2: playing
          this.isInPlay = true
          this.is_playing = true
          break
        case 3: // 3: pause
          this.isInPlay = true
          this.is_playing = false
          break
        case 4: // 4: stop
        default:
          this.isInPlay = false
          this.is_playing = false
          break
      }
    })
    this.message = 'audio file set'
    this.is_ready = true
    this.getAndSetCurrentAudioPosition()
  }

  stopPlayRecording() {
    this.file.stop()
    this.file.release()
    clearInterval(this.get_position_interval)
    this.position = 0
  }

  ngOnDestroy() {
    this.selectedMusic$.unsubscribe()
    this.playValue$.unsubscribe()
  }

  goBack() {
    this.navCtrl.back()
  }

  async onToFavorite(track: ITrack) {
    this._musicListService.toFavorite(track).then((data) => {
      this._store.dispatch(setPlayerCurrentTrack({
        track: {
          ...track,
          favorite: data?.data
        }
      }))
    })
  }
}
