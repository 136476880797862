import { UserData } from '@xplat-cgc/ionic/features/providers/user-data'
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'

import { IonContent, MenuController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { TranslateService } from '@ngx-translate/core'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router'
import { BibleResource } from '@xplat-cgc/ionic/features/providers/resources/bible-resource.service'
import { SearchService } from '@xplat-cgc/ionic/features/ui/pages/search/search.service'
import { firebase } from '@firebase/app'
import '@firebase/analytics'
import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import { PostWeekComponent } from '@xplat-cgc/ionic/features/ui/pages/post/post-week/post-week.component'
import { AuthMeComponent } from '@xplat-cgc/ionic/features/ui/pages/auth/auth-me/auth-me.component'
import {
  NotificationListComponent
} from '@xplat-cgc/ionic/features/ui/pages/notification/notification-list/notification-list.component'
import { PostListComponent } from '@xplat-cgc/ionic/features/ui/pages/post/post-list/post-list.component'
import { PostAddComponent } from '@xplat-cgc/ionic/features/ui/pages/post/post-add'

import { GlobalService } from '@xplat-cgc/ionic/features/providers/services/global-service'
import { filter } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { PlanComponent } from '@xplat-cgc/ionic/features/ui/components/plan/plan.component'
import Echo from 'laravel-echo'
import * as moment from 'moment'
import { LiveResource } from '@xplat-cgc/ionic/features/ui/pages/live/live-resource'
import * as _ from 'lodash'
import { PlayerState } from '@xplat-cgc/player/state/interfaces/player-state.interface'
import { State, Store } from '@ngrx/store'
import { loadPlayerDownloads } from '@xplat-cgc/player/state/actions/player.actions'


declare var require: any

window['io'] = require('socket.io-client');
@Component({
  selector: 'QCH-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public queryText = '';
  public global = {};
  public searchType = null;
  public menuMini = false;
  public currentPage = null;
  public userPermissions = [];

  @ViewChild('searchElement', { static: true }) searchElement: ElementRef;
  @ViewChild('menuElement', { static: true }) menuElement: ElementRef;
  @ViewChild(IonContent) content: IonContent;

  public appPages = [];

  customPopoverOptions: any = {
    header: 'Hair Color',
    subHeader: 'Select your hair color',
    message: 'Only select your dominant hair color'
  };

  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(
    private platform: Platform,
    protected router: Router,
    protected globalProvider: GlobalProvider,
    protected route: ActivatedRoute,
    protected searchResource: BibleResource,
    protected translate: TranslateService,
    protected searchService: SearchService,
    protected events: Events,
    public menu: MenuController,
    protected modalController: ModalController,
    protected navController: NavController,
    protected popoverController: PopoverController,
    protected userData: UserData,
    protected globalService: GlobalService,
    private liveResource: LiveResource,
    protected _store: Store<{ player: PlayerState }>,
    private _state: State<{ player: PlayerState }>,
  ) {

    this._store.dispatch(loadPlayerDownloads());
    this.global = this.globalProvider['global'];
    this.global['urlBible'] = 'https://www.blueletterbible.org/search/search.cfm?Criteria=&t=' + (this.global['lang'] == 'es' ? 'rvr60' : 'KJV') +  '&ss=1#s=s_primary_0_1'
    this.global['mobile'] = false;
    this.initializeApp();

    this.events.subscribe('user:login', user => {
      this.getUserData(user);
      if (user && user['role'] && user['role'][0] === 'admin.super') {
        this.setTimeout();
        this.userInactive.subscribe(() => {
          this.onLogout();
          console.log('user has been inactive for 3m');
        });
      }
    });

    if (this.global['user']) {
      this.userData.getUser().then(user => this.getUserData(user));
      if (
        this.global['user']['roles'] &&
        this.global['user']['roles'][0]['slug'] === 'admin.super'
      ) {
        this.setTimeout();
        this.userInactive.subscribe(() => {
          this.onLogout();
          console.log('user has been inactive for 3m');
        });
      }
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1800000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  onLogout() {
    this.userInactive.unsubscribe();
    this.menu.enable(false);
    delete this.global['user'];
    delete this.global['token'];
    this.global['visors'] = [];
    this.global['result'] = {
      bible: {},
      strong: {},
      post: {
        data: {
          data: []
        }
      },
      postWeek: {},
      pdf: {
        data: {
          data: []
        }
      }
    };
    this.userData.logout().then(() => {
      this.navController.navigateRoot('/auth/login');
    });
  }

  initializeApp() {
    // @ts-ignore
    const paramsSocket = _.assignIn({
      broadcaster: 'socket.io',
      reconnectionDelay: 30000,
      reconnectionDelayMax: 30000,
    }, this.global['config']['SOCKET']);

    let langId = []
    if (this.global['lang'] == 'en') {
      langId.push('2');
    }
    if (this.global['lang'] == 'es') {
      langId.push('1');
      langId.push('3');
    }

    this.liveResource.query({_page: 1, _limit: 1000,
      'status-in': 'LIVE',
      _withJoin: 'language',
      _sortJoin: '-date,-time,language_id',
      'date-min': moment().format('YYYY-MM-DD')
    }).then((response) => {
      let list = response['data']['data'];
      if (this.global['mobile']) {
        list = _.filter(list, ['directed_to_app', true]);
      } else {
        list = _.filter(list, ['directed_to_web', true]);
      }
      if (list.length) {
        this['global']['config']['liveEvents'] = list;
        this['global']['config']['liveEventsIds'] = _.map(list, 'id');
        this['global']['config']['liveStream'] = true;
      } else {
        this['global']['config']['liveEvents'] = [];
        this['global']['config']['liveEventsIds'] = [];
        this['global']['config']['liveStream'] = false;
      }
    });
    // @ts-ignore
/*     window['Echo']['channel']('stream-event')
      .listen('StreamUpdated', e => {
        console.log(e);
        this.liveResource.check({
          user: this.global['user'],
          hash: e['hash']
        }).then((response) => {
          let list = response['data'];
          if (this.global['mobile']) {
            list = _.filter(list, ['directed_to_app', true]);
          } else {
            list = _.filter(list, ['directed_to_web', true]);
          }
          if (list.length) {
            this['global']['config']['liveEvents'] = list;
            this['global']['config']['liveEventsIds'] = _.map(list, 'id');
            this['global']['config']['liveStream'] = true;
          } else {
            this['global']['config']['liveEvents'] = [];
            this['global']['config']['liveEventsIds'] = [];
            this['global']['config']['liveStream'] = false;
          }
        });
      }) ;*/
    this.translate.addLangs(['en', 'es']);
    const langStorage = window.localStorage['lang'] || 'en';
    if (!langStorage) {
      this.translate.setDefaultLang('en');
    } else if (langStorage) {
      this.global['lang'] = langStorage;
    }

    this.translate.use(window.localStorage.getItem('lang') || 'en');
    this.global['visors'] = [];
    this.global['result'] = {
      bible: {},
      strong: {},
      post: {
        data: {
          data: []
        }
      },
      postWeek: {},
      pdf: {
        data: {
          data: []
        }
      }
    };
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
                                             /*try {
        await this.notificationService.requestPermission();
      } catch (e) {
        console.log(e);
      }*/
                                           });
    this.calculateWithMenu(1000);
  }

  getUserData(user) {
    if (user && user['abilities'] && user['abilities']['front_permissions']) {
      this.userPermissions = user['abilities']['front_permissions'];
      this.appPages = [
        {
          title: 'Home',
          section: null,
          url: '/home',
          icon: ['fas', 'compass']
        }
      ];
      if (this.userPermissions.hasOwnProperty('sermons.search')) {
        this.appPages.push({
          title: 'Sermons & Books',
          section: 'pdf',
          url: '/search',
          icon: ['fas', 'pen-fancy']
        });
      }
      if (this.userPermissions.hasOwnProperty('bible.search')) {
        this.appPages.push({
          title: 'Bible & Strong\'s',
          sub: 'Powered By Blue Letter Bible',
          section: 'bible',
          url: '/search',
          icon: ['fas', 'bible']
        });
      }
      /*if (this.userPermissions.hasOwnProperty('strong.search')) {
        this.appPages.push({
          title: "Strong's",
          section: 'strong',
          url: '/search',
          icon: ['fas', 'quote-right']
        });
      }*/
      if (this.userPermissions.hasOwnProperty('notes.search')) {
        this.appPages.push({
          title: 'Quick Notes',
          section: 'post',
          url: '/search',
          icon: ['fas', 'book-reader']
        });
      }
    }
  }

  ngOnInit() {
    try {
      this.initFirebase();
    } catch (e) {
      console.log(e);
    }
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(e => {
        this.currentPage = e;
        firebase.analytics().logEvent('page_view', {screen_name: e.url})
        document.querySelector('body').setAttribute('view', e.url);
        this.calculateWithMenu();
      });
    document.querySelector('body').setAttribute('menu-mini', 'false');
  }

  async initFirebase() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
      firebase.analytics();
    }
  }

  onSelectSection(section) {
    if (section !== '') {
      this.global['searchType'] = section;
      if (this.global['searchType'] === 'bible' || this.global['searchType'] === 'strong') {
        this.global['result']['bible']['title'] = 'Powered by Blue Letter Bible';
      }
      if (this.globalService.isPlanFree() && (this.global['searchType'] === 'pdf' || this.global['searchType'] === 'post')) {
        this.presentPlan();
      }
    }
  }

  onOpen(url) {
    if (this.platform.is('mobile')) {
      this.navController.navigateRoot(url);
      return true;
    }

    if (url === '/post/week') {
      this.presentModal(PostWeekComponent);
    } else if (url === '/auth/me') {
      this.presentModal(AuthMeComponent);
    } else if (url === '/notification/list') {
      this.presentModal(NotificationListComponent);
    } else if (url === '/note/list') {
      if (this.globalService.isPlanFree()) {
        this.presentPlan();
        return true;
      }
      this.presentModal(PostListComponent);
    }
  }
  async presentPlan() {
    const modal = await this.modalController.create({
      component: PlanComponent,
      backdropDismiss: false,
      componentProps: {
        user: this.global['user'],
        close: true,
        hideId: this.global['user']['plan_id']
      }
    });
    modal.present();
  }

  async presentModal(component) {
    const modal = await this.modalController.create({
      component: component,
      backdropDismiss: false
    });
    modal.present();
    /*modal.onDidDismiss().then(() => {
            this.globalProvider.load().then(() => {
                this.onClose(this.globalProvider.get('user'));
            });
        });*/
  }

  onClose(data) {
    this.popoverController.dismiss(data);
  }

  onCollapse() {
    this.menuMini = !this.menuMini;
    document.querySelector('body').setAttribute('menu-mini', this.menuMini ? 'true' : 'false');
    this.calculateWithMenu();
  }

  calculateWithMenu(timeout = 0) {
    if (this.global['user']) {
      /* setTimeout(() => {
        this.searchElement.nativeElement.style.width =
          'calc(100% - ' + (this.menuElement.nativeElement.clientWidth + 1) + 'px)';
      }, timeout); */
    }
  }

  onChangeLang(lang) {
    this.global['lang'] = lang;
    this.globalProvider['global']['lang'] = lang;
    window.localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
    this.translate
      .use(lang)
      .toPromise()
      .then(() => {
        /* setTimeout(() => {
          document.querySelector('.search')['style']['width'] =
            'calc(100% - ' + (document.querySelector('.menu').clientWidth + 1) + 'px)';
        }, 0); */
      });
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PostAddComponent,
      event: ev,
      cssClass: 'popover-post-add',
      translucent: true,
      showBackdrop: false
    });
    return await popover.present();
  }
}
