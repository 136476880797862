import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';

@Directive({
    selector: 'img[thumbnail]'
})
export class ThumbnailDirective {
    @Input() public image: any;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        const reader = new FileReader();
        const el = this.el;

        reader.onloadend = readerEvent => {
            const image = new Image();
            image.onload = imageEvent => {
                // Resize the image
                const canvas = document.createElement('canvas');
                const maxSize = 70;
                let width = image.width;
                let height = image.height;
                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                el.nativeElement.src = canvas.toDataURL('image/jpeg');
            };
            image.src = reader.result as string;
        };

        if (this.image) {
            return reader.readAsDataURL(this.image);
        }
    }
}
