import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { SearchService } from '../search.service'
import { IonInfiniteScroll, Platform } from '@ionic/angular'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { ActivatedRoute, Router } from '@angular/router'
import { BibleResource, GlobalProvider } from '@xplat-cgc/ionic/features/providers'
import * as _ from 'lodash'

@Component({
  selector: 'app-search-bible',
  templateUrl: './search-bible.component.html',
  styleUrls: ['./search-bible.component.scss']
})
export class SearchBibleComponent implements OnInit, AfterViewInit {
  @ViewChild(IonInfiniteScroll)
  infiniteScroll: IonInfiniteScroll;
  public global = {};
  public currentIndex = null;

  constructor(
    public globalProvider: GlobalProvider,
    protected searchService: SearchService,
    protected bibleResource: BibleResource,
    protected events: Events,
    public router: Router,
    protected route: ActivatedRoute,
    public platform: Platform,
    protected ref: ChangeDetectorRef
  ) {
    this.global = this.globalProvider['global'];
    this.events.subscribe('event:infinitiScrollResultBible', data => {
      if (this.infiniteScroll) {
        this.infiniteScroll.disabled = data['disabled'];
      }
    });
  }

  ngAfterViewInit() {}

  onSelectPassages(passage) {
    this.global['originSelect'] = 'search';
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    this.currentIndex = passage['id'];
    this.global['loadingViewer'] = true;
    this.global['result']['bible']['title'] = passage['reference'];
    if (this.global['mobile']) {
      this.router.navigate([
        '/tabs/study/search/section/' + this.global['searchType'] + '/viewer'
      ]);
    }
    this.events.publish('event:viewer', {
      type: 'bible',
      data: passage
    });
    this.global['loadingViewer'] = false;
  }

  removeAccents(str) {
    let accents =
      'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    let accentsOut =
      'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter);
      if (i != -1) {
        str[index] = accentsOut[i];
      }
    });
    return str.join('');
  }

  onSelectChapter(data) {
    this.global['urlBible'] =
      'https://www.blueletterbible.org/' +
      (this.global['lang'] == 'es' ? 'rvr60' : 'KJV') +
      '/' +
      this.removeAccents(data['name'].toLowerCase().replace('san ', '')) +
      '/1/1/p1/ss1';
    return true;
    this.global['originSelect'] = 'search';
    this.global['visors'] = _.filter(this.global['visors'], n => {
      return n['searchType'] !== this.global['searchType'];
    });
    if (this.global['mobile']) {
      this.router.navigate([
        '/tabs/study/search/section/' + this.global['searchType'] + '/viewer'
      ]);
    } else {
      this.router.navigate(['/search']);
    }

    this.global['loadingViewer'] = true;
    this.bibleResource
      .chapter({
        id: data['chapterId'],
        'include-chapter-numbers': true,
        'include-verse-numbers': true,
        'include-verse-spans': true
      })
      .then(response => {
        this.currentIndex = data['id'];
        this.global['result']['bible']['title'] = response['data']['reference'];
        this.events.publish('event:viewer', {
          type: 'bible',
          data: response['data']
        });
      })
      .catch(() => {
        this.global['loadingViewer'] = false;
      });
  }

  ngOnInit() {}

  onQuery(event) {
    if (
      this.global['result']['bible']['data'] &&
      this.global['result']['bible']['data']['verses']
    ) {
      if (
        this.global['result']['bible']['data']['verses'].length >=
        this.global['result']['bible']['data']['total'] - 1
      ) {
        this.infiniteScroll.disabled = true;
        event.target.complete();
        return false;
      }
    }
    this.global['result']['bible']['page']++;
    this.searchService
      .onQueryBible({
        query: this.global['result']['bible']['queryText']
      })
      .finally(() => {
        event.target.complete();
      });
  }

  onQueryBook(book) {
    this.global['queryText'] = book;
    this.onQuery(null);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }
}
