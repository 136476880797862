<ion-header *ngIf="!global['mobile']">
  <ion-toolbar>
    <ion-buttons slot="end" *ngIf="!global['mobile'] && global['user'] && global['user']['country_id']">
      <ion-button (click)="onDismiss()"  fill="clear">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="back-image">
    <div class="back-filter"></div>
    <ion-header *ngIf="global['mobile']" style="height: 30vh;">
      <ion-toolbar *ngIf="global['mobile']">
      </ion-toolbar>
      <ion-toolbar>
        <ion-title><strong>{{ 'My Account' | translate }}</strong></ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="ion-padding-horizontal" *ngIf="user">
      <form #f="ngForm" [formGroup]="formUser" (ngSubmit)="onSave()">
        <div class="row pt-2">
          <div style="text-align:center;width: 100%" class="col-12 form-group">
            <ion-avatar class="avatar" (click)="onUploadPhoto()" *ngIf="user && !uploader.queue.length">
              <img loading="lazy"alt="Photo" *ngIf="!user['photo']" src="assets/img/placeholder.png"/>
              <img loading="lazy"alt="Photo" *ngIf="user['photo']" [src]="environment.resources + user['photo']"/>
            </ion-avatar>
            <div *ngIf="uploader.queue.length">
              <ion-avatar class="avatar" *ngFor="let item of uploader.queue">
                <img loading="lazy"alt="Photo" src="" thumbnail [image]="item?._file"/>
              </ion-avatar>
            </div>
            <br/>
            <ion-button color="primary" (click)="onUploadPhoto()"
                        *ngIf="!uploader.queue.length && !user['photo']">{{ 'Select' | translate }}
              <input *ngIf="!global['mobile']" type="file" id="btn_file" ng2FileSelect [uploader]="uploader"
                     class="inputfile" accept=".jpg, .jpeg, .png"/>
            </ion-button>
            <ion-button color="danger" *ngIf="uploader.queue.length" (click)="onClearQueue(uploader)">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </ion-button>
            <ion-button color="danger" *ngIf="user['photo']" (click)="onClearPhoto()">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </ion-button>
          </div>

          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'First name' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <input matInput inputmode="text" name="name" autofocus formControlName="name" [(ngModel)]="user['name']"/>
            </mat-form-field>
            <div *ngIf="formUser.get('name').invalid && (formUser.get('name').touched || submitted)"
                 class="invalid-feedback">
              <div class="text-danger" *ngIf="!formUser?.controls?.name.valid">
                * {{ 'Required' | translate }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Last name' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <input matInput inputmode="text" name="last_primary_name" formControlName="last_primary_name"
                     [(ngModel)]="user['last_primary_name']"/>
            </mat-form-field>
            <div
              *ngIf="formUser.get('last_primary_name').invalid && (formUser.get('last_primary_name').touched || submitted)">
              <div class="text-danger" *ngIf="!formUser?.controls?.last_primary_name.valid">
                * {{ 'Required' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Last name 2' | translate }}</mat-label>
              <input matInput inputmode="text" name="last_second_name" formControlName="last_second_name"
                     [(ngModel)]="user['last_second_name']"/>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Cell Phone' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <input matInput inputmode="text" name="cellphone" formControlName="cellphone"
                     [(ngModel)]="user['cellphone']"/>
            </mat-form-field>
            <div *ngIf="formUser.get('cellphone').invalid && (formUser.get('cellphone').touched || submitted)">
              <div class="text-danger" *ngIf="!formUser?.controls?.cellphone.valid">
                * {{ 'Invalid' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Birthdate' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <input matInput inputmode="text" name="birthdate" formControlName="birthdate" mask="0000-00-00"
                     placeholder="aaaa-mm-dd" maxlength="10" [specialCharacters]="['-']" [dropSpecialCharacters]="false"
                     [(ngModel)]="user['birthdate']"/>
            </mat-form-field>
            <div *ngIf="formUser.get('birthdate').invalid && (formUser.get('birthdate').touched || submitted)">
              <div class="text-danger" *ngIf="!formUser?.controls?.birthdate.valid">
                * {{ 'Invalid' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Gender' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <select matNativeControl [(ngModel)]="user['gender_id']" formControlName="gender_id" name="gender_id">
                <option [value]="2">{{ 'Female' | translate }}</option>
                <option [value]="1">{{ 'Male' | translate }}</option>
              </select>
            </mat-form-field>
            <div *ngIf="formUser.get('gender_id').invalid && (formUser.get('gender_id').touched || submitted)">
              <div class="text-danger" *ngIf="!formUser?.controls?.gender_id.valid">
                * {{ 'Invalid' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>{{ 'Country' | translate }}
                <span class="text-danger text-bold">*</span></mat-label>
              <select matNativeControl [(ngModel)]="user['country_id']" formControlName="country_id" name="country_id"
                      (ngModelChange)="onChangeCountry()">
                <option *ngFor="let co of countries" [value]="co.id">({{ co.code }}) {{ co.name }}
                </option>
              </select>
            </mat-form-field>
            <div *ngIf="formUser.get('country_id').invalid && (formUser.get('country_id').touched || submitted)">
              <div class="text-danger"
                   *ngIf="!formUser?.controls?.country_id.valid && formUser?.controls?.country_id.dirty">
                * {{ 'Invalid' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group" *ngIf="churches && churches.length">
            <mat-form-field style="width: 100%" appearance="outline" floatLabel="always">
              <mat-label>{{ 'Church' | translate }}<span class="text-danger text-bold">*</span></mat-label>
              <select matNativeControl [(ngModel)]="user['church_id']" formControlName="church_id" name="church_id">
                <option *ngFor="let ch of churches; trackyBy: trackyByFn" [value]="ch.id">{{ ch.name }} ({{ ch.code }})
                </option>
              </select>
            </mat-form-field>
            <div *ngIf="formUser.get('church_id').invalid && (formUser.get('church_id').touched || submitted)">
              <div class="text-danger"
                   *ngIf="!formUser?.controls?.church_id.valid && formUser?.controls?.church_id.dirty">
                * {{ 'Invalid' | translate }}.
              </div>
            </div>
          </div>
        </div>
        <hr/>
      </form>
    </div>
    <div class="clearfix pb-5"></div>
    <ion-list class="ion-no-padding" lines="none" *ngIf="global['user'] && global['user']['plan'] && !global['mobile']">
      <ion-list-header>
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'PLAN' | translate }}</ion-label>
      </ion-list-header>
      <div>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6" class="pl-3  pb-4">
              <label><strong>Plan</strong></label>
              <br/>
              <br/> {{ (global['user']['plan'] ? global['user']['plan']['name'][global['lang'] || 'en'] : '') | translate }}
            </ion-col>
            <ion-col size="12" size-sm="6" class="pl-3  pb-4">
              <div *ngIf="global['user']['plan']['id'] == 4">
                <label><strong>{{ 'Remaining days' }}</strong></label>
                <br/>
                <br/> {{ moment(global['user']['created_at']).add(global['user']['plan']['test_days'], 'days').diff(moment(), 'days') }}
              </div>
            </ion-col>
            <ion-col size="12" size-sm="6" *ngIf="
                            global['user'] &&
                            global['user']['subscription'] &&
                            (global['user']['subscription']['status'] == 'paid' ||
                                global['user']['subscription']['status'] == 'pending')
                            ">
              <ion-button expand="block" color="danger" type="button" (click)="onCancelSubscription()">
                <fa-icon [icon]="['fas', 'ban']"></fa-icon> &nbsp;
                {{ 'Cancel Subscription' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size="12" size-sm="6" *ngIf="
                                global['user'] &&
                                global['user']['subscription'] &&
                                global['user']['subscription']['status'] == 'paused'">
              <ion-button expand="block" color="success" type="button" (click)="onActivatedSubscription()">
                <fa-icon [icon]="['fas', 'redo-alt']"></fa-icon> &nbsp;
                {{ 'Activate Subscription' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size="12" size-sm="6" *ngIf="(global['user'] &&
                            !global['user']['subscription']) || (
                            global['user'] &&
                            global['user']['subscription'] &&
                            (global['user']['subscription']['status'] == 'paid' ||
                                global['user']['subscription']['status'] == 'pending'))
                            ">
              <ion-button tappable="" expand="block" (click)="onChangePlan()" color="primary">
                <fa-icon [icon]="['fas', 'exchange-alt']"></fa-icon> &nbsp;
                <strong>{{'Change Plan' | translate}}</strong></ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-list>
    <ion-list class="ion-no-padding" lines="none" *ngIf="!global['mobile'] &&
                            global['user'] &&
                            global['user']['subscription'] &&
                            (global['user']['subscription']['status'] == 'paid' ||
                                global['user']['subscription']['status'] == 'pending')
                            ">
      <ion-list-header *ngIf="global['user']['payment']">
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Payment' | translate }}
        </ion-label>
      </ion-list-header>
      <div>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6" class="pl-3  pb-4" *ngIf="global['user']['payment']">
              <label><strong>{{ 'Payment date' | translate }}</strong></label>
              <br/>
              <br/> {{ global['user']['payment']['created_at'] }}
            </ion-col>
            <ion-col size="12" size-sm="6" class="pl-3  pb-4" *ngIf="global['user']['payment']">
              <label><strong>{{ 'Next Payment' | translate }}</strong></label>
              <br/>
              <br/> {{ moment(global['user']['payment']['created_at']).add(1, 'month').format('YYYY/MM/DD') }}
            </ion-col>
            <ion-col size="12" size-sm="6" class="pl-3  pb-4" *ngIf="global['user']['payment']">
              <label><strong>{{ 'Credit card' | translate }}</strong></label>
              <br/>
              <br/>
              <fa-icon [icon]="['fas', 'credit-card']"></fa-icon> &nbsp; * * * * *
              * * * * * * * {{ global['user']['payment']['card']['card_number'] }}
            </ion-col>
            <ion-col size="12" size-sm="6" *ngIf="global['user']['payment']">
              <ion-button expand="block" tappable="" (click)="onChangePaymentMethod()" color="primary"
                          *ngIf="global['user']['payment']">
                <fa-icon [icon]="['fas', 'credit-card']"></fa-icon> &nbsp;
                <strong>{{'Change payment method' | translate}}</strong>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-list>
    <ion-list class="ion-no-padding" lines="none" *ngIf="tutor && !global['mobile']">
      <ion-list-header>
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Account Holder' | translate }}
        </ion-label>
      </ion-list-header>
      <ion-item>
        <ion-avatar slot="start">
          <img loading="lazy"*ngIf="tutor['photo']" src="{{environment.resources   + tutor['photo']}}">
          <img loading="lazy"*ngIf="!tutor['photo']" alt="Photo" src="assets/img/placeholder.png"/>

        </ion-avatar>
        <ion-label>
          <h2>{{tutor['name']}} {{tutor['last_primary_name']}} {{tutor['last_second_name']}}</h2>
          <p>{{tutor['email']}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list class="ion-no-padding" lines="none"
              *ngIf="global['user'] && (global['user']['plan_id'] == 3 || global['user']['id'] == 1)  && !global['mobile']">
      <ion-list-header>
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Additional Account' | translate }}
        </ion-label>
      </ion-list-header>
      <ion-item>
        <p>{{'You can only have 2 additional account' | translate}}</p>
      </ion-item>
      <ion-item *ngIf="familiarPlan && familiarPlan.length < 2">
        <mat-form-field style="width: 100%" appearance="outline" class="pt-2">
          <mat-label>{{ 'Email' | translate }}<span class="text-danger text-bold">*</span></mat-label>
          <input matInput inputmode="email" [(ngModel)]="emailInvited"/>
          <ion-button matSuffix [disabled]="loading" type="button" expand="block" color="secondary"
                      (click)="onInviteFamiliarPlan(emailInvited)">
            <fa-icon *ngIf="loading" [pulse]="true" [icon]="['fas','spinner']" aria-hidden="true"></fa-icon>
            <fa-icon *ngIf="!loading" [icon]="['fas', 'plus']"></fa-icon>
          </ion-button>
        </mat-form-field>
      </ion-item>
      <ion-item *ngFor="let item of familiarPlan">
        <ion-avatar slot="start" *ngIf="!item['invited_user']">
          <img loading="lazy"alt="Photo" src="assets/img/placeholder.png"/>
        </ion-avatar>
        <ion-label *ngIf="!item['invited_user']">
          <h2 class="ion-text-medium">{{'Pending' | translate}} ...</h2>
          <p>{{item['email']}}</p>
          <p>
            <fa-icon *ngIf="loading" [pulse]="true"
                     [icon]="['fas','spinner']" aria-hidden="true"></fa-icon>
            <a *ngIf="!loading" href="javascript:"
               (click)="onResendInvitation(item['email'])"><strong>{{'Resend Invitation'}}</strong></a>
          </p>
        </ion-label>
        <ion-avatar slot="start" *ngIf="item['invited_user'] && item['invited_user']['photo']">
          <img loading="lazy"src="{{environment.resources   + item['invited_user']['photo']}}">
        </ion-avatar>
        <ion-avatar slot="start" *ngIf="item['invited_user'] && !item['invited_user']['photo']">
          <img loading="lazy"alt="Photo" src="assets/img/placeholder.png"/>
        </ion-avatar>
        <ion-label *ngIf="item['invited_user']">
          <h2>{{item['invited_user']['name']}} {{item['invited_user']['last_primary_name']}} {{item['invited_user']['last_second_name']}}</h2>
          <p>{{item['invited_user']['email']}}</p>
        </ion-label>
        <ion-button slot="end" color="danger" [disabled]="loading" (click)="onRemoveFamiliar(item)">
          <fa-icon *ngIf="loading" [pulse]="true"
                   [icon]="['fas','spinner']" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="!loading" [icon]="['fas', 'minus']"></fa-icon>
        </ion-button>
      </ion-item>
    </ion-list>
    <ion-list *ngIf="global['mobile']">
      <ion-list-header>
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Language' | translate }}
        </ion-label>
      </ion-list-header>
      <ion-item tappable="" (click)="onChangeLang('en')" [color]="this.global['lang'] == 'en' ? 'secondary' : ''">
        <ion-avatar slot="start">
          <img loading="lazy"src="assets/img/usa.png" width="32" class="pl-2"/>
        </ion-avatar>
        <ion-label>
          English
        </ion-label>
      </ion-item>
      <ion-item tappable="" (click)="onChangeLang('es')" [color]="this.global['lang'] == 'es' ? 'secondary' : ''">
        <ion-avatar slot="start">
          <img loading="lazy"src="assets/img/mx.png" width="32" class="pl-2"/>
        </ion-avatar>
        <ion-label>
          Español
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list *ngIf="global['mobile'] && platform.is('android')">
      <ion-list-header>
        <ion-label class="ion-color-medium ion-text-uppercase" color="medium">{{ 'Settings' | translate }}
        </ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>{{'Background Music Player' | translate}}</ion-label>
        <ion-toggle [(ngModel)]="backgroundMusic" (ngModelChange)="onBackgroundMusicChange()"></ion-toggle>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-list-header>
        <ion-label class="ion-color-medium" color="medium">LEGAL</ion-label>
      </ion-list-header>
      <ion-item (click)="onPresentTerms()" tappable>
        <ion-label>{{ 'Terms and Conditions' | translate }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Version {{ version }} <span *ngIf="global['user'] && global['user']['plan_id']">/ 0{{global['user']['plan_id']}}</span></ion-label>
      </ion-item>
    </ion-list>
    <div class="row">
      <ion-button *ngIf="global['user'] && global['mobile']" type="button" (click)="onLogout()" class="col-12 col-sm-12 col-md-5"
                  color="danger">
        {{ 'Logout' | translate }}
      </ion-button>
      <ion-button *ngIf="global['user']" type="button" (click)="onRemove()" class="col-12 col-sm-12 col-md-5"
                  color="danger">
        {{ 'Remove account' | translate }}
      </ion-button>
      <ion-button tappable="" *ngIf="!global['user'] && global['mobile']" type="button" [routerLink]="['/auth/login']"
                  class="col-sm-5" color="primary">
        {{ 'Login' | translate }}
      </ion-button>
    </div>



  </div>

  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</ion-content>
<ion-footer>
  <div class="row">
    <ion-button *ngIf="global['user']" type="button" (click)="onSave()" class="col-sm-6 col-md-5">
      {{ 'Update' | translate }}
    </ion-button>
  </div>
</ion-footer>
