import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { Injectable } from '@angular/core'
import { AudioStatisticsResource } from '@xplat-cgc/ionic/features/providers'
import * as _ from 'lodash'
import { Device } from '@capacitor/device'
import { Storage } from '@ionic/storage'
import { Network } from '@capacitor/network';
import { interval, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'



@Injectable({ providedIn: 'root' })
export class AudioStatisticService {
  protected session
  protected lastPosition = 0
  protected playingEvent = false
  protected info
  private timer$ = interval(1000);
  private reset$ = new Subject<void>();
  private completeTimer = false;
  private musicSession = null;

  constructor(
    protected audioStatisticsResource: AudioStatisticsResource,
    protected gp: GlobalProvider,
    public storage: Storage,
  ) {
    if (!this.session) {
      this.session = (new Date()).valueOf()
    }
    this.onInit()
    Network.addListener('networkStatusChange', status => {
      console.log(status)
      this.notifyPendings()
    });
  }

  notifyPendings() {
    setTimeout(() => {
      this.storage.get('PENDING_AUDIO_STATISTICS').then((data) => {
        console.log('PENDINGS', data)
        if (data) {
          data.forEach((item) => {
            setTimeout(() => {
              this.audioStatisticsResource.event(item).then((response) => {
                this.storage.set('PENDING_AUDIO_STATISTICS', _.filter(data, (i) => i.music_session !== item.music_session))
              })
            }, 2000)
          })
        }

      })
      }, 8000)
  }

  async onInit() {
    this.info = await Device.getInfo()
  }

  changeSession() {
    return this.session = (new Date()).valueOf()
  }

  async event(data: Record<any, any>) {
    if (!this.completeTimer) {
      return;
    }
    if (!data?.id) {
      return false
    }
    if (!this.gp.global?.config.AUDIO_STATISTICS) {
      return false
    }

    data.music_type = (data?.music_type ? data?.music_type : 'music_player')
    if (!_.isEmpty(this.gp.global?.config.AUDIO_STATISTICS_EVENTS) && !_.includes(this.gp.global?.config.AUDIO_STATISTICS_EVENTS, data?.event)) {
      return false
    }

    if (!_.isEmpty(this.gp.global?.config.AUDIO_STATISTICS_TYPE) && !_.includes(this.gp.global?.config.AUDIO_STATISTICS_TYPE, data?.music_type)) {
      return false
    }

    data.uuid = (await Device.getId())?.uuid
    data.device = this.info
    data.user_id = this.gp.global.user.id
    data.session = this.session
    if (data?.event === 'playing') {
      if (!this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK && this.playingEvent) {
        return
      }
      if (this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK) {
        if (Math.abs(data?.position - this.lastPosition) <= this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK) {
          return
        }
      }
      this.playingEvent = true
    } else {
      this.playingEvent = false
    }
    this.lastPosition = data.position
    data.music_session = this.musicSession
    const statusNetwork = await Network.getStatus()
    if (!statusNetwork.connected) {
      let pending = await this.storage.get('PENDING_AUDIO_STATISTICS') || []
      // find and maps exist id
      pending.push({
        ...data,
        offline: true
      })

      console.log()
      await this.storage.set('PENDING_AUDIO_STATISTICS', pending)
    } else {
      this.audioStatisticsResource.event(data)
    }
  }


  async eventUser(data: Record<any, any>) {
    if (!this.completeTimer) {
      return;
    }
    if (!data?.id) {
      return false
    }
    if (!this.gp.global?.config.AUDIO_STATISTICS) {
      return false
    }

    data.music_type = (data.music_type ? data.music_type : 'music_player')
    if (!_.isEmpty(this.gp.global?.config.AUDIO_STATISTICS_EVENTS) && !_.includes(this.gp.global?.config.AUDIO_STATISTICS_EVENTS, data?.event)) {
      return false
    }

    if (!_.isEmpty(this.gp.global?.config.AUDIO_STATISTICS_TYPE) && !_.includes(this.gp.global?.config.AUDIO_STATISTICS_TYPE, data?.music_type)) {
      return false
    }

    console.log(this.musicSession)
    data.uuid = (await Device.getId())?.uuid
    data.device = this.info
    data.user_id = this.gp.global.user.id
    data.session = this.session
    data.music_session = this.musicSession
    if (data.event === 'playing') {
      if (!this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK && this.playingEvent) {
        return
      }
      if (this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK) {
        if (Math.abs(data?.position - this.lastPosition) <= this.gp.global?.config.AUDIO_STATISTICS_TIMER_TRACK) {
          return
        }
      }
      this.playingEvent = true
    } else {
      this.playingEvent = false
    }
    this.lastPosition = data?.position
    this.setSessionTrackCurrent(data)
    this.audioStatisticsResource.eventUser(data)
  }


  setSessionTrackCurrent(data) {
    this.storage.set(
      'currentTrack',
      data
    )
  }

  start() {
    this.reset()
    this.timer$
      .pipe(
        takeUntil(this.reset$),
        takeUntil(interval(15000)),
      )
      .subscribe({
        next: (value) => {
          console.log(`Elapsed time: ${value + 1} seconds s`);
        },
        complete: () => {
          this.musicSession = (new Date()).valueOf()
          this.completeTimer = true
        },
      });
  }

  reset() {
    this.completeTimer = false
    this.reset$.next();
  }
}

