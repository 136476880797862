import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { SearchService } from '../search.service'
import { MenuController, ModalController, Platform, PopoverController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { PostAddComponent } from '../../post/post-add/post-add.component'
import { BibleResource } from '@xplat-cgc/ionic/features/providers/resources'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { GlobalProvider, GlobalService, UserData } from '@xplat-cgc/ionic/features/providers'
import { ActivatedRoute, Router } from '@angular/router'
import * as _ from 'lodash'
import { PlanComponent } from '../../../components/plan/plan.component'
import { Keyboard } from '@capacitor/keyboard'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-search-main',
  templateUrl: './search-main.component.html',
  styleUrls: ['./search-main.component.scss']
})
export class SearchMainComponent implements OnInit {
  public queryText = {}
  public global = {}
  public searchType = null
  public post: any = {}
  public userPermissions = []

  constructor(
    protected searchResource: BibleResource,
    protected globalProvider: GlobalProvider,
    protected searchService: SearchService,
    protected events: Events,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private menu: MenuController,
    protected translate: TranslateService,
    public userData: UserData,
    public platform: Platform,
    private ref: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public globalService: GlobalService
  ) {
    this.global = this.globalProvider['global']
  }

  onKeyUpQuery(event) {
    this.queryText[this.global['searchType']] = event.target.value
    if (event.keyCode === 13) {
      this.onSearch()
    }
  }

  onClear() {
    this.queryText[this.global['searchType']] = ''
    this.global['result'][this.global['searchType']]['queryText'] = ''
    if (this.global['searchType'] == 'bible') {
      this.global['result']['bible']['data'] = null
    }
    return false
    //this.figmafi;
  }

  async presentPlan() {
    const modal = await this.modalController.create({
      component: PlanComponent,
      backdropDismiss: false,
      componentProps: {
        user: this.global['user'],
        close: true,
        hideId: this.global['user']['plan_id']
      }
    })
    modal.present()
  }

  onSearch() {
    if (this.global['user'] && this.globalService.isPlanFree()) {
      if (this.global['mobile']) {
        this.router.navigate(['auth/login'])
        return true
      } else {
        this.presentPlan()
        return true
      }
    }
    if (this.global['mobile']) {
      if (Capacitor.isNativePlatform()) {
        Keyboard.hide()
      }
    }

    if (!_.trim(this.queryText[this.global['searchType']])) {
      return false
    }
    this.ref.detectChanges()
    const type = _.capitalize(this.global['searchType'])
    this.global['result'][this.global['searchType']][
      'queryText'
      ] = this.queryText[this.global['searchType']]
    document
      .querySelector('ion-content.' + this.global['searchType'])
      ['scrollToTop'](500)
    setTimeout(() => {
      this['onQuery' + type]()
    })
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: PostAddComponent,
      componentProps: { post: this.post },
      cssClass: 'new-post-modal-css',
      backdropDismiss: false
    })
    modal.present()
    modal.onDidDismiss().then(post => {
      if (post.data && post.data.id) {
        this.post = {}
      }
    })
  }

  onQueryBible() {
    this.global['result']['bible']['data'] = {}
    this.global['result']['bible']['page'] = 0
    if (this.queryText['bible'] !== '') {
      this.events.publish('event:infinitiScrollResultBible', {
        disabled: false
      })
      this.searchService
        .onQueryBible({
          query: this.queryText['bible']
        })
        .finally(() => {
        })
    }
  }

  onQueryStrong() {
    this.global['result']['strong']['data'] = ''
    if (this.queryText['strong'] !== '') {
      this.searchService.onQueryStrong({
        query: this.queryText['strong']
      })
    }
  }

  onQueryPdf() {
    this.global['result']['pdf']['page'] = 1
    this.global['result']['pdf']['data'] = {}
    if (this.queryText['pdf'] !== '') {
      this.events.publish('event:infinitiScrollResultPdf', {
        disabled: false
      })
      this.searchService.onQueryPost({
        query: this.queryText['pdf'],
        type: 'pdf'
      })
    }
  }

  onQueryPost() {
    this.global['result']['post']['page'] = 1
    this.global['result']['post']['data'] = {}
    if (this.queryText['post'] !== '') {
      this.events.publish('event:infinitiScrollResultPost', {
        disabled: false
      })
      this.searchService.onQueryPost({
        query: this.queryText['post'],
        type: 'post'
      })
    }
  }

  ngOnInit() {
    this.events.subscribe('event:searchAll', data => {
      if (data && data['queryText'] && _.trim(data['queryText'])) {
        if (this.userPermissions.hasOwnProperty('sermons.search')) {
          this.queryText['pdf'] = data['queryText']
          document.querySelector('ion-content.pdf')['scrollToTop'](500)
          this.global['result']['pdf']['queryText'] = data['queryText']
          this.onQueryPdf()
        }

        /*if (this.userPermissions.hasOwnProperty('bible.search')) {
          this.queryText['bible'] = data['queryText'];
          document.querySelector('ion-content.bible')['scrollToTop'](500);
          this.global['result']['bible']['queryText'] = data['queryText'];
          this.onQueryBible();
        }

        if (this.userPermissions.hasOwnProperty('strong.search')) {
          this.queryText['strong'] = data['queryText'];
          document.querySelector('ion-content.strong')['scrollToTop'](500);
          this.global['result']['strong']['queryText'] = data['queryText'];
          this.onQueryStrong();
        }*/

        if (this.userPermissions.hasOwnProperty('notes.search')) {
          this.queryText['post'] = data['queryText']
          document.querySelector('ion-content.post')['scrollToTop'](500)
          this.global['result']['post']['queryText'] = data['queryText']
          this.onQueryPost()
        }
      }
    })

    this.events.subscribe('event:search', data => {
      if (data && data['queryText'] && data['queryText']) {
        this.queryText[this.global['searchType']] = data['queryText']
        this.onSearch()
      }
    })

    this.userData.getUser().then(response => {
      if (!response) {
        return
      }
      this.userPermissions = response['abilities']['front_permissions']
      if (_.isEmpty(this.global['searchType'])) {
        if (this.userPermissions.hasOwnProperty('notes.search')) {
          this.global['searchType'] = 'post'
        }
        if (this.userPermissions.hasOwnProperty('strong.search')) {
          this.global['searchType'] = 'strong'
        }
        if (this.userPermissions.hasOwnProperty('bible.search')) {
          this.global['searchType'] = 'bible'
        }
        if (this.userPermissions.hasOwnProperty('sermons.search')) {
          this.global['searchType'] = 'pdf'
        }
      }
    })
  }
}
