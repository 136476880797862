import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { environment } from '@xplat-cgc/ionic/core/environments/environment'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { PostResource } from '../../post/post-resource.service'
import { Events } from '@xplat-cgc/ionic/features/providers/services/events.service'
import { SpotifyService } from '@xplat-cgc/player/app/spotify.service';
declare let Vimeo


@Component({
  selector: 'app-search-visor',
  templateUrl: './search-visor.component.html',
  styleUrls: ['./search-visor.component.scss']
})
export class SearchVisorComponent implements OnInit {
  @Input() public data = {}
  @ViewChild('pdf') public panel: ElementRef
  public global = {}
  public type = 'bible'
  public environment = environment
  public idAudio

  public url = ''

  constructor(
    public globalProvider: GlobalProvider,
    protected events: Events,
    public router: Router,
    private elRef: ElementRef,
    protected postResource: PostResource,
    private _spotifyService: SpotifyService
  ) {
    this.global = this.globalProvider['global']
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.data['type'] === 'video') {
        this.global['loadingViewer'] = false
        if (this.data['data']['local']) {
          this.data['data']['url'] = this.data['data']['url']
        } else if (this.data['data']['filepath']) {
          this.data['data']['url'] =
            environment.api + 'post/' + this.data['data']['id'] + '/audio.mp3'
        } else {
          if (!this.data['data']['inline']) {
            this.global['loadingViewer'] = true
            this.data['data']['url'] =
              'https://player.vimeo.com' + this.data['data']['path_file']
          }
        }
      } else if (this.data['type'] === 'pdf' || this.data['type'] === 'book') {
        this.global['loadingViewer'] = false
      } else if (this.data['type'] === 'strong') {
        this.elRef.nativeElement
          .querySelector('.lexicon-pronunc > div')
          .remove()
        this.elRef.nativeElement
          .querySelector('.lexicon-pronunc > .lexpop')
          .remove()
        this.elRef.nativeElement.querySelector('.bdb-thayer-lex').remove()
        this.elRef.nativeElement.querySelector('.show-for-medium').remove()
        this.global['loadingViewer'] = false
      } else if (this.data['type'] === 'audio') {
        if (this.data['data']['local']) {
          this.data['data']['url'] = this.data['data']['url']
        } else if (this.data['data']['path_file']) {
          this.data['data']['url'] =
            environment.api + 'post/' + this.data['data']['id'] + '/audio.mp3'
        }
        this.global['loadingViewer'] = false
      } else {
        this.global['loadingViewer'] = false
      }
    })
    this.idAudio = Math.random()
  }

  onStartAudio() {
    this.global['loadingViewer'] = false
  }

  onLoadVideo() {
    this.global['loadingViewer'] = false
    const player = new Vimeo.Player(this.elRef.nativeElement.querySelector('#video'))
    player.on('play', () => {
      this._spotifyService.stop(true)
    })
  }
}
