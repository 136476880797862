<!--ion-header>
    <ion-toolbar color="light">
        <ion-title class="ion-color-primary ion-text-center">
            <strong>{{(global['headerTitle'] || '') | translate}}</strong>
        </ion-title>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-select class="ion-te" [(ngModel)]="global['lang']" [interfaceOptions]="{}" slot="end"
                    (ionChange)="onChangeLang(global['lang'])"
                    interface="popover" placeholder="{{'Language' | translate}}">
            <ion-select-option [selected]="this.global['lang'] == 'es'" value="es">Español</ion-select-option>
            <ion-select-option [selected]="this.global['lang'] == 'en'" value="en">English</ion-select-option>
        </ion-select>
        <ion-buttons slot="end">
            <ion-button (click)="presentPopover($event)">
                <fa-icon [icon]="['fas', 'user-circle']" size="2x"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

</ion-header-->
<div>
    <ion-router-outlet></ion-router-outlet>
</div>
