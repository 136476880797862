<ion-header *ngIf="type != 'popover'">
    <ion-toolbar *ngIf="!global['mobile']">
        <ion-title  class="ion-text-uppercase">{{'My Notifications' | translate}}</ion-title>
        <ion-buttons slot="end" *ngIf="!global['mobile']">
            <ion-button (click)="onDismiss()">
                <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="primary" *ngIf="global['mobile']">
        <ion-buttons slot="start">
            <ion-menu-button color="light"></ion-menu-button>
        </ion-buttons>
        <ion-title  class="ion-text-uppercase">{{'My Notifications' | translate}}</ion-title>
        <ion-buttons slot="end" *ngIf="global['mobile']">
            <ion-button (click)="onDismiss()">
                <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="onQuery($event)">
        <ion-refresher-content refreshing-spinner="dots"></ion-refresher-content>
    </ion-refresher>
    <div *ngIf="global['mobile'] && loading">
        <div class="ion-padding custom-skeleton" *ngFor="let ske of [1,2,3,4,5,6,7]">
            <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
        </div>
    </div>
    <ion-list>
        <ion-item *ngFor="let row
            of list" #slidingItem>
            <fa-icon *ngIf="type != 'popover'" [icon]="['fas', 'bell']" slot="start"></fa-icon>
            <ion-label>
                {{row['notification']['title']}}
                <p>{{row['notification']['description']}}</p>
            </ion-label>
            <ion-note slot="end">{{globalService.ago(row['created_at'])}}</ion-note>
            <!--ion-icon name="arrow-forward" slot="end"></ion-icon-->
        </ion-item>
    </ion-list>
</ion-content>
