import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule, Platform} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {QuillModule} from 'ngx-quill';
import {NgxLoadingModule} from 'ngx-loading';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAudible} from '@fortawesome/free-brands-svg-icons';
import {
    faArrowDown, faArrowLeft, faArrowUp,
    faBible,
    faBookReader, faDownload,
    faMinusCircle, faPenFancy,
    faPlayCircle,
    faPlusCircle,
    faSearch, faSearchMinus,
    faSpinner, faTimesCircle,
    faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSearchPlus} from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import {SearchPage} from './search.page';
import {SearchViewerComponent} from './search-viewer/search-viewer.component';
import {SearchVisorComponent} from './search-visor/search-visor.component';
import {SearchMainComponent} from './search-main/search-main.component';
import {PipesModule} from '../../../providers/pipes/pipes.module';
import {ToastService} from '../../../providers/services/toast-service';
import {PostSharedModule} from '../post';
import {NgxAudioPlayerModule} from '../../../modules/player/ngx-audio-player.module';
import {SearchSharedModule} from './search.shared.module';
import {SearchService} from './search.service';
import {ViewSharedModule} from '../../../providers/views.shared.module';
import {faPrint} from '@fortawesome/free-solid-svg-icons/faPrint';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {SearchMainMobileComponent} from '../search/search-main-mobile';
import {SearchBibleComponent} from '@xplat-cgc/ionic/features/ui/pages/search/search-bible';
import {VisorModule} from '../../../modules/visor/visor.module';
import { HeaderButtonsModule } from '../../components/header-buttons/header-buttons.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'

const routes: Routes = [
    {
        path: '',
        component: SearchPage,
        children: [
            {
                path: '',
                component: SearchViewerComponent
            },
            {
                path: 'viewer',
                component: SearchVisorComponent
            },
            {
                path: 'viewer',
                component: SearchViewerComponent
            },
            {
              path: 'section/:section',
              component: SearchMainMobileComponent
            },
            {
              path: 'section/:section/viewer',
              component: SearchViewerComponent
            },
        ]
    },
    {
        path: 'mobile',
        component: SearchPage,
        children: [
            {
                path: '',
                component: SearchMainMobileComponent
            },
            {
                path: 'viewer',
                component: SearchViewerComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        PipesModule,
        TranslateModule,
        QuillModule,
        NgxLoadingModule,
        FontAwesomeModule,
        ViewSharedModule,
        SearchSharedModule,
        PostSharedModule,
        NgxAudioPlayerModule,
        VisorModule,
        HeaderButtonsModule,
        NgxExtendedPdfViewerModule
    ],
    exports: [
        PostSharedModule,
        SearchPage
    ],
    declarations: [
        SearchPage
    ],
    providers: [
        SearchService,
        ToastService
    ]
})
export class SearchPageModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faAudible);
        library.add(faBible);
        library.add(faBookReader);
        library.add(faSpinner);
        library.add(faUserCircle);
        library.add(faSearch);
        library.add(faMinusCircle);
        library.add(faPlusCircle);
        library.add(faPlayCircle);
        library.add(faDownload);
        library.add(faPenFancy);
        library.add(faPlusCircle);
        library.add(faSearchPlus);
        library.add(faSearchMinus);
        library.add(faArrowDown);
        library.add(faArrowUp);
        library.add(faSearch);
        library.add(faPrint);
        library.add(faCheckCircle);
        library.add(faArrowLeft);
        library.add(faArrowRight);
        library.add(faTimesCircle);
    }
}
