import {Routes} from '@angular/router';
import {LayoutPage} from './layout.page';
import {CanActivateViaAuthGuard} from '@xplat-cgc/ionic/features/providers/CanActivateViaAuthGuard';

const routes: Routes = [
    {
        path: '',
        component: LayoutPage,
        canActivate: [CanActivateViaAuthGuard],
        children: [
            {
                path: 'search',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/search/search.module')
                    .then(m => m.SearchPageModule)
            },
            {
                path: 'note',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/post/post.module')
                    .then(m => m.PostPageModule)
            },
            {
                path: 'post',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/post/post.module')
                    .then(m => m.PostPageModule)
            },
          {
            path: 'live',
            loadChildren: () =>
              import(
                '@xplat-cgc/ionic/features/ui/pages/live/live.module'
                ).then(m => m.LivePageModule)
          },
            {
                path: 'notification',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/notification/notification.module')
                    .then(m => m.NotificationPageModule)
            },
            {
                path: 'church',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/church/church.module')
                    .then(m => m.ChurchPageModule)
            },
            {
                path: 'broadcast',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/broadcast/broadcast.module')
                    .then(m => m.BroadcastPageModule)
            },
            {
                path: 'founder',
                loadChildren: () => import('@xplat-cgc/ionic/features/ui/pages/founder/founder.module')
                    .then(m => m.FounderPageModule)
            }
        ]
    }
];

export {routes};
