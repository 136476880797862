import {Injectable} from '@angular/core';
import {ResourceParams} from '@ngx-resource/core';
import {BaseResources} from '../../../providers/resources';

@Injectable()
@ResourceParams({
    pathPrefix: '/broadcast'
})
export class BroadcastResource extends BaseResources {
}
