<ion-slides
  #slidesWeeks
  (ionSlideWillChange)="onIonSlideDidChange()"
  class="calendar"
  [dir]="dir"
  [options]="slideOpts"
>
  <ion-slide *ngFor="let week of weeks; let iWeek = index">
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row>
        <ion-col
          class="ion-text-center ion-no-padding only-{{ week.length }}"
          *ngFor="let day of week; let iDay = index"
          [ngClass]="day['active'] ? 'active' : ''"
        >
          <a class="day" tappable (click)="onSelectDate(day, iDay, iWeek)">
            <ion-ripple-effect></ion-ripple-effect>
            <small class="text-uppercase">{{
              day['moment'] ? day['moment'].format('MMM') : ''
            }}</small>
            <h5 class="ion-no-margin ion-no-padding">
              <strong>{{
                day['moment'] ? day['moment'].format('DD') : ''
              }}</strong>
            </h5>
            <small class="text-uppercase">{{
              day['moment'] ? day['moment'].format('ddd') : ''
            }}</small>
          </a>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-slide>
</ion-slides>
