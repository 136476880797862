import { ActivatedRoute, RouterEvent, NavigationEnd, Router } from '@angular/router'
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers/global-provider'
import { NotificationListComponent } from './../../pages/notification/notification-list/notification-list.component'
import { HelpComponent } from '@xplat-cgc/ionic/features/ui/components/help/help.component'
import { Component, OnInit } from '@angular/core'
import { ModalController, AlertController } from '@ionic/angular'
import * as _ from 'lodash'
import { TranslateService } from '@ngx-translate/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { BaseResources } from '../../../providers/resources/base-resources'
import { ToastService } from '../../../providers/services/toast-service'
import { HelpStrongComponent } from '../help-strong/help-strong.component'
import { filter } from 'rxjs/operators'
import { Browser } from '@capacitor/browser'

@Component({
  selector: 'QCH-ion-header-buttons',
  templateUrl: './header-buttons.component.html',
  styleUrls: ['./header-buttons.component.scss']
})
export class HeaderButtonsComponent implements OnInit {
  id = (new Date()).valueOf()
  message = ''
  loading = false
  subject = ''
  public form: any
  global = {}
  public showHelpStron = false

  constructor(
    protected resource: BaseResources,
    protected toastService: ToastService,
    private _globalProvider: GlobalProvider,
    protected translate: TranslateService,
    public fb: UntypedFormBuilder,
    protected modalController: ModalController,
    private _route: ActivatedRoute,
    private _router: Router,
    private _alertController: AlertController
  ) {
    this.global = this._globalProvider.global
  }

  ngOnInit() {
    this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(e => {
        if (e['url'] == '/tabs/study/search/section/strong') {
          this.showHelpStron = true
        } else {
          this.showHelpStron = false
        }
      })
  }

  async presentModalHelp() {
    if (this.global['config']['URL_SUPPORT']) {
      await Browser.open({
        url: this.global['config']['URL_SUPPORT']
      });
    }
  }

  async presentModalNotification() {
    const modal = await this.modalController.create({
      component: NotificationListComponent
    })
    return await modal.present()
  }

  async presentModaHelpStrong() {
    const translate = await this.translate.get([
      'To Search with Strong\'s Numbers enter H(Hebrew) or G(Greek) before the Strong\'s Number. Example H789 or G789'
    ]).toPromise()
    const alert = await this._alertController.create({
      header: 'Strong\'s',
      message: translate['To Search with Strong\'s Numbers enter H(Hebrew) or G(Greek) before the Strong\'s Number. Example H789 or G789'],
      buttons: ['Cancel']
    })

    await alert.present()
  }


  onChangeLang(lang) {
    this.global['lang'] = lang
    window.localStorage.setItem('lang', lang)
    this.translate.setDefaultLang(lang)
    this.translate.use(lang)
  }
}
