import { HeaderButtonsModule } from '@xplat-cgc/ionic/features/ui/components/header-buttons/header-buttons.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

import { PermissionModalPage } from './permission-modal.page'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChurch, faMapMarkedAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { NgxLoadingModule } from 'ngx-loading'
import { faMountain } from '@fortawesome/free-solid-svg-icons/faMountain'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FontAwesomeModule,
    TranslateModule,
    NgxLoadingModule,
    HeaderButtonsModule
  ],
  declarations: [PermissionModalPage]

})
export class PermissionModalModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faChurch)
    library.add(faMapMarkedAlt)
    library.add(faTimesCircle)
    library.add(faMountain)
    library.add(faChevronRight)
    library.add(faChevronLeft)
    library.add(faCalendar)
    library.add(faChurch)
  }
}
