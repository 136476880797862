<div *ngIf="data">
  <ion-card>
    <ion-card-header>
      <ion-card-title *ngIf="global['lang'] == 'en'"><strong>{{data['title']}}</strong></ion-card-title>
      <ion-card-title *ngIf="global['lang'] == 'es'"><strong>{{data['title_es']}}</strong></ion-card-title>
    </ion-card-header>
    <img loading="lazy"[src]="environment.resources + data['image']">
  </ion-card>
  <ion-card-content class="ion-text-justify ion-no-padding" *ngIf="data['audio'] && global['lang'] == 'en'">
    <mat-basic-audio-player
    [track]="{id: data['id'], 'url_user' : data['audio'], 'title'  : data['title'], 'image'  : data['image'], 'data_audio' : data, 'music_type' : 'broadcast'}"
    [audioUrl]="data['audio']"></mat-basic-audio-player>
    <div class="pb-2"></div>
  </ion-card-content>
  <ion-card-content class="ion-text-justify ion-no-padding" *ngIf="data['audio_es'] && global['lang'] == 'es'">
    <mat-basic-audio-player [track]="{id: data['id'], 'url_user' : data['audio_es'], 'title'  : data['title_es'], 'image'  : data['image'], 'data_audio' : data, 'music_type' : 'music'}" [audioUrl]="data['audio_es']"></mat-basic-audio-player>
    <div class="pb-2"></div>
  </ion-card-content>
</div>

