import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { RouterModule } from '@angular/router'
import { NgxLoadingModule } from 'ngx-loading'
import { QuillModule } from 'ngx-quill'
import { FileUploadModule } from 'ng2-file-upload'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile, faFolder, faTimes, faTrash, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { AuthMeComponent } from '../ui/pages/auth/auth-me/auth-me.component'
import { PostSharedModule } from '../ui/pages/post/post.shared.module'
import { NotificationListComponent } from '../ui/pages/notification/notification-list/notification-list.component'
import { ThumbnailDirective } from './directives/thumbnail.directive'
import { NoteFolderAddComponent } from '../ui/pages/note-folder/note-folder-add/note-folder-add.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { CheckoutPaymentResource } from '@xplat-cgc/ionic/features/providers/resources/checkout-payment.resource'
import { PlanModule } from '../ui/components/plan/plan.module'
import { FamiliaPlanResource } from './resources/familia-plan-resource.service'
import { NgxMaskModule } from 'ngx-mask'

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        NgxLoadingModule,
        QuillModule,
        NgxLoadingModule,
        FileUploadModule,
        PostSharedModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        NgxMaskModule,
        PlanModule
    ],
    declarations: [
        AuthMeComponent,
        NotificationListComponent,
        ThumbnailDirective,
        NoteFolderAddComponent,
    ],
    exports: [
        AuthMeComponent,
        NotificationListComponent,
        ThumbnailDirective,
        NoteFolderAddComponent,
    ],
    providers: [
        CheckoutPaymentResource,
        FamiliaPlanResource
    ]
})
export class ViewSharedModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        library.add(faTimes);
        library.add(faTrash);
        library.add(faFile);
        library.add(faFolder);
        library.add(faUserCircle);
    }
}
