import { environment } from './../../../../../core/environments/environment';
import { Platform } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { BaseAudioPlayerFunctions } from '../base/base-audio-player-components';
import { GlobalProvider } from '@xplat-cgc/ionic/features/providers';
import { SpotifyService } from '@xplat-cgc/player/app/spotify.service';

@Component({
  selector: 'mat-basic-audio-player',
  templateUrl: './mat-basic-audio-player.component.html',
  styleUrls: ['./mat-basic-audio-player.component.css']
})
export class MatBasicAudioPlayerComponent extends BaseAudioPlayerFunctions
  implements OnInit {
  @Input() track: any = null;
  @Input()
  title: string;
  environment = environment;
  @Input()
  audioUrl: string;

  @Input()
  displayTitle = false;

  @Input()
  displayVolumeControls = true;

  constructor(
    public platform: Platform,
    public globalProvider: GlobalProvider,
    public serviceProvider: SpotifyService
  ) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.bindPlayerEvent();
    }, 0);
  }

  resetSong(): void {
    this.player.nativeElement.src = this.audioUrl;
  }
}
