import {Injectable} from '@angular/core';
import {BaseResources} from './base-resources';
import {
    ResourceParams,
    ResourceAction,
    ResourceRequestMethod,
    IResourceMethod
} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/audioStatistics'
})
export class AudioStatisticsResource extends BaseResources {
    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/event'
    })
    event: IResourceMethod<any, Object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/eventUser'
  })
  eventUser: IResourceMethod<any, Object>;
}
